import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { MotikomiData } from '@class/motikomi-data';
import { NyukansyaData } from '@class/nyukansya-data';
import { DbConnectService } from '@service/db-connect.service';
import { UserDataService } from '@service/user-data.service';
import { ModalService } from '@service/modal.service'
import { SpinnerModalComponent } from '@spinner/spinner-modal.component';

@Component({
  selector: 'app-itijitaikan',
  templateUrl: './itijitaikan.component.html',
  styleUrls: ['./itijitaikan.component.css']
})
export class ItijitaikanComponent implements OnInit {
  @Input() nyukansya: NyukansyaData[];
  @Input() motikomiData: MotikomiData[];
  @Output() changeFlg = new EventEmitter();

  pageForm: FormGroup;
  hansyutuList: MotikomiData[];
  motidasiList: MotikomiData[];
  noHansyutu: boolean;
  noMotidasi: boolean;

  constructor(
    private fb: FormBuilder,
    private dbConnect: DbConnectService,
    private userData: UserDataService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.pageForm = this.fb.group({
      kakunin1: false,
      kakunin2: false,
      kakunin3: false,
      key: '',
      iccard: '',
      hansyutus: new FormArray([]),
      motidasis: new FormArray([])
    });

    this.hansyutuList = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];

    this.motidasiList = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];
  }

  ngOnInit(): void {
    this.noHansyutu = false;
    this.noMotidasi = false;
    this.addHansyutu();
    this.addMotidasi();
    this.setMotikomiData();
    this.pageForm.controls.key.setValue(this.nyukansya[0].key);
    this.pageForm.controls.iccard.setValue(this.nyukansya[0].iccard);
  }

  async btnTorokuClick(): Promise<any> {
    if (!confirm('登録します。よろしいですか？')) {
      return;
    }
    
    this.modalService.openModal(SpinnerModalComponent, this.viewContainerRef, []);

    // 入館者ログの登録
    const prmList = [{
      key: ':pSINSEI_NO',
      value: this.nyukansya[0].sinseiNo
    },
    {
      key: ':pSEQ_NO',
      value: this.nyukansya[0].seqNo
    }];
    const result = await this.dbConnect.CmdSql('nyutaikanUketuke', '5', prmList);

    prmList.push({
      key: ':pRIREKI_NO',
      value: result[0].max_rireki_no + 1
    });
    prmList.push({
      key: ':pSTATUS',
      value: '02'
    });
    prmList.push({
      key: ':pKAKUNIN_1',
      value: this.pageForm.controls.kakunin1.value ? '1' : '0'
    });
    prmList.push({
      key: ':pKAKUNIN_2',
      value: ''
    });
    prmList.push({
      key: ':pKAKUNIN_3',
      value: this.pageForm.controls.kakunin3.value ? '1' : '0'
    });
    prmList.push({
      key: ':pKEY',
      value: this.pageForm.controls.key.value
    });
    prmList.push({
      key: ':pICCARD',
      value: this.pageForm.controls.iccard.value
    });
    prmList.push({
      key: ':pINS_KJN_NO',
      value: this.userData.keep.kjnNo
    });
    await this.dbConnect.CmdStored('nyutaikanUketuke', '7', prmList);

    // 持込物ログの登録
    const data: MotikomiData[] = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];
    data.length = 0;
    const hansyutus = this.hansyutus.controls;
    const motidasis = this.motidasis.controls;
    for (let i = 0; i < hansyutus.length; i++) {
      if (hansyutus[i].value) {
        data.push(this.hansyutuList[i]);
      }
    }
    for (let i = 0; i < motidasis.length; i++) {
      if (motidasis[i].value) {
        data.push(this.motidasiList[i]);
      }
    }

    for (const obj of data) {
      const prmList2 = [{
        key: ':pSINSEI_NO',
        value: obj.sinseiNo
      },
      {
        key: ':pKBN',
        value: obj.kbn
      },
      {
        key: ':pSEQ_NO',
        value: obj.seqNo
      }];
      const result2 = await this.dbConnect.CmdSql('nyutaikanUketuke', '6', prmList2);

      prmList2.push({
        key: ':pRIREKI_NO',
        value: result2[0].max_rireki_no + 1
      });
      prmList2.push({
        key: ':pSTATUS',
        value: '02'
      });
      prmList2.push({
        key: ':pNYUKANSYA_SEQ_NO',
        value: this.nyukansya[0].seqNo
      });
      prmList2.push({
        key: ':pINS_KJN_NO',
        value: this.userData.keep.kjnNo
      });
      await this.dbConnect.CmdStored('nyutaikanUketuke', '8', prmList2);
    }
    this.modalService.closeModal();
    alert('一時退館手続きが完了しました。');
    this.changeFlg.emit(false);
  }

  btnBackClick(): void {
    // 戻る
    this.changeFlg.emit(false);
  }

  setMotikomiData(): void {
    // 搬出物
    this.hansyutuList = this.motikomiData.filter(obj => obj.kbn.toString() === '2' && obj.status === '');
    this.pageForm.controls.hansyutus = new FormArray([]);
    if (this.hansyutuList.length === 0) {
      this.noHansyutu = true;
    } else {
      let i = 0;
      while (i < this.hansyutuList.length) {
        this.addHansyutu();
        i++;
      }
    }

    // 持込物（持込作業PC・持込工具類・その他持込物）
    this.motidasiList = this.motikomiData.filter(obj => (obj.kbn.toString() === '3'
      || obj.kbn.toString() === '4' || obj.kbn.toString() === '5') && obj.status === '01');
    this.pageForm.controls.motidasis = new FormArray([]);
    if (this.motidasiList.length === 0) {
      this.noMotidasi = true;
    } else {
      let i = 0;
      while (i < this.motidasiList.length) {
        this.addMotidasi();
        i++;
      }
    }
  }

  // 搬出物
  addHansyutu(): void {
    this.hansyutus.push(new FormControl(false));
  }

  get hansyutus(): FormArray {
    return this.pageForm.controls.hansyutus as FormArray;
  }

  // 持出物（持込作業PC・持込工具類・その他持込物）
  addMotidasi(): void {
    this.motidasis.push(new FormControl(false));
  }

  get motidasis(): FormArray {
    return this.pageForm.controls.motidasis as FormArray;
  }

}
