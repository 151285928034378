import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ApiheaderHttpInterceptor implements HttpInterceptor{
    constructor(private router: Router) {}

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const tokenKey = sessionStorage.getItem('userToken');

        let authReq = httpRequest;
        
        if ( tokenKey != null)
        {
            authReq = httpRequest.clone({
                headers: httpRequest.headers.set('Authorization', tokenKey)
            });
        }
        
        return next.handle(authReq).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 0) {
                    alert('セッションが切れました。再ログインしてください。')
                    
                    this.router.navigate(['/login']);
                }
                return throwError(error);
            }));
    }
}
