import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { map, tap, catchError } from 'rxjs/operators';
import { Auth, auth0SignInButton } from 'aws-amplify';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class AwsConnectService {
  public loggedIn: BehaviorSubject<boolean>;
  userId: string;
  userPass: string;
  pwResetFlg = false;
  public UserDataConfirm: any;
  public userKbn: string;
  pwChgFlg = false;

  /** Api gateway Cognito専用 */
  private url = 'https://6ot71h49wc.execute-api.ap-northeast-1.amazonaws.com/cognitoUserConnect';
  private headers: any = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  /** ログイン */
  UserCheck(id: string, pass: string): Observable<any> {
    return fromPromise(this.isEnableUser(id).then(res => {
      if(!res){
        return Promise.reject();
      }
      return Auth.signIn(id, pass).then(userdata => {
        this.userId = id;
        this.userPass = pass;
        this.UserDataConfirm = userdata;
  
        // 初回ログイン時はパスワード変更へ
        if (this.UserDataConfirm != null) {
          if (this.UserDataConfirm.challengeName === 'NEW_PASSWORD_REQUIRED' || this.UserDataConfirm.challengeName === 'SMS_MFA') {
            return;
          }
        }
        sessionStorage.setItem('userToken', userdata.signInUserSession.idToken.jwtToken);
      })
    })).pipe(tap(() => this.loggedIn.next(true)));
  }

  /** ログイン認証 */
  UserNinsyoCheck(code: string): Observable<any> {
    return fromPromise(Auth.confirmSignIn(this.UserDataConfirm, '' + code + '', 'SMS_MFA').then(userdata => {
      this.UserDataConfirm = userdata;
      sessionStorage.setItem('userToken', userdata.signInUserSession.idToken.jwtToken);
    })).pipe(tap(() => this.loggedIn.next(true)));
  }

  /** パスワード変更 */
  PasswordChange(password: string) {
    Auth.completeNewPassword(this.UserDataConfirm, password, null).then(userdata1 => {

      //社員のみ二段階認証なしに設定
      if(this.userId.slice(0, 2) === '10'){
        Auth.setPreferredMFA(userdata1, 'NOMFA');
      }else{
        Auth.setPreferredMFA(userdata1, 'SMS');
      }
      alert('パスワードの更新が完了しました。再度ログインして下さい。');
      this.router.navigate(['/login']);
    })
    .catch(err => {
      console.log(err);
      alert('パスワードの変更処理でエラーが発生しました。\nエラーコード：' + err.code);
    });
  }

  /** パスワード変更(すでに初回ログイン済の場合) */
  async ChangePassword(oldPassword, newPassword) {
    const user = await Auth.currentAuthenticatedUser();
    Auth.changePassword(user, oldPassword, newPassword).then(userdata1 => {
      alert('パスワードの更新が完了しました。\n再度ログインして下さい。');
      this.router.navigate(['/login']);
    }).catch(err => {
      console.log(err); 
      alert('パスワードの変更処理でエラーが発生しました。\nエラーコード：' + err.code);
    });
  }

  /** パスワード忘れた方へメール送信 */
  PasswordForgotJikkou(code: string, pw: string) {
    this.pwResetFlg = false;
    Auth.forgotPasswordSubmit(this.userId, '' + code + '', pw).then(data => {
      alert('パスワードの再発行が完了しました。再度ログインして下さい。');
      this.router.navigate(['/login']);
    }).catch(err => {
      console.log('パスワード再発行エラー' + err);
    });
  }

  /** 認証コード再発行 */
  ResendCode() {
    Auth.signIn(this.userId, this.userPass).then(userdata => {
      this.UserDataConfirm = userdata;
      // sessionStorage.setItem('userToken', userdata.signInUserSession.idToken.jwtToken);
      alert('認証コードの再発行が完了しました。');
    });
  }

  /** signout */
  Logout(): Observable<any> {
    sessionStorage.removeItem('userToken');
    return fromPromise(Auth.signOut()).pipe(tap(() => this.loggedIn.next(false)));
  }

  /** API Gateway Cognito専用 */
  /** ユーザー作成 */
  createUser(prmList: any): any {
    const apiUrl = this.url + '/createuser';
    return this.http.post(apiUrl, prmList, this.headers).toPromise().then(res => {
      return;
    }).catch((error) => console.log(error));
  }

  /** ユーザー修正 */
  editUser(prmList: any): any {
    const apiUrl = this.url + '/edituser';
    return this.http.post(apiUrl, prmList, this.headers).toPromise().then(res => {
      return;
    }).catch((error) => console.log(error));
  }

  /** アカウントリセット. パスワード忘れた方へメール送信  */
  passwordReset(userId: string): any {
    const apiUrl = this.url + '/resetaccount';
    const prmList = {
      username: userId
    };
    return this.http.post(apiUrl, prmList, this.headers).toPromise().then(res => {
      this.userId = userId;
      this.pwResetFlg = true;
      
      if (res["err"] === null)
      {
        alert('メールで届いた手順に従って新しいパスワードを設定して下さい。');
      }
      else
      {
        alert('アカウントを初期パスワードで認証してください。');
      }      
      return res["err"];
    }).catch((error) => console.log('パスワード認証メール送信エラー' + error));
  }

  deleteExpiredUser(): Promise<any>{
    const apiUrl = 'https://5naolg1383.execute-api.ap-northeast-1.amazonaws.com/deleteUser/expireduser';
    const prmList = {};
    return this.http.post<any>(apiUrl, prmList).toPromise().then(res => {
      if(res === null){
        return null;
      }
      return res;
    }).catch(err => console.log(err));
  }

  isEnableUser(id: string): Promise<boolean>{
    const apiUrl = 'https://6vvhmak3df.execute-api.ap-northeast-1.amazonaws.com/LambdaStage_Test/enableuser';
    const param = {
        userId: id
    }
    return this.http.get(apiUrl, {params: param}).toPromise().then(res => {
      if(res['body']['isEnable']){
        return true;
      }else{
        return false;
      }
    });
  }
}
