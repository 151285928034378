import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { SyokaiData } from '@class/syokai-data';
import { UserDataService } from '@service/user-data.service';
import { DbConnectService } from '@service/db-connect.service';
import { ModalService } from '@service/modal.service'
import { SpinnerModalComponent } from '@spinner/spinner-modal.component';

@Component({
  selector: 'app-sinsei-syokai-kensaku',
  templateUrl: './sinsei-syokai-kensaku.component.html',
  styleUrls: ['../sinsei-syokai.component.css']
})
export class SinseiSyokaiKensakuComponent implements OnInit {
  @Input() gridData: SyokaiData[];
  @Output() sinseiNo = new EventEmitter();
  pageForm: FormGroup;
  syokaiData: SyokaiData[];
  syokaiDataDisp: SyokaiData[];

  pageIndex: number;
  totalPage: number;
  dataCount: number;
  keibi: boolean;

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private userData: UserDataService,
    private dbConnect: DbConnectService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef
  ) {
    this.pageForm = this.fb.group({
      status: '',
      sinseiNo: '',
      nyukanYoteiYmdFrom: '',
      nyukanYoteiYmdTo: '',
      sinseiNameKn: ''
    });
    this.syokaiData = [{
      sinseiNo: '',
      sinseiKjnNo: '',
      sinseiGroup: '',
      sinseiNameKn: '',
      sinseiNameKj: '',
      status: '',
      statusDisp: '',
      nyukanYoteiYmd: '',
      nyukanYoteiTime: '',
      taikanYoteiYmd: '',
      taikanYoteiTime: '',
      group: '',
      mokuteki: '',
      mokutekiSyosai: '',
      spAreas: '',
      pcAreas: '',
      dcAreas: '',
      //sdAreas: '',
      camera: false,
      phone: false,
      uploadFile: '',
      mainKjnNo: '',
      //subKjnNo: '',   //★削除
      attendKjnNo: '',
      specialFlg: false,
      specialReason: '',
      specialExtra: ''
    }];
    this.syokaiDataDisp = [{
      sinseiNo: '',
      sinseiKjnNo: '',
      sinseiGroup: '',
      sinseiNameKn: '',
      sinseiNameKj: '',
      status: '',
      statusDisp: '',
      nyukanYoteiYmd: '',
      nyukanYoteiTime: '',
      taikanYoteiYmd: '',
      taikanYoteiTime: '',
      group: '',
      mokuteki: '',
      mokutekiSyosai: '',
      spAreas: '',
      pcAreas: '',
      dcAreas: '',
      //sdAreas: '',
      camera: false,
      phone: false,
      uploadFile: '',
      mainKjnNo: '',
      //subKjnNo: '',   //★削除  
      attendKjnNo: '',
      specialFlg: false,
      specialReason: '',
      specialExtra: ''
    }];

    this.keibi = false;
  }

  ngOnInit(): void {
    this.pageIndex = 0;
    this.totalPage = 0;

    // 1ページの最大データ表示数
    this.dataCount = 5;

    //警備員かどうか
    if(this.userData.keep.role === '5'){
      this.keibi = true;
    }

  }

  // 抽出ボタン　クリック
  async btnExtractClick(): Promise<void> {
    await this.setData();

    // ステータス
    let status = '';
    const value = this.pageForm.controls.status.value;
    if (value === '01') {
      status = '申請中';
    } else if (value === '02') {
      status = '承認済';
    } else if (value === '03') {
      status = '入館中';
    } else if (value === '04') {
      status = '終了';
    } else if (value === '05') {
      status = '退館済';
    } else if (value === '06') {
      status = '未入館';
    } else {
      status = '';
    }
    if (status !== '') {
      this.syokaiData = this.syokaiData.filter(obj => obj.statusDisp === status);
    }

    // 申請番号
    if (this.pageForm.controls.sinseiNo.value !== '') {
      this.syokaiData = this.syokaiData.filter(obj => String(obj.sinseiNo) === this.pageForm.controls.sinseiNo.value);
    }

    // 入館予定日
    let nyukanYoteiYmdFrom = this.pageForm.controls.nyukanYoteiYmdFrom.value;
    let nyukanYoteiYmdTo = this.pageForm.controls.nyukanYoteiYmdTo.value;
    if (nyukanYoteiYmdFrom !== '' || nyukanYoteiYmdTo !== '') {
      if (nyukanYoteiYmdFrom === '') {
        // 開始値が選択されていなければ終了値を設定する
        this.pageForm.controls.nyukanYoteiYmdFrom.setValue(nyukanYoteiYmdTo);
        nyukanYoteiYmdFrom = nyukanYoteiYmdTo;
      } else if (nyukanYoteiYmdTo === '') {
        // 終了値が選択されていなければ開始値を設定する
        this.pageForm.controls.nyukanYoteiYmdTo.setValue(nyukanYoteiYmdFrom);
        nyukanYoteiYmdTo = nyukanYoteiYmdFrom;
      }

      this.syokaiData = this.syokaiData.filter(obj => obj.nyukanYoteiYmd >= this.datePipe.transform(nyukanYoteiYmdFrom, 'yyyy年MM月dd日'));
      this.syokaiData = this.syokaiData.filter(obj => obj.nyukanYoteiYmd <= this.datePipe.transform(nyukanYoteiYmdTo, 'yyyy年MM月dd日'));
    }

    // 申請者氏名
    if (this.pageForm.controls.sinseiNameKn.value !== '') {
      this.syokaiData = this.syokaiData.filter(obj => !obj.sinseiNameKn.indexOf(this.pageForm.controls.sinseiNameKn.value));  //前方一致
      //this.syokaiData = this.syokaiData.filter(obj => obj.sinseiNameKn.indexOf(this.pageForm.controls.sinseiNameKn.value) > -1);  //部分一致
    }

    // ページネーション
    this.pageIndex = 0;
    this.totalPage = Math.ceil(this.syokaiData.length / this.dataCount);
    this.changePage();
  }

  // クリアボタン　クリック
  btnClearClick(): void {
    this.pageForm.controls.status.setValue('');
    this.pageForm.controls.sinseiNo.setValue('');
    this.pageForm.controls.nyukanYoteiYmdFrom.setValue('');
    this.pageForm.controls.nyukanYoteiYmdTo.setValue('');
    this.pageForm.controls.sinseiNameKn.setValue('');
  }

  // データ行　クリック
  btnRecordClick(index: number): void {
    if (this.syokaiDataDisp[index].sinseiNo === null || this.syokaiDataDisp[index].sinseiNo === '') {
      return;
    }
    this.sinseiNo.emit(this.syokaiDataDisp[index].sinseiNo);
  }
  async setData(): Promise<void> {
    this.syokaiData.length = 0;
    //this.gridData.forEach(obj => {  //同期処理へ変更
    for(let obj of this.gridData){
      const data = new SyokaiData(
        obj.sinseiNo,
        obj.sinseiKjnNo,
        obj.sinseiGroup,
        obj.sinseiNameKn,
        obj.sinseiNameKj,
        obj.status,
        this.setStatus(obj.status),
        this.datePipe.transform(obj.nyukanYoteiYmd || ' ' || obj.nyukanYoteiTime, 'yyyy年MM月dd日'),
        this.datePipe.transform(obj.nyukanYoteiYmd || ' ' || obj.nyukanYoteiTime, 'hh時mm分'),
        this.datePipe.transform(obj.taikanYoteiYmd || ' ' || obj.taikanYoteiTime, 'yyyy年MM月dd日'),
        this.datePipe.transform(obj.taikanYoteiYmd || ' ' || obj.taikanYoteiTime, 'hh時mm分'),
        obj.group,
        obj.mokuteki,
        obj.mokutekiSyosai,
        obj.spAreas,
        obj.pcAreas,
        obj.dcAreas,
        //obj.sdAreas,
        obj.camera,
        obj.phone,
        obj.uploadFile,
        obj.mainKjnNo,
        //obj.subKjnNo,  //★削除
        obj.attendKjnNo,
        obj.specialFlg,
        obj.specialReason,
        obj.specialExtra
      );
      await this.setStatusEx(data);
      this.syokaiData.push(data);
    //});
    };

    // ページネーション
    this.pageIndex = 0;
    this.totalPage = Math.ceil(this.syokaiData.length / this.dataCount);
    this.changePage();

  }

  setStatus(statusCd: string): string {
    let status = '';
    if (statusCd === '01' || statusCd === '02' || statusCd === '03') {
      status = '申請中';
    } else if (statusCd === '04') {
      status = '承認済';
    } else if (statusCd === '05') {
      status = '入館中';
    } else if (statusCd === '06') {
      status = '終了';
    }

    return status;
  }

  async setStatusEx(syokai : SyokaiData) : Promise<void>{
    //入館中の状態ではない場合は処理しない
    if(syokai.status !== '05'){
      return;
    }
    //入館中でnst011_nyukansyaの状態が全員退館済の場合は退館済とする
    const prmList1 = [{
      key: ':pSINSEI_NO',
      value: syokai.sinseiNo
    }];

    await this.dbConnect.CmdSql('sinseiSyokai', '13', prmList1).then(result => {
      if (result === null) {
        //履歴が存在しない場合は何もしない
        syokai.statusDisp = '未入館';
      }else{
        const data = result;
        data.every(obj => {
          if(obj.nst011_status === '03'){
            //退館済の場合
            syokai.statusDisp = '退館済';
          }
        });
      }
    });
  }

  onNextPage(): void {
    if (this.pageIndex === this.totalPage -1) {
      return;
    }
    this.pageIndex++;
    this.changePage();

  }

  onBackPage(): void {
    if (this.pageIndex === 0) {
      return;
    }
    this.pageIndex--;
    this.changePage();
  }

  changePage(): void {
    let val = 0;
    this.syokaiDataDisp.length = 0;
    while (val < this.dataCount) {
      const index = (this.pageIndex) * this.dataCount + val;
      if (index < this.syokaiData.length) {
        const data = new SyokaiData(
          this.syokaiData[index].sinseiNo,
          this.syokaiData[index].sinseiKjnNo,
          this.syokaiData[index].sinseiGroup,
          this.syokaiData[index].sinseiNameKn,
          this.syokaiData[index].sinseiNameKj,
          this.syokaiData[index].status,
          this.syokaiData[index].statusDisp,
          this.syokaiData[index].nyukanYoteiYmd,
          this.syokaiData[index].nyukanYoteiTime,
          this.syokaiData[index].taikanYoteiYmd,
          this.syokaiData[index].taikanYoteiTime,
          this.syokaiData[index].group,
          this.syokaiData[index].mokuteki,
          this.syokaiData[index].mokutekiSyosai,
          this.syokaiData[index].spAreas,
          this.syokaiData[index].pcAreas,
          this.syokaiData[index].dcAreas,
          //this.syokaiData[index].sdAreas,
          this.syokaiData[index].camera,
          this.syokaiData[index].phone,
          this.syokaiData[index].uploadFile,
          this.syokaiData[index].mainKjnNo,
          //this.syokaiData[index].subKjnNo,  //★削除
          this.syokaiData[index].attendKjnNo,
          this.syokaiData[index].specialFlg,
          this.syokaiData[index].specialReason,
          this.syokaiData[index].specialExtra
        );
        this.syokaiDataDisp.push(data);
      }
      val++;
    }
  }


  //当日検索・翌日検索
  async btnExtractTodayClick(value : number = 0): Promise<void> {
    this.modalService.openModal(SpinnerModalComponent, this.viewContainerRef, []);
    await this.setData();

    // 入館予定日
    let date = new Date();
    date.setDate(date.getDate() + value);

    let nyukanYoteiYmdFrom = this.datePipe.transform(date, 'yyyy年MM月dd日');
    let nyukanYoteiYmdTo = this.datePipe.transform(date, 'yyyy年MM月dd日');

    this.syokaiData = this.syokaiData.filter(obj => obj.nyukanYoteiYmd <= nyukanYoteiYmdFrom);
    this.syokaiData = this.syokaiData.filter(obj => obj.taikanYoteiYmd >= nyukanYoteiYmdTo);
    //this.syokaiData = this.syokaiData.filter(obj => obj.status === '04' || obj.status === '05')

    // ページネーション
    this.pageIndex = 0;
    this.totalPage = Math.ceil(this.syokaiData.length / this.dataCount);
    this.changePage();
    this.modalService.closeModal();
  }

}
