import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MasterData } from '@class/master-data';
import { DbConnectService } from '@service/db-connect.service';
import { AsyncValidatorService } from '@utility/async-validator.service';
import { CustomValidator } from '@utility/custom-validator';

@Component({
  selector: 'app-master-toroku-nyuryoku',
  templateUrl: './master-toroku-nyuryoku.component.html',
  styleUrls: ['../master-toroku.component.css']
})
export class MasterTorokuNyuryokuComponent implements OnInit {
  @Output() masterData = new EventEmitter();
  @Output() changeFlg = new EventEmitter();
  pageForm: FormGroup;
  item: MasterData;
  syain: boolean;
  touchFlg: boolean;

  constructor(
    private fb: FormBuilder,
    private dbConnect: DbConnectService,
    private asyncValidators: AsyncValidatorService
  ) {
    this.pageForm = fb.group({
      syubetu: ['', [Validators.required]],
      syainNo: ['', [Validators.required]],
      role: ['', [Validators.required]],
      group: ['', [Validators.required]],
      nameKnSei: ['', [Validators.required, CustomValidator.katakana]],
      nameKnMei: ['', [Validators.required, CustomValidator.katakana]],
      nameKjSei: ['', [Validators.required]],
      nameKjMei: ['', [Validators.required]],
      telNo: ['', [Validators.required, CustomValidator.numeric]],
      telNoCheck: ['', [Validators.required]],
      telNoEqual: [null, [Validators.requiredTrue]],
      email: ['', [Validators.required], [this.asyncValidators.existEmail()]],
      emailCheck: ['', [Validators.required]],
      emailEqual: [null, [Validators.requiredTrue]],
      //syainNoMain: ['', [Validators.required]], ★
      //syainNoMainCheck: [null, [Validators.requiredTrue]], ★
      //syainNoSub: ['', [Validators.required]], ★
      //syainNoSubCheck: [null, [Validators.requiredTrue]] ★
    });

    this.item = {
      syubetu: '',
      syainNo: '',
      role: '',
      roleDisp: '',
      group: '',
      nameKn: '',
      nameKj: '',
      telNo: '',
      email: '',
      kjnNoMain: '',
      syainNoMain: '', //★未使用
      groupMain: '', //★未使用
      nameKnMain: '', //★未使用
      nameKjMain: '', //★未使用
      telNoMain: '', //★未使用
      emailMain: '', //★未使用
      kjnNoSub: '', //★未使用
      syainNoSub: '', //★未使用
      groupSub: '', //★未使用
      nameKnSub: '', //★未使用
      nameKjSub: '', //★未使用
      telNoSub: '', //★未使用
      emailSub: '' //★未使用
    };
  }

  ngOnInit(): void {
    this.syain = false;
    this.touchFlg = false;
  }

  CmbSyubetuChange(): void {
    if (this.pageForm.controls.syubetu.value === '10') {
      // 社員
      this.syain = true;

      this.pageForm.controls.syainNo.enable();
      this.pageForm.controls.role.enable();
      //this.pageForm.controls.syainNoMain.disable();
      //this.pageForm.controls.syainNoMainCheck.disable();
    } else {
      // 社員以外
      this.syain = false;

      this.pageForm.controls.syainNo.disable();
      this.pageForm.controls.role.disable();
      //this.pageForm.controls.syainNoMain.enable();
      //this.pageForm.controls.syainNoMainCheck.enable();
    }
  }

/** ★ 削除
  btnKensakuMainClick(): void {
    const syainNo = this.pageForm.controls.syainNoMain.value;

    if (syainNo === null || syainNo === '') {
      this.initTantosyaData('Main');
      return;
    }

    const prmList = [
      {
        key: ':pSYAIN_NO',
        value: syainNo
      }];

    this.dbConnect.CmdSql('masterToroku', '1', prmList).then(result => {
      if (result === null) {
        this.initTantosyaData('Main');
        return;
      }
      const data = result[0];
      this.item.syainNoMain = syainNo.toString();
      this.item.kjnNoMain = data.nsm001_kjn_no;
      this.item.groupMain = data.nsm001_group;
      this.item.nameKnMain = data.nsm001_name_kn;
      this.item.nameKjMain = data.nsm001_name_kj;
      this.item.telNoMain = data.nsm001_tel_no;
      this.item.emailMain = data.nsm001_email;
      this.pageForm.controls.syainNoMainCheck.setValue(true);
    });
  }

  btnKensakuSubClick(): void {
    const syainNo = this.pageForm.controls.syainNoSub.value;

    if (syainNo === null || syainNo === '') {
      this.initTantosyaData('Sub');
      return;
    }

    const prmList = [{
      key: ':pSYAIN_NO',
      value: syainNo
    }];

    this.dbConnect.CmdSql('masterToroku', '1', prmList).then(result => {
      if (result === null) {
        this.initTantosyaData('Sub');
        return;
      }
      const data = result[0];
      this.item.syainNoSub = syainNo.toString();
      this.item.kjnNoSub = data.nsm001_kjn_no;
      this.item.groupSub = data.nsm001_group;
      this.item.nameKnSub = data.nsm001_name_kn;
      this.item.nameKjSub = data.nsm001_name_kj;
      this.item.telNoSub = data.nsm001_tel_no;
      this.item.emailSub = data.nsm001_email;
      this.pageForm.controls.syainNoSubCheck.setValue(true);
    });
  }
**/

  btnNextClick(): void {
    this.setTouched();
    if (this.pageForm.invalid) {
      return;
    }

    this.setMasterData();
    this.masterData.emit(this.item);
    this.changeFlg.emit(true);
  }

  setTouched(): void {
    this.touchFlg = true;
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

/** ★ 削除
  initTantosyaData(tanto: string): void {
    if (tanto === 'Main') {
      // 社内担当者（正）
      this.item.syainNoMain = '';
      this.item.groupMain = '';
      this.item.nameKnMain = '';
      this.item.nameKjMain = '';
      this.item.telNoMain = '';
      this.item.emailMain = '';
      this.pageForm.controls.syainNoMainCheck.setValue(false);
    } else if (tanto === 'Sub') {
      // 社内担当者（副）
      this.item.syainNoSub = '';
      this.item.groupSub = '';
      this.item.nameKnSub = '';
      this.item.nameKjSub = '';
      this.item.telNoSub = '';
      this.item.emailSub = '';
      this.pageForm.controls.syainNoSubCheck.setValue(false);
    }
  }
**/

  setMasterData(): void {
    this.item.syubetu = this.getUserKbn(this.pageForm.controls.syubetu.value);
    this.item.syainNo = this.pageForm.controls.syainNo.value;
    this.item.role = this.pageForm.controls.role.value;
    this.item.roleDisp = this.getRole(this.pageForm.controls.role.value);
    this.item.group = this.pageForm.controls.group.value;
    this.item.nameKn = this.pageForm.controls.nameKnSei.value + '　' + this.pageForm.controls.nameKnMei.value;
    this.item.nameKj = this.pageForm.controls.nameKjSei.value + '　' + this.pageForm.controls.nameKjMei.value;
    this.item.telNo = this.pageForm.controls.telNo.value;
    this.item.email = this.pageForm.controls.email.value;
  }



  checkTelNo() {
    const equal = this.pageForm.controls.telNo.value === this.pageForm.controls.telNoCheck.value;
    this.pageForm.controls.telNoEqual.setValue(equal);
  }

  checkEmail() {
    const equal = this.pageForm.controls.email.value === this.pageForm.controls.emailCheck.value;
    this.pageForm.controls.emailEqual.setValue(equal);
  }

  getUserKbn(userKbn: string): string {
    let result = '';
    if (userKbn === '10') {
      result = '社員';
    } else if (userKbn === '20') {
      result = '社外';
    } else if (userKbn === '30') {
      result = '警備員';
/** ★ 削除
    } else if (userKbn === '41') {
      result = 'ベンダー（Gr.1）';
    } else if (userKbn === '42') {
      result = 'ベンダー（Gr.2）';
    } else if (userKbn === '43') {
      result = 'ベンダー（Gr.3）';
    } else if (userKbn === '44') {
      result = 'ベンダー（Gr.4）';
**/
    }

    return result;
  }

  getRole(role: string): string {
    let result = '';
    if (role === '1') {
      result = '担当者';
    } else if (role === '2') {
      result = 'DS部　部次長';
    } else if (role === '3') {
      result = 'DS部　SOG社員';
    } else if (role === '4') {
      result = 'DS部　FOG社員';
    }

    return result;
  }

}
