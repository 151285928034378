import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AwsMailService {

  /** Api gateway メール送信用 */
  private url: string = "https://03t3pzotv5.execute-api.ap-northeast-1.amazonaws.com/sendMail";
  private headers: any = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(
    private http: HttpClient
    ) { }

  sendAccCreateTuti(prmList: any): any{
    const apiUrl = this.url + '/usercreatetuti';

    return this.http.post(apiUrl, prmList, this.headers)
    .toPromise()
    .then(res => {
      console.log("メール送信終了");
      return;
    })
    .catch(
      (error) => console.log("ユーザー登録メール送信エラー"+error)
    );  
  }

  //★ Add Start
  sendAccEditTuti(prmList: any): any{
    const apiUrl = this.url + '/useredittuti';

    return this.http.post(apiUrl, prmList, this.headers)
    .toPromise()
    .then(res => {
      console.log("メール送信終了");
      return;
    })
    .catch(
      (error) => console.log("ユーザー修正メール送信エラー"+error)
    );  
  }
  //★ End

  sendSinseiTorokuTuti(prmList: any): any {
    const apiUrl = this.url + '/sinseitutimail';

    return this.http.post(apiUrl, prmList, this.headers)
    .toPromise()
    .then(res => {
      console.log("メール送信終了");
      return;
    })
    .catch(
      (error) => console.log("申請登録通知メール送信エラー"+error)
    );  
  }

  sendShouninZumiTuti(prmList: any): any {
    const apiUrl = this.url + '/shounintuti';

    return this.http.post(apiUrl, prmList, this.headers)
    .toPromise()
    .then(res => {
      console.log("メール送信終了");
      return;
    })
    .catch(
      (error) => console.log("申請承認通知メール送信エラー"+error)
    );  
  }

  sendShouninZumiTutiSinsei(prmList: any): any {
    const apiUrl = this.url + '/shounintutisinsei';

    return this.http.post(apiUrl, prmList, this.headers)
    .toPromise()
    .then(res => {
      console.log("メール送信終了");
      return;
    })
    .catch(
      (error) => console.log("申請承認申請者通知メール送信エラー"+error)
    );  
  }

  sendSinseiDeleteTuti(prmList: any): any {
    const apiUrl = this.url + '/sinseideletetuti';

    return this.http.post(apiUrl, prmList, this.headers)
    .toPromise()
    .then(res => {
      console.log("メール送信終了");
      return;
    })
    .catch(
      (error) => console.log("申請却下通知メール送信エラー"+error)
    );  
  }

  /**
* @return 仮登録が成功した場合はtrue　メアド重複等の原因で登録できなかった場合はfalse
*/
  sendKaritorokuMail(mailAddress: string):Promise<boolean>{
    const params = {
      emailAddr: mailAddress
    };

    return this.http.post(`${this.url}/regmastertuti`, params, {observe: 'body', responseType: 'json'})
      .toPromise()
      .then(res => {
        if(res['statusCode'] == 400){
          Promise.reject();
        }
        return res['body'].success;
      });
  }
}
