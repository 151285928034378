import { Component, OnInit, ValueProvider, ViewContainerRef } from '@angular/core';
import { AwsConnectService } from '@service/aws-connect.service';
import { ModalService } from '@service/modal.service';
import { SpinnerModalComponent } from '@spinner/spinner-modal.component';

@Component({
  selector: 'app-expired-delete',
  templateUrl: './expired-delete.component.html',
  styleUrls: ['./expired-delete.component.css']
})
export class ExpiredDeleteComponent implements OnInit {

  constructor(
    private awsConnect: AwsConnectService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
  }

  async onBtnClick(): Promise<void>{
    this.modalService.openModal(SpinnerModalComponent, this.viewContainerRef, []);
    const result = await this.awsConnect.deleteExpiredUser();
    this.modalService.closeModal();
    if(result === null || result.body.count === 0){
      alert('期限切れのユーザは存在しません');
    }else{
      alert(result.body.count + '件のユーザを削除しました');
    }
  }

}
