<body>
  <app-menu>
    <div class="container">
      <div [hidden]="!uketuke">
        <p class="h2 mb-5 title">入退館受付</p>
        <form class="needs-validation ml-3 mr-3 mb-3" [formGroup]="pageForm">
          <div class="form-row">
            <div class="col-md-2 mb-3">
              <label class="font-weight-bold">入館予定日時</label>
            </div>
            <div class="col-md-8 mb-3">
              <label class="ml-3">{{nyukanYoteiDat}}</label>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2 mb-3">
              <label class="font-weight-bold">退館予定日時</label>
            </div>
            <div class="col-md-8 mb-3">
              <label class="ml-3">{{taikanYoteiDat}}</label>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2">
              <label class="font-weight-bold">社内担当者情報</label>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2 ml-3">
              <label>主担当</label>
            </div>
            <label>{{mainTanto}}</label>
          </div>
          <!--
          <div class="form-row">
            <div class="col-md-2 ml-3">
              <label>副担当</label>
            </div>
            <label>{{subTanto}}</label>
          </div>
          -->
          <div class="form-row">
            <div class="col-md-2 ml-3 mb-3">
              <label>アテンド社員</label>
            </div>
            <label>{{attendTanto}}</label>
          </div>
          <div class="form-row">
            <div class="col-md-2 mb-3">
              <label class="font-weight-bold">団体名</label>
            </div>
            <div class="col-md-8 mb-3">
              <label class="ml-3">{{syokaiData.group}}</label>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2 mb-3">
              <label class="font-weight-bold">入館目的</label>
            </div>
            <div class="col-md-8 mb-3">
              <label class="ml-3">{{syokaiData.mokuteki}}</label>
            </div>
          </div>
          <div class="form-row mb-3">
            <div class="col-md-2 ml-3">
              <label>詳細</label>
            </div>
            <div class="col-md-8">
              <label>{{syokaiData.mokutekiSyosai}}</label>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2">
              <label class="font-weight-bold">入館者</label>
            </div>
          </div>
          <div formArrayName="nyukansyas">
          <div *ngFor="let nyukansya of nyukansyaData; let i = index" [formGroupName]="i">
            <div class="form-row mb-3">
              <div class="row col-md-8">
                <div class="col-md-2 ml-3">
                  <label>入館者{{i + 1}}</label>
                </div>
                <div class="col-md-8 mb-2">
                  <label>{{nyukansya.nameKj}}</label>
                  <span class="col-md-2 mb-2">
                    <button class="btn btn-outline-danger btn-sm ml-2" type="submit" name="btnNyukansyaEdit" (click)="btnEditClick(i)">入館者修正</button>
                  </span>
                </div>
                <div class="col-md-2 ml-3"></div>
                <div class="col-md-8 mb-1">
                  <button class="btn btn-outline-info btn-sm" type="submit" name="btnNyukan"
                    (click)="btnNyukanClick(i)">入　館</button>
                  <button class="btn btn-outline-info btn-sm ml-2" type="submit" name="btnTempTaikan"
                    (click)="btnTempTaikanClick(i)">一時退館</button>
                  <button class="btn btn-outline-info btn-sm ml-2" type="submit" name="btnReNyukan"
                    (click)="btnReNyukanClick(i)">再入館</button>
                  <button class="btn btn-outline-info btn-sm ml-2" type="submit" name="btnTaikan"
                    (click)="btnTaikanClick(i)">退　館</button>
                  <label class="ml-4">ステータス：</label>
                  <label class="textRed">{{status[i]}}</label>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-8">
                  <div class="form-check form-check-inline ml-3">
                    <input class="form-check-input" type="checkbox" formControlName="fubi">
                    <label class="form-check-label">申請不備</label>
                  </div>
                  <div class="form-check form-check-inline ml-3">
                    <input class="form-check-input" type="checkbox" formControlName="none">
                    <label class="form-check-label">入館無し</label>
                  </div>
                  
                </div>
              </div>
              <div class="col-md-4 qrcode">
                <img src={{qrcode[i]}}>
              </div>
              
            </div>
          </div>
        </div>
          <div class="form-row">
            <div class="col-md-2 mt-3">
              <label class="font-weight-bold">搬入物</label>
            </div>
            <div class="col-md-8 mt-3" [hidden]="!noHannyu">
              <label class="ml-3">搬入物なし</label>
            </div>
          </div>
          <div *ngFor="let hannyu of hannyuList; let i = index">
            <div class="form-row">
              <div class="col-md-2 ml-3">
                <label>搬入物{{i + 1}}</label>
              </div>
              <div class="col-md-8">
                <label class="ml-3">{{hannyu.meisyo}}</label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2 mt-3">
              <label class="font-weight-bold">搬出物</label>
            </div>
            <div class="col-md-8 mt-3" [hidden]="!noHansyutu">
              <label class="ml-3">搬出物なし</label>
            </div>
          </div>
          <div *ngFor="let hansyutu of hansyutuList; let i = index">
            <div class="form-row">
              <div class="col-md-2 ml-3">
                <label>搬出物{{i + 1}}</label>
              </div>
              <div class="col-md-8">
                <label class="ml-3">{{hansyutu.meisyo}}</label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2 mt-3">
              <label class="font-weight-bold">入館場所</label>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-3">
              <label class="ml-3">SPビル</label>
            </div>
            <div class="col-md-7">
              <label class="ml-3">{{syokaiData.spAreas}}</label>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-3">
              <label class="ml-3">プロセシングセンター</label>
            </div>
            <div class="col-md-7">
              <label class="ml-3">{{syokaiData.pcAreas}}</label>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-3">
              <label class="ml-3">GCC-DC</label>
            </div>
            <div class="col-md-7">
              <label class="ml-3">{{syokaiData.dcAreas}}</label>
            </div>
          </div>
          <!-- <div class="form-row">
            <div class="col-md-3 mb-3">
              <label class="ml-3">第二データセンター</label>
            </div>
            <div class="col-md-7 mb-3">
              <label class="ml-3">{{syokaiData.sdAreas}}</label>
            </div>
          </div> -->
          <div class="form-row">
            <div class="col-md-2">
              <label class="font-weight-bold">持込作業PC</label>
            </div>
          </div>
          <div *ngFor="let pc of pcList; let i = index">
            <div class="form-row">
              <div class="col-md-2 ml-3">
                <label>持込作業PC{{i + 1}}</label>
              </div>
              <div class="col-md-8">
                <label class="ml-3">コンピュータ名：{{pc.meisyo}}</label>
                <label class="ml-3">接続先：{{pc.setuzokusaki}}</label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2 mt-3">
              <label class="font-weight-bold">持込工具類</label>
            </div>
            <div class="col-md-8 mt-3" [hidden]="!noTool">
              <label class="ml-3">持込工具類なし</label>
            </div>
          </div>
          <div *ngFor="let tool of toolList; let i = index">
            <div class="form-row">
              <div class="col-md-2 ml-3">
                <label>持込工具類{{i + 1}}</label>
              </div>
              <div class="col-md-8">
                <label class="ml-3">{{tool.meisyo}}</label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-4 mt-3 mb-3">
              <label class="font-weight-bold">持込カメラ・デジタルカメラ</label>
            </div>
            <div class="col-md-6 mt-3 mb-3">
              <label class="ml-3">{{camera}}</label>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-4 mb-3">
              <label class="font-weight-bold">持込携帯電話・スマートフォン</label>
            </div>
            <div class="col-md-6 mb-3">
              <label class="ml-3">{{phone}}</label>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-4 mb-3">
              <label class="font-weight-bold">特別許可申請</label>
            </div>
            <div class="col-md-6 mb-3">
              <label class="ml-3 font-weight-bold text-danger">{{special}}</label>
            </div>
          </div>
          <div class="form-row" [hidden]="!noSpecial">
            <div class="col-md-4 mb-3">
              <label class="font-weight-bold">特別許可理由</label>
            </div>
            <div class="col-md-6 mb-3">
              <label class="ml-3 font-weight-bold text-danger">{{getSpecialReason()}}</label>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-2">
              <label class="font-weight-bold">その他持込物</label>
            </div>
            <div class="col-md-8" [hidden]="!noOther">
              <label class="ml-3">その他持込物なし</label>
            </div>
          </div>
          <div *ngFor="let other of otherList; let i = index">
            <div class="form-row">
              <div class="col-md-2 ml-3">
                <label>その他持込物{{i + 1}}</label>
              </div>
              <div class="col-md-8">
                <label class="ml-3">{{other.meisyo}}</label>
              </div>
            </div>
          </div>
        </form>
        <div class="center">
          <button class="btn btn-primary ml-3" type="submit" name="btnFinish" (click)="btnFinishClick()">完　了</button>
          <button class="btn btn-secondary ml-3" type="submit" name="btnHome" (click)="btnHomeClick()">ホーム</button>
        </div>
      </div>
      <div *ngIf="nyukan">
        <app-nyukan [nyukansya]="nyukansya" [motikomiData]="motikomiData" (changeFlg)="changeNyukan($event)"></app-nyukan>
      </div>
      <div *ngIf="taikan">
        <app-taikan [nyukansya]="nyukansya" [motikomiData]="motikomiData" (changeFlg)="changeNyukan($event)"></app-taikan>
      </div>
      <div *ngIf="reNyukan">
        <app-sainyukan [nyukansya]="nyukansya" [motikomiData]="motikomiData" (changeFlg)="changeNyukan($event)"></app-sainyukan>
      </div>
      <div *ngIf="tempTaikan">
        <app-itijitaikan [nyukansya]="nyukansya" [motikomiData]="motikomiData" (changeFlg)="changeNyukan($event)"></app-itijitaikan>
      </div>
      <div *ngIf="nyukansyaedit">
        <app-edit [nyukansya]="nyukansya" (changeFlg)="changeEdit($event)"></app-edit>
      </div>
    </div>
  </app-menu>
</body>
