export class AreaData {
  no: string;     // エリア番号
  kbn: string;    // エリア区分
  level: string;  // セキュリティレベル
  taisyo: string; // 入館対象区分
  meisyo: string; // エリア名称

  constructor(
    no: string,
    kbn: string,
    level: string,
    taisyo: string,
    meisyo: string
  ) {
    this.no = no;
    this.kbn = kbn;
    this.level = level;
    this.taisyo = taisyo;
    this.meisyo = meisyo;
  }
}
