import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-spinner-modal',
  templateUrl: './spinner-modal.component.html',
  styleUrls: ['./spinner-modal.component.css'],
  animations: [
     trigger('openClose', [
        state('open', style({
           opacity: 1,
        })),
        state('closed', style({
           display: 'none',
           opacity: 0,
        })),
        transition('open => closed', [
           animate('0.2s')
        ]),
        transition('closed => open', [
           animate('0.2s')
        ]),
        transition('* => void', [
           animate('0.2s')
        ]),
        transition('void => *', [
           animate('0.2s')
        ]),
     ])
  ]
})
export class SpinnerModalComponent implements OnInit {
  @Input() data: any;
  isOpen: boolean;

  constructor() {
    this.isOpen =true
   }

  ngOnInit(): void {
  }

  public onClose() {
    this.data.click('NG');
    this.closeModal();
 }

 closeModal() {
    this.isOpen = false;
 }
}
