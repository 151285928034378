<body>
  <div class="container">
    <div class="center">
      <p class="h3 font-weight-normal mb-5">株式会社GCC 入館申請システム</p>
      <p class="h4 font-weight-normal mb-4">パスワードを忘れた場合</p>
    </div>
    <form class="form-inline justify-content-center" [formGroup]="pageForm">
      <div class="form-label-group mx-sm-3 mb-4">
        <label class="sr-only">ログインID</label>
        <input type="text" class="form-control formWidth" placeholder="ログインID" name="edtId"
          [formControl]="pageForm.controls.id">
      </div>
      <div class="form-label-group mx-sm-3 mb-4">
        <label class="sr-only">メールアドレス</label>
        <input type="text" class="form-control formWidth" placeholder="メールアドレス" name="edtMail"
          [formControl]="pageForm.controls.email">
        <small class="form-text text-muted">登録されているメールアドレスを入力して下さい。</small>
        <small class="form-text text-muted">パスワードを変更するメールが送信されます。</small>
      </div>
    </form>
    <div class="center">
      <button type="submit" class="btn btn-primary mr-3 mb-4" name="btnSend" (click)="btnSendClick()">送　信</button>
      <button type="submit" class="btn btn-secondary ml-3 mb-4" name="btnBack" (click)="btnBackClick()">戻　る</button>
    </div>
  </div>
</body>
<div class="center">
  <p class="mt-3 mb-3 text-muted small">Copyright &copy; 2021 GCC Inc. All Rights Reserved.</p>
</div>
