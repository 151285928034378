<body>
    <div class="container">
      <p class="h2 mb-5 title">マスタ修正</p>
      <form class="needs-validation ml-3 mr-3 mb-3" [formGroup]="pageForm">
        <p class="h4 mb-4">申請者情報</p>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label class="font-weight-bold">所属</label>
            <ng-container
              *ngIf="pageForm.controls.group.hasError('required') && (pageForm.controls.group.touched || pageForm.controls.group.dirty)">
              <div class="col-md-12">
                <span class="textRed">※所属を入力してください。</span>
              </div>
            </ng-container>
            <input type="text" class="form-control" [formControl]="pageForm.controls.group" [class.input-invalid]="pageForm.controls.group.invalid && (pageForm.controls.group.touched || pageForm.controls.group.dirty)">
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label class="font-weight-bold">氏名（カナ）</label>
            <ng-container
              *ngIf="pageForm.controls.nameKnSei.hasError('required') && (pageForm.controls.nameKnSei.touched || pageForm.controls.nameKnSei.dirty)">
              <div class="col-md-12">
                <span class="textRed">※セイを入力してください。</span>
              </div>
            </ng-container>
            <ng-container
              *ngIf="pageForm.controls.nameKnSei.hasError('katakana') && (pageForm.controls.nameKnSei.touched || pageForm.controls.nameKnSei.dirty)">
              <div class="col-md-12">
                <span class="textRed">※全角カナで入力してください。</span>
              </div>
            </ng-container>
            <input type="text" class="form-control mb-3" placeholder="セイ" [formControl]="pageForm.controls.nameKnSei" [class.input-invalid]="pageForm.controls.nameKnSei.invalid && (pageForm.controls.nameKnSei.touched || pageForm.controls.nameKnSei.dirty)">
            <ng-container
              *ngIf="pageForm.controls.nameKnMei.hasError('required') && (pageForm.controls.nameKnMei.touched || pageForm.controls.nameKnMei.dirty)">
              <div class="col-md-12">
                <span class="textRed">※メイを入力してください。</span>
              </div>
            </ng-container>
            <ng-container
              *ngIf="pageForm.controls.nameKnMei.hasError('katakana') && (pageForm.controls.nameKnMei.touched || pageForm.controls.nameKnMei.dirty)">
              <div class="col-md-12">
                <span class="textRed">※全角カナで入力してください。</span>
              </div>
            </ng-container>
            <input type="text" class="form-control" placeholder="メイ" [formControl]="pageForm.controls.nameKnMei" [class.input-invalid]="pageForm.controls.nameKnMei.invalid && (pageForm.controls.nameKnMei.touched || pageForm.controls.nameKnMei.dirty)">
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label class="font-weight-bold">氏名（漢字）</label>
            <ng-container
              *ngIf="pageForm.controls.nameKjSei.hasError('required') && (pageForm.controls.nameKjSei.touched || pageForm.controls.nameKjSei.dirty)">
              <div class="col-md-12">
                <span class="textRed">※姓を入力してください。</span>
              </div>
            </ng-container>
            <input type="text" class="form-control mb-3" placeholder="姓" [formControl]="pageForm.controls.nameKjSei" [class.input-invalid]="pageForm.controls.nameKjSei.invalid && (pageForm.controls.nameKjSei.touched || pageForm.controls.nameKjSei.dirty)">
            <ng-container
              *ngIf="pageForm.controls.nameKjMei.hasError('required') && (pageForm.controls.nameKjMei.touched || pageForm.controls.nameKjMei.dirty)">
              <div class="col-md-12">
                <span class="textRed">※名を入力してください。</span>
              </div>
            </ng-container>
            <input type="text" class="form-control" placeholder="名" [formControl]="pageForm.controls.nameKjMei" [class.input-invalid]="pageForm.controls.nameKjMei.invalid && (pageForm.controls.nameKjMei.touched || pageForm.controls.nameKjMei.dirty)">
          </div>
        </div>
        <div [hidden]="syain">
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label class="font-weight-bold">携帯電話</label>
            <ng-container
              *ngIf="pageForm.controls.telNo.hasError('required') && (pageForm.controls.telNo.touched || pageForm.controls.telNo.dirty)">
              <div class="col-md-12">
                <span class="textRed">※携帯電話の番号を入力してください。</span>
              </div>
            </ng-container>
            <ng-container
              *ngIf="pageForm.controls.telNo.hasError('numeric') && (pageForm.controls.telNo.touched || pageForm.controls.telNo.dirty)">
              <div class="col-md-12">
                <span class="textRed">※「-」(ハイフン)無しで半角数字のみを入力してください。</span>
              </div>
            </ng-container>
            <input type="text" class="form-control" [formControl]="pageForm.controls.telNo" [class.input-invalid]="pageForm.controls.telNo.invalid && (pageForm.controls.telNo.touched || pageForm.controls.telNo.dirty)">
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label class="font-weight-bold">携帯電話（確認用）</label>
            <ng-container
              *ngIf="pageForm.controls.telNoCheck.hasError('required') && (pageForm.controls.telNoCheck.touched || pageForm.controls.telNoCheck.dirty)">
              <div class="col-md-12">
                <span class="textRed">※携帯電話（確認用）の番号を入力してください。</span>
              </div>
            </ng-container>
            <ng-container
              *ngIf="pageForm.controls.telNoEqual.value == false">
              <div class="col-md-12">
                <span class="textRed">※携帯電話が一致しません。</span>
              </div>
            </ng-container>
            <input type="text" class="form-control" (focusout)="checkTelNo()" [formControl]="pageForm.controls.telNoCheck" [class.input-invalid]="pageForm.controls.telNoCheck.invalid && (pageForm.controls.telNoCheck.touched || pageForm.controls.telNoCheck.dirty)">
          </div>
        </div>
      </div>
        <div [hidden]="!syain">
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label class="font-weight-bold">内線</label>
            <ng-container
              *ngIf="pageForm.controls.telNo.hasError('required') && (pageForm.controls.telNo.touched || pageForm.controls.telNo.dirty)">
              <div class="col-md-12">
                <span class="textRed">※内線の番号を入力してください。</span>
              </div>
            </ng-container>
            <input type="text" class="form-control" [formControl]="pageForm.controls.telNo" [class.input-invalid]="pageForm.controls.telNo.hasError('required') && (pageForm.controls.telNo.touched || pageForm.controls.telNo.dirty)">
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label class="font-weight-bold">内線（確認用）</label>
            <ng-container
              *ngIf="pageForm.controls.telNoCheck.hasError('required') && (pageForm.controls.telNoCheck.touched || pageForm.controls.telNoCheck.dirty)">
              <div class="col-md-12">
                <span class="textRed">※内線（確認用）の番号を入力してください。</span>
              </div>
            </ng-container>
            <ng-container
              *ngIf="pageForm.controls.telNoEqual.value == false">
              <div class="col-md-12">
                <span class="textRed">※内線の番号が一致しません。</span>
              </div>
            </ng-container>
            <input type="text" class="form-control" (focusout)="checkTelNo()" [formControl]="pageForm.controls.telNoCheck" [class.input-invalid]="pageForm.controls.telNoCheck.hasError('required') && (pageForm.controls.telNoCheck.touched || pageForm.controls.telNoCheck.dirty)">
          </div>
        </div>
      </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label class="font-weight-bold">メールアドレス</label>
            <ng-container
              *ngIf="pageForm.controls.email.hasError('required') && (pageForm.controls.email.touched || pageForm.controls.email.dirty)">
              <div class="col-md-12">
                <span class="textRed">※メールアドレスを入力してください。</span>
              </div>
            </ng-container>
            <input type="email" class="form-control" [formControl]="pageForm.controls.email" [class.input-invalid]="pageForm.controls.email.invalid && (pageForm.controls.email.touched || pageForm.controls.email.dirty)">
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label class="font-weight-bold">メールアドレス（確認用）</label>
            <ng-container
              *ngIf="pageForm.controls.emailCheck.hasError('required') && (pageForm.controls.emailCheck.touched || pageForm.controls.emailCheck.dirty)">
              <div class="col-md-12">
                <span class="textRed">※メールアドレス（確認用）を入力してください。</span>
              </div>
            </ng-container>
            <ng-container
              *ngIf="pageForm.controls.emailEqual.value == false">
              <div class="col-md-12">
                <span class="textRed">※メールアドレスが一致しません。</span>
              </div>
            </ng-container>
            <input type="email" class="form-control" (focusout)="checkEmail()" [formControl]="pageForm.controls.emailCheck" [class.input-invalid]="pageForm.controls.emailCheck.invalid && (pageForm.controls.emailCheck.touched || pageForm.controls.emailCheck.dirty)">
          </div>
        </div>
      </form>
      <ng-container
        *ngIf="pageForm.invalid && touchFlg">
        <span class="textRed">※入力内容が正しくありません。入力内容をご確認ください。</span>
      </ng-container>
      <div class="center">
        <button class="btn btn-info" type="submit" name="btnNext" (click)="btnNextClick()">次　へ</button>
      </div>
    </div>
  </body>
  