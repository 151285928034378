<div class="container">
  <nav class="navbar navbar-dark bg-dark fixed-top">
    <a class="navbar-brand" routerLink="/home">株式会社GCC 入館申請システム</a>
    <div class="navbar-text inlineBlock">
      <svg width="1em" height="1em" class="bi bi-person-circle textWhite mb-1" fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z" />
        <path fill-rule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
        <path fill-rule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z" />
      </svg>
      <label class="ml-2 textWhite">{{userName}}</label>
      <div class="nav-item dropdown ml-4 inlineBlock">
        <a class="btn btn-secondary bg-dark dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <button class="dropdown-item" name="btnChangePassword" (click)="btnChangePasswordClick()">パスワード変更</button>
          <button class="dropdown-item" name="btnLogout" (click)="btnLogoutClick()">ログアウト</button>
        </div>
      </div>
    </div>
  </nav>
  <div class="splitBox leftBox">
    <ul class="nav">
      <li class="nav-item">
        <div [hidden]="!masterToroku">
          <li class="nav-item">
            <a class="nav-link" routerLink="/masterToroku">
              マスタ登録
            </a>
          </li>
        </div>
        <div [hidden]="!masterKanri">
          <li class="nav-item">
            <a class="nav-link" routerLink="/masterKanri">
              マスタ管理
            </a>
          </li>
        </div>
        <div [hidden]="!sinseiToroku">
            <li class="nav-item">
              <a class="nav-link" routerLink="/sinseiToroku">
                入館申請登録
             </a>
          </li>
        </div>
        <div [hidden]="!sinseiSyokai">
          <li class="nav-item">
            <a class="nav-link" routerLink="/sinseiSyokai">
              申請情報照会
            </a>
          </li>
        </div>
        <div [hidden]="!nyutaikanUketuke">
          <li class="nav-item">
            <a class="nav-link" routerLink="/nyutaikanUketuke">
              QRコード読み取り
            </a>
          </li>
        </div>
        <div [hidden]="!masterSyusei">
          <li class="nav-item">
            <a class="nav-link" routerLink="/masterSyusei">
              マスタ修正
            </a>
          </li>
        </div>
        <div [hidden]="!roleChange">
          <li class="nav-item">
            <a class="nav-link" routerLink="/roleChange">
              ロール変更
            </a>
          </li>
        </div>
        <div [hidden]="!domainKanri">
          <li class="nav-item">
            <a class="nav-link" routerLink="/domainKanri">
              ドメイン管理
            </a>
          </li>
        </div>
        <div [hidden]="!nyukanRireki">
          <li class="nav-item">
            <a class="nav-link" routerLink="/nyukanRireki">
              入館履歴照会
            </a>
          </li>
        </div>
      </li>
    </ul>
  </div>
  <ng-content></ng-content>
</div>
