import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { MotikomiData } from '@class/motikomi-data';
import { NyukansyaData } from '@class/nyukansya-data';
import { DbConnectService } from '@service/db-connect.service';
import { UserDataService } from '@service/user-data.service';
import { ModalService } from '@service/modal.service'
import { SpinnerModalComponent } from '@spinner/spinner-modal.component';
declare function tabletDemo(): void;
declare var $;

@Component({
  selector: 'app-sainyukan',
  templateUrl: './sainyukan.component.html',
  styleUrls: ['./sainyukan.component.css']
})
export class SainyukanComponent implements OnInit {
  @Input() nyukansya: NyukansyaData[];
  @Input() motikomiData: MotikomiData[];
  @Output() changeFlg = new EventEmitter();

  pageForm: FormGroup;
  hannyuList: MotikomiData[];
  motikomiList: MotikomiData[];
  noHannyu: boolean;
  noMotikomi: boolean;

  constructor(
    private fb: FormBuilder,
    private dbConnect: DbConnectService,
    private userData: UserDataService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.pageForm = this.fb.group({
      kakunin1: false,
      kakunin2: false,
      kakunin3: false,
      key: '',
      iccard: '',
      hannyus: new FormArray([]),
      motikomis: new FormArray([])
    });

    this.hannyuList = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];

    this.motikomiList = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];
  }

  ngOnInit(): void {
    this.noHannyu = false;
    this.noMotikomi = false;
    this.addHannyu();
    this.addMotikomi();
    this.setMotikomiData();
    this.pageForm.controls.key.setValue(this.nyukansya[0].key);
    this.pageForm.controls.iccard.setValue(this.nyukansya[0].iccard);
  }

  async btnTorokuClick(): Promise<any> {
    if (!confirm('登録します。よろしいですか？')) {
      return;
    }
    
    this.modalService.openModal(SpinnerModalComponent, this.viewContainerRef, []);

    // 入館者ログの登録
    const prmList = [{
      key: ':pSINSEI_NO',
      value: this.nyukansya[0].sinseiNo
    },
    {
      key: ':pSEQ_NO',
      value: this.nyukansya[0].seqNo
    }];
    const result = await this.dbConnect.CmdSql('nyutaikanUketuke', '5', prmList);

    prmList.push({
      key: ':pRIREKI_NO',
      value: result[0].max_rireki_no + 1
    });
    prmList.push({
      key: ':pSTATUS',
      value: '01'
    });
    prmList.push({
      key: ':pKAKUNIN_1',
      value: this.pageForm.controls.kakunin1.value ? '1' : '0'
    });
    prmList.push({
      key: ':pKAKUNIN_2',
      value: this.pageForm.controls.kakunin2.value ? '1' : '0'
    });
    prmList.push({
      key: ':pKAKUNIN_3',
      value: this.pageForm.controls.kakunin3.value ? '1' : '0'
    });
    prmList.push({
      key: ':pKEY',
      value: this.pageForm.controls.key.value
    });
    prmList.push({
      key: ':pICCARD',
      value: this.pageForm.controls.iccard.value
    });
    prmList.push({
      key: ':pINS_KJN_NO',
      value: this.userData.keep.kjnNo
    });
    await this.dbConnect.CmdStored('nyutaikanUketuke', '7', prmList);

    // 持込物ログの登録
    const data: MotikomiData[] = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];
    data.length = 0;
    const hannyus = this.hannyus.controls;
    const motikomis = this.motikomis.controls;
    for (let i = 0; i < hannyus.length; i++) {
      if (hannyus[i].value) {
        data.push(this.hannyuList[i]);
      }
    }
    for (let i = 0; i < motikomis.length; i++) {
      if (motikomis[i].value) {
        data.push(this.motikomiList[i]);
      }
    }

    for (const obj of data) {
      const prmList2 = [{
        key: ':pSINSEI_NO',
        value: obj.sinseiNo
      },
      {
        key: ':pKBN',
        value: obj.kbn
      },
      {
        key: ':pSEQ_NO',
        value: obj.seqNo
      }];
      const result2 = await this.dbConnect.CmdSql('nyutaikanUketuke', '6', prmList2);

      prmList2.push({
        key: ':pRIREKI_NO',
        value: result2[0].max_rireki_no + 1
      });
      prmList2.push({
        key: ':pSTATUS',
        value: '01'
      });
      prmList2.push({
        key: ':pNYUKANSYA_SEQ_NO',
        value: this.nyukansya[0].seqNo
      });
      prmList2.push({
        key: ':pINS_KJN_NO',
        value: this.userData.keep.kjnNo
      });
      await this.dbConnect.CmdStored('nyutaikanUketuke', '8', prmList2);
    }
    this.modalService.closeModal();
    alert('再入館手続きが完了しました。');
    this.changeFlg.emit(false);
  }

  btnBackClick(): void {
    // 戻る
    this.changeFlg.emit(false);
  }

  setMotikomiData(): void {
    // 搬入物
    this.hannyuList = this.motikomiData.filter(obj => obj.kbn.toString() === '1' && obj.status === '');
    this.pageForm.controls.hannyus = new FormArray([]);
    if (this.hannyuList.length === 0) {
      this.noHannyu = true;
    } else {
      let i = 0;
      while (i < this.hannyuList.length) {
        this.addHannyu();
        i++;
      }
    }

    // 持込物（持込作業PC・持込工具類・その他持込物）
    this.motikomiList = this.motikomiData.filter(obj => (obj.kbn.toString() === '3'
      || obj.kbn.toString() === '4' || obj.kbn.toString() === '5') && obj.status === '02');
    this.pageForm.controls.motikomis = new FormArray([]);
    if (this.motikomiList.length === 0) {
      this.noMotikomi = true;
    } else {
      let i = 0;
      while (i < this.motikomiList.length) {
        this.addMotikomi();
        i++;
      }
    }
  }

  // 搬入物
  addHannyu(): void {
    this.hannyus.push(new FormControl(false));
  }

  get hannyus(): FormArray {
    return this.pageForm.controls.hannyus as FormArray;
  }

  // 持込物（持込作業PC・持込工具類・その他持込物）
  addMotikomi(): void {
    this.motikomis.push(new FormControl(false));
  }

  get motikomis(): FormArray {
    return this.pageForm.controls.motikomis as FormArray;
  }

}
