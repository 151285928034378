export class RirekiSummary {
    constructor(
        public id:number,
        public sinseiDate: Date,
        public nyukanYoteiDate: Date,
        public taikanYoteiDate: Date,
        public sinseisyaName: string,
        public status: string,
    ){}
}
