<body>
  <div [ngClass]="{'containerChange': !pincode && !chgpass, 'containerForget': (pincode && !chgpass), 'containerChangeNewPass': (!pincode && chgpass)}">
    <div class="center">
      <p class="h3 font-weight-normal mb-5">株式会社GCC 入館申請システム</p>
      <p class="h4 font-weight-normal mb-4">パスワード変更</p>
    </div>
    <form class="form-inline justify-content-center" [formGroup]="pageForm">
      <div class="form-label-group mx-sm-3 mb-4" [hidden]='!pincode'>
        <label class="sr-only">認証コード</label>
        <input type="number" class="form-control formWidth" placeholder="認証コード"
          [formControl]="pageForm.controls.pincode">
        <small class="form-text text-muted">メールで届いた認証コードを入力して下さい。</small>
      </div>
      <div class="form-label-group mx-sm-3 mb-4" [hidden]='!chgpass'>
        <label class="sr-only">古いパスワード</label>
        <input type="password" class="form-control formWidth" placeholder="古いパスワード"
          [formControl]="pageForm.controls.oldPass">
        <small class="form-text text-muted">現在のパスワードを入力してください。</small>
      </div>
      <div class="form-label-group mx-sm-3 mb-4">
        <label class="sr-only">新しいパスワード</label>
        <input type="password" class="form-control formWidth" placeholder="新しいパスワード"
          [formControl]="pageForm.controls.newPass">
        <small class="form-text text-muted">半角英大小文字、数字、記号のうち３種類以上を<br>
          使用し、10桁以上で入力してください。
        </small>
        <ng-container
        *ngIf="pageForm.controls.newPass.hasError('password') && (pageForm.controls.newPass.touched || pageForm.controls.newPass.dirty)">
        <span class="textRed">※入力条件を満たしていません。</span>
      </ng-container>
      </div>
      <div class="form-label-group mx-sm-3 mb-4">
        <label class="sr-only">新しいパスワード（確認用）</label>
        <input type="password" class="form-control formWidth" placeholder="新しいパスワード（確認用）"
          [formControl]="pageForm.controls.newPassCheck">
        <small class="form-text text-muted">新しいパスワードを再入力して下さい。</small>
      </div>
    </form>
    <div class="center">
      <button type="submit" class="btn btn-primary mb-4" name="btnChange" (click)="btnChangeClick()">変　更</button>
      <button type="submit" class="btn btn-secondary ml-3 mb-4" name="btnBack" (click)="btnBackClick()">戻　る</button>
    </div>
  </div>
  <div class="center">
    <p class="mt-3 mb-3 text-muted small">Copyright &copy; 2021 GCC Inc. All Rights Reserved.</p>
  </div>
</body>
