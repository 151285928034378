<div class="container">
    <form class="ml-3 mr-3 mb-3" [formGroup]="pageForm">
      <p class="h4 mb-5 title">拒否ドメイン登録</p>
      <div class="form-row">
        <div class="col-md-2 mb-3 center">
          <label class="font-weight-bold">ドメイン名</label>
        </div>
        <div class="col-md-8 mb-3">
          <input type="text" class="form-control form-control-sm" [formControl]="pageForm.controls.name" >
        </div>
      </div>

      <div class="col-md-12 mt-3 mb-5 center">
        <button type="button" class="btn btn-info" (click)="btnAddClick()">追　加</button>
        <button type="button" class="btn btn-secondary ml-3" (click)="btnClearClick()">クリア</button>
      </div>

      <p class="h4 mb-5 title">登録済ドメイン</p>
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-end">
          <li class="page-item">
            <button class="page-link" (click)="onBackPage()">前へ</button>
          </li>
          <li class="page-item disabled">
            <label class="page-link">{{pageIndex + 1}} / {{totalPage}}</label>
          </li>
          <li class="page-item">
            <button class="page-link" (click)="onNextPage()">次へ</button>
          </li>
        </ul>
      </nav>
      <div class="tableDetail">
        <table class="table table-hover mb-5">
          <thead class="thead-light center">
            <tr>
              <th class="thCheck col-md-2">チェック</th>
              <th class="thGroup col-md-2">No.</th>
              <th class="thNameKj">ドメイン名</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let obj of domainDataDisp; let i = index" (click)="btnRecordClick(i)">
              <td>
                <input class="form-check-input" type="checkbox" [formControl]="pageForm.controls.check" [(ngModel)]="obj.check">
              </td>
              <td>{{obj.no}}</td>
              <td>{{obj.name}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
    <div class="center">
        <button class="btn btn-danger ml-3" type="submit" name="btnNext" (click)="btnDeleteClick()">削　除</button>
<!--        <button class="btn btn-secondary ml-3" type="submit" name="btnBack" (click)="btnBackClick()">戻　る</button> -->
    </div>
</div>
