<body>
    <app-menu>
        <div class="container">
            <div [hidden]="!kensaku">
                <app-role-change-kensaku [gridData]="gridData" (kjnNo)="changeKensaku($event)"></app-role-change-kensaku>
            </div>
            <div [hidden]="!nyuryoku">
                <app-role-change-nyuryoku [syokaiData]="syokaiData" (kakuninData)="getKakuninData($event)" (changeFlg)="changeNyuryoku($event)"></app-role-change-nyuryoku>
            </div>
            <div [hidden]="!kakunin">
                <app-role-change-kakunin [kakuninData]="kakuninData" (changeFlg)="changeKakunin($event)"></app-role-change-kakunin>
            </div>
        </div>
    </app-menu>
</body>

