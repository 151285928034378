import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AwsConnectService  } from '@service/aws-connect.service';
import { DbConnectService } from '@service/db-connect.service';
import { UserDataService } from '@service/user-data.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  userName: string;

  // メニュー制御用
  masterToroku: boolean;
  masterKanri: boolean;
  sinseiToroku: boolean;
  sinseiSyokai: boolean;
  nyutaikanUketuke: boolean;
  masterSyusei: boolean; //★
  roleChange: boolean; //★
  domainKanri: boolean; //★
  nyukanRireki: boolean; //★

  constructor(
    private router: Router,
    private authService: AwsConnectService,
    private dbConnect: DbConnectService,
    private userData: UserDataService
  ) { }

  ngOnInit(): void {
    this.setUserName();
    this.setMenu(this.userData.keep.userKbn, this.userData.keep.role);
  }

  btnLogoutClick(): void {
    const prmList = [{
      key: ':pKJN_NO',
      value: this.userData.keep.kjnNo
    }, {
      key: ':pLOG_FLG',
      value: '0'
    }];
    this.dbConnect.CmdSql('common', '3', prmList).then(result => {
      this.authService.Logout().subscribe
        (
          result2 => {
            this.router.navigate(['/login']);
          },
          error => {
            // メッセージを表示
          });
    });
  }

  btnChangePasswordClick(): void {
    this.authService.pwChgFlg = true;
    this.router.navigate(['/changePassword']);
  }

  setUserName(): void {
    this.userName = this.userData.keep.nameKj;
  }

  setMenu(userKbn: string, role: string): void {
    if (userKbn === '00') {
      // 管理者
      this.masterToroku = true;
      this.masterKanri = true;
      this.sinseiToroku = true;
      this.sinseiSyokai = true;
      this.nyutaikanUketuke = true;
      this.roleChange = true; //★
      this.domainKanri = true;  //★
      this.nyukanRireki = true //★
    } else if (userKbn === '10') {
      // 社員
      if (role === '1') {
        // 担当者
        this.masterToroku = false;
        this.masterKanri = false;
        this.sinseiToroku = true;
        this.sinseiSyokai = true;
        this.nyutaikanUketuke = false;
        this.roleChange = false; //★
        this.domainKanri = false; //★
        this.nyukanRireki = false //★
      } else if (role === '2') {
        // DS部部次長
        this.masterToroku = false;
        this.masterKanri = false;
        this.sinseiToroku = true;
        this.sinseiSyokai = true;
        this.nyutaikanUketuke = false;
        this.roleChange = true; //★
        this.domainKanri = true; //★
        this.nyukanRireki = true; //★
      } else if (role === '3') {
        // DS部SOG社員
        this.masterToroku = true;
        this.masterKanri = true;
        this.sinseiToroku = true;
        this.sinseiSyokai = true;
        this.nyutaikanUketuke = false;
        this.roleChange = true; //★
        this.domainKanri = true; //★
        this.nyukanRireki = true; //★
      } else if (role === '4') {
        // DS部FOG社員
        this.masterToroku = false;
        this.masterKanri = false;
        this.sinseiToroku = true;
        this.sinseiSyokai = true;
        this.nyutaikanUketuke = false;
        this.roleChange = false; //★
        this.domainKanri = false; //★
        this.nyukanRireki = false; //★
      } else {
        this.masterToroku = false;
        this.masterKanri = false;
        this.sinseiToroku = false;
        this.sinseiSyokai = false;
        this.nyutaikanUketuke = false;
        this.roleChange = false; //★
        this.domainKanri = false; //★
        this.nyukanRireki = false; //★
      }
    } else if (userKbn === '20' || userKbn === '41' || userKbn === '42' || userKbn === '43' || userKbn === '44') {
      // 顧客・ベンダー
      this.masterToroku = false;
      this.masterKanri = false;
      this.sinseiToroku = true;
      this.sinseiSyokai = true;
      this.nyutaikanUketuke = false;
      this.roleChange = false; //★
      this.domainKanri = false; //★
      this.nyukanRireki = false; //★
    } else if (userKbn === '30') {
      // 警備員
      this.masterToroku = false;
      this.masterKanri = false;
      this.sinseiToroku = false;
      this.sinseiSyokai = true;
      this.nyutaikanUketuke = true;
      this.roleChange = false; //★
      this.domainKanri = false; //★
      this.nyukanRireki = true; //★
    } else {
      this.masterToroku = false;
      this.masterKanri = false;
      this.sinseiToroku = false;
      this.sinseiSyokai = false;
      this.nyutaikanUketuke = false;
      this.roleChange = false; //★
      this.domainKanri = false; //★
      this.nyukanRireki = false; //★
    }
    
    //全ロール共通
    this.masterSyusei = true; //★
  }

}
