<body>
  <p class="h2 mb-5 title">一時退館手続き</p>
  <form class="needs-validation ml-3 mr-3 mb-3" [formGroup]="pageForm">
    <div class="form-row">
      <div class="col-md-12">
        <label class="font-weight-bold">一時退館時確認事項</label>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-2">
        <label class="ml-4">一時退館者</label>
      </div>
      <div class="col-md-10">
          <label>{{nyukansya[0].nameKj}}</label>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-2">
        <label class="ml-4">本人確認</label>
      </div>
      <div class="col-md-10">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" [formControl]="pageForm.controls.kakunin1">
          <label class="form-check-label">確認済</label>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-2">
        <label class="ml-4">金属探知</label>
      </div>
      <div class="col-md-10">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" [formControl]="pageForm.controls.kakunin3">
          <label class="form-check-label">確認済</label>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-2">
        <label class="ml-4">鍵番号</label>
      </div>
      <div class="col-md-10">
        <input type="text" class="form-control form-control-sm mb-2" [formControl]="pageForm.controls.key">
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-2">
        <label class="ml-4">ICカードNo</label>
      </div>
      <div class="col-md-10">
        <input type="text" class="form-control form-control-sm" [formControl]="pageForm.controls.iccard">
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-2 mt-3">
        <label class="font-weight-bold">搬出物確認</label>
      </div>
      <div class="col-md-8 mt-3" [hidden]="!noHansyutu">
        <label class="ml-3">搬出物なし</label>
      </div>
    </div>
    <div class="col-md-12 mb-3">
      <div class="displayFlexWrap" formArrayName="hansyutus">
        <div *ngFor="let hansyutu of hansyutuList; let i = index">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" [formControlName]="i">
            <label class="form-check-label mr-3">{{hansyutu.meisyo}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-2 mt-3">
        <label class="font-weight-bold">持出物確認</label>
      </div>
      <div class="col-md-8 mt-3" [hidden]="!noMotidasi">
        <label class="ml-3">持出物なし</label>
      </div>
    </div>
    <div class="col-md-12 mb-3">
      <div class="displayFlexWrap" formArrayName="motidasis">
        <div *ngFor="let motidasi of motidasiList; let i = index">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" [formControlName]="i">
            <label class="form-check-label mr-3">{{motidasi.meisyo}}</label>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="center">
    <button class="btn btn-primary mr-3" type="submit" name="btnToroku" (click)="btnTorokuClick()">登　録</button>
    <button class="btn btn-secondary ml-3" type="submit" name="btnBack" (click)="btnBackClick()">戻　る</button>
  </div>
</body>
