export class SinseiData {
  nyukanYoteiYmd: string;  // 入館予定日
  nyukanYoteiTime: string; // 入館予定時刻
  taikanYoteiYmd: string; // 退館予定日
  taikanYoteiTime: string; // 退館予定時刻
  group: string; // 所属
  mokuteki: string; // 入館目的
  mokutekiSyosai: string; //入館目的の詳細
  nyukansyas: Nyukansya[]; // 入館者
  hannyus: Hannyu[]; // 搬入物
  hansyutus: Hansyutu[]; // 搬出物
  spAreas: SpArea[]; // 入館場所（SPビル）
  pcAreas: PcArea[]; // 入館場所（プロセシングセンター）
  dcAreas: DcArea[]; // 入館場所（GCC-DC）
  //sdAreas: SdArea[]; // 入館場所（第二データセンター）
  pcs: Pc[]; // 持込作業PC
  tools: Tool[]; // 持込工具類
  camera: boolean; // 持込カメラ
  phone: boolean; // 持込携帯電話
  others: Other[]; // その他持込物
  mainGroup: string; // 所属（主担当者）
  mainNameKn: string; // 氏名カナ（主担当者）
  mainNameKj: string; // 氏名漢字（主担当者）
  mainTelNo: string; // 内線（主担当者）
  mainEmail: string; // メールアドレス（主担当者）
  subGroup: string; // 所属（副担当者）
  subNameKn: string; // 氏名カナ（副担当者）
  subNameKj: string; // 氏名漢字（副担当者）
  subTelNo: string;  // 内線（副担当者）
  subEmail: string;  // メールアドレス（副担当者）

  constructor(
    nyukanYoteiYmd: string,
    nyukanYoteiTime: string,
    taikanYoteiYmd: string,
    taikanYoteiTime: string,
    group: string,
    mokuteki: string,
    mokutekiSyosai: string,
    nyukansyas: Nyukansya[],
    hannyus: Hannyu[],
    hansyutus: Hansyutu[],
    spAreas: SpArea[],
    pcAreas: PcArea[],
    dcAreas: DcArea[],
    //sdAreas: SdArea[],
    pcs: Pc[],
    tools: Tool[],
    camera: boolean,
    phone: boolean,
    others: Other[],
    mainGroup: string,
    mainNameKn: string,
    mainNameKj: string,
    mainTelNo: string,
    mainEmail: string,
    subGroup: string,
    subNameKn: string,
    subNameKj: string,
    subTelNo: string,
    subEmail: string
  ) {
    this.nyukanYoteiYmd = nyukanYoteiYmd;
    this.nyukanYoteiTime = nyukanYoteiTime;
    this.taikanYoteiYmd = taikanYoteiYmd;
    this.taikanYoteiTime = taikanYoteiTime;
    this.group = group;
    this.mokuteki = mokuteki;
    this.mokutekiSyosai = mokutekiSyosai;
    this.nyukansyas = nyukansyas;
    this.hannyus = hannyus;
    this.hansyutus = hansyutus;
    this.spAreas = spAreas;
    this.pcAreas = pcAreas;
    this.dcAreas = dcAreas;
    //this.sdAreas = sdAreas;
    this.pcs = pcs;
    this.tools = tools;
    this.camera = camera;
    this.phone = phone;
    this.others = others;
    this.mainGroup = mainGroup;
    this.mainNameKn = mainNameKn;
    this.mainNameKj = mainNameKj;
    this.mainTelNo = mainTelNo;
    this.mainEmail = mainEmail;
    this.subGroup = subGroup;
    this.subNameKn = subNameKn;
    this.subNameKj = subNameKj;
    this.subTelNo = subTelNo;
    this.subEmail = subEmail;
  }
}

// 入館者
export class Nyukansya {
  seqNo: string;
  nameKn: string;
  nameKj: string;

  constructor(
    seqNo: string,
    nameKn: string,
    nameKj: string
  ) {
    this.seqNo = seqNo;
    this.nameKn = nameKn;
    this.nameKj = nameKj;
  }
}

// 搬入物
export class Hannyu {
  seqNo: string;
  meisyo: string;

  constructor(
    seqNo: string,
    meisyo: string
  ) {
    this.seqNo = seqNo;
    this.meisyo = meisyo;
  }
}

// 搬出物
export class Hansyutu {
  seqNo: string;
  meisyo: string;

  constructor(
    seqNo: string,
    meisyo: string
  ) {
    this.seqNo = seqNo;
    this.meisyo = meisyo;
  }
}

// 入館場所（SPビル）
export class SpArea {
  no: string;
  meisyo: string;
  nyukanFlg: boolean;

  constructor(
    no: string,
    meisyo: string,
    nyukanFlg: boolean
  ) {
    this.no = no;
    this.meisyo = meisyo;
    this.nyukanFlg = nyukanFlg;
  }
}

// 入館場所（プロセシングセンター）
export class PcArea {
  no: string;
  meisyo: string;
  nyukanFlg: boolean;

  constructor(
    no: string,
    meisyo: string,
    nyukanFlg: boolean
  ) {
    this.no = no;
    this.meisyo = meisyo;
    this.nyukanFlg = nyukanFlg;
  }
}

// 入館場所（GCC-DC）
export class DcArea {
  no: string;
  meisyo: string;
  nyukanFlg: boolean;

  constructor(
    no: string,
    meisyo: string,
    nyukanFlg: boolean
  ) {
    this.no = no;
    this.meisyo = meisyo;
    this.nyukanFlg = nyukanFlg;
  }
}

// 入館場所（第二データセンター）
export class SdArea {
  no: string;
  meisyo: string;
  nyukanFlg: boolean;

  constructor(
    no: string,
    meisyo: string,
    nyukanFlg: boolean
  ) {
    this.no = no;
    this.meisyo = meisyo;
    this.nyukanFlg = nyukanFlg;
  }
}

// 持込作業PC
export class Pc {
  seqNo: string;
  pcName: string;
  setuzokusaki: string;
  macaddress: string;

  constructor(
    seqNo: string,
    pcName: string,
    setuzokusaki: string,
    macaddress: string
  ) {
    this.seqNo = seqNo;
    this.pcName = pcName;
    this.setuzokusaki = setuzokusaki;
    this.macaddress = macaddress;
  }
}

// 持込工具類
export class Tool {
  seqNo: string;
  meisyo: string;

  constructor(
    seqNo: string,
    meisyo: string
  ) {
    this.seqNo = seqNo;
    this.meisyo = meisyo;
  }
}

// その他持込物
export class Other {
  seqNo: string;
  meisyo: string;

  constructor(
    seqNo: string,
    meisyo: string
  ) {
    this.seqNo = seqNo;
    this.meisyo = meisyo;
  }
}
