import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { MasterData } from '@class/master-data';
import { DbConnectService } from '@service/db-connect.service';
import { UserDataService } from '@service/user-data.service';
import { AwsConnectService } from '@service/aws-connect.service';
import { ModalService } from '@service/modal.service'
import { SpinnerModalComponent } from '@spinner/spinner-modal.component';
import { NewsService, newsCategory } from '@service/news.service';

@Component({
  selector: 'app-master-syusei-kakunin',
  templateUrl: './master-syusei-kakunin.component.html',
  styleUrls: ['../master-syusei.component.css']
})
export class MasterSyuseiKakuninComponent implements OnInit {
  @Input() masterData: MasterData;
  @Output() changeFlg = new EventEmitter();
  syain: boolean;

  constructor(
    private dbConnect: DbConnectService,
    private userData: UserDataService,
    private cognitoConnect: AwsConnectService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
    private newsService: NewsService
  ) { }

  ngOnInit(): void {
    this.syain = false;
  }

  async btnTorokuClick(): Promise<any> {
    if (!confirm('修正します。よろしいですか？')) {
      return;
    }

    this.modalService.openModal(SpinnerModalComponent, this.viewContainerRef, []);

    const syubetu = this.masterData.syubetu;
    let sqlId = '1';

    const prmList = [{
      key: ':pKJN_NO',
      value: this.userData.keep.kjnNo
    },
    {
      key: ':pGROUP',
      value: this.masterData.group
    },
    {
      key: ':pNAME_KN',
      value: this.masterData.nameKn
    },
    {
      key: ':pNAME_KJ',
      value: this.masterData.nameKj
    },
    {
      key: ':pTEL_NO',
      value: this.masterData.telNo
    },
    {
      key: ':pEMAIL',
      value: this.masterData.email
    },
    {
      key: ':pUPD_KJN_NO',
      value: this.userData.keep.kjnNo
    }];

    await this.dbConnect.CmdStored('masterSyusei', sqlId, prmList);

    // nst030_newsの更新
    await this.newsService.registerNews(this.userData.keep.kjnNo, newsCategory.masterSyusei, this.masterData.group + '　' + this.masterData.nameKj);

    // Cognitoの更新
    const syozokuName = this.masterData.group + '　' + this.masterData.nameKj;

    let telNo = '';
    if (syubetu !== '社員') {
      // 電話番号の先頭に国番号（+81）をつける
      telNo = '+81' + this.masterData.telNo;
    }

    const cogPrmList = {
      username: this.userData.keep.userId,
      email: this.masterData.email,
      phoneNumber: this.masterData.telNo,
      nameKj: syozokuName
    };
    await this.cognitoConnect.editUser(cogPrmList);

    // 修正されたことの通知をSESで行う
    //if (syubetu !== '社員'){
      /** マネージャー宛のメールなので一旦コメントアウト ★
      const prmDSManager = [{
        key: ':pROLE',
        value: '2'
      }];
      const DSManagers = await this.dbConnect.CmdSql('common', '4', prmDSManager);
      DSManagers.forEach(async manager => {
        let prmDS = {
          // "tantouAddr": manager.nsm001_email,
          "tantouAddr": 'takahashi.ya@sol.gcc.co.jp',  // 一時修正
          "tantouNm": manager.nsm001_group + '　' + manager.nsm001_name_kj  ,
          "riyouShaNm": syozokuName
        };
        await this.mailService.sendAccEditTuti(prmDS);
      });
      **/
    //}

    this.modalService.closeModal();
    this.changeFlg.emit(true);
  }

  btnBackClick(): void {
    this.changeFlg.emit(false);
  }

  isSyain(): boolean {
    return this.masterData.syubetu === '社員';
  }
}
