<app-menu>
    <div class="container">
        <p class="h2 mb-5 title">入館履歴一覧</p>

        <!-- 絞り込み機能 -->
        <form class="ml-3 mr-3 mb-3" [formGroup]="pageForm">
            <div class="form-row">
            <div class="col-md-2 mb-3">
                <label class="font-weight-bold">申請番号</label>
            </div>
            <div class="col-md-4 mb-3">
                <input type="text" class="form-control form-control-sm" [formControl]="pageForm.controls.sinseiNo">
            </div>
            <div class="col-md-2 mb-3 center">
                <label class="font-weight-bold">状態</label>
            </div>
            <div class="col-md-4 mb-3">
                <select class="form-control form-control-sm" size="1" [formControl]="pageForm.controls.status">
                <option value="">選択してください</option>
                <option value="05">未完了</option>
                <option value="06">完了</option>
                </select>
            </div>
            </div>
            <div class="form-row">
            <div class="col-md-2 mb-3">
                <label class="font-weight-bold">申請日</label>
            </div>
            <div class="col-md-4 mb-3">
                <input type="date" class="form-control form-control-sm"
                [formControl]="pageForm.controls.sinseiDateFrom">
            </div>
            <div class="col-md-2 mb-3 center">
                <label class="font-weight-bold">〜</label>
            </div>
            <div class="col-md-4 mb-3">
                <input type="date" class="form-control form-control-sm"
                [formControl]="pageForm.controls.sinseiDateTo">
            </div>
            </div>
            <div class="form-row">
            <div class="col-md-3 mb-3">
                <label class="font-weight-bold">申請者氏名（カナ）</label>
            </div>
            <div class="col-md-9 mb-3">
                <input type="text" class="form-control form-control-sm" [formControl]="pageForm.controls.sinseiNameKn">
            </div>
            </div>
            <div class="col-md-12 mt-3 mb-5 center">
            <button type="button" class="btn btn-info" (click)="btnExtractClick()">抽　出</button>
            <button type="button" class="btn btn-secondary ml-3" (click)="btnClearClick()">クリア</button>
            </div>
        </form>

        <div>
            <table class="table table-hover">
                <thead class="thead-dark">
                    <tr>
                        <th>No</th>
                        <th>入館予定日</th>
                        <th>退館予定日</th>
                        <th>状態</th>
                        <th>申請日</th>
                        <th>申請者</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of rirekiList" [routerLink]="['/nyukanRireki', item.id]">
                        <td>{{item.id}}</td>
                        <td>{{item.nyukanYoteiDate | date:'yyyy年MM月dd日'}}</td>
                        <td>{{item.taikanYoteiDate | date:'yyyy年MM月dd日'}}</td>
                        <td *ngIf="item.status" class="text-success">完了</td>
                        <td *ngIf="!item.status" class="text-danger">未完了</td>
                        <td>{{item.sinseiDate | date:'yyyy年MM月dd日'}}</td>
                        <td>{{item.sinseisyaName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</app-menu>