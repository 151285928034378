import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AwsMailService } from '@service/aws-mail.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-karitoroku',
  templateUrl: './karitoroku.component.html',
  styleUrls: ['./karitoroku.component.css']
})
export class KaritorokuComponent implements OnInit {
  form: FormGroup = this.fb.group({
    mailAddress: ['', Validators.required]
  })

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private awsApi: AwsMailService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void{
    if(this.form.invalid){
      alert('入力内容が不正です。');
      return
    }

    const mailAddress = this.form.value.mailAddress;
    this.awsApi.sendKaritorokuMail(mailAddress)
      .then(success => {
        if(success){
          alert('仮登録が完了しました。登録頂いたメールアドレスにメールが送信されますので、案内に従って本登録を行ってください。');
        }else{
          alert('仮登録が完了しました');
        }
      })
      .catch(err => alert('登録に失敗しました。入力内容にお間違いがないかお確かめください。'));
  }

  onBack(): void{
    this.router.navigate(['/login']);
  }
}
