<body>
  <div class="container">
    <div class="center">
      <p class="h3 font-weight-normal mb-5">株式会社GCC 入館申請システム</p>
      <p class="h4 font-weight-normal mb-4">SMS認証</p>
    </div>
    <form class="form-inline justify-content-center" [formGroup]="pageForm">
      <div class="form-label-group mx-sm-3 mb-4">
        <label class="sr-only">認証コード</label>
        <input type="number" class="form-control formWidth" name="edtPinCode" [formControl]="pageForm.controls.pinCode" placeholder="認証コード">
        <small class="form-text text-muted">携帯電話に届いた認証コードを入力して下さい。</small>
      </div>
    </form>
    <div class="center">
      <button type="submit" class="btn btn-primary mb-5" name="btnNinsyo" (click)="btnNinsyoClick()">認　証</button>
      <button class="btn btn-secondary mb-5 offset-md-1" name="btnResend" (click)="btnResendClick()">再発行</button>
    </div>
  </div>
</body>
