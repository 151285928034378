import { Component, OnInit } from '@angular/core';
import { NewsService } from '@service/news.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  news: string[];
  sinsei: string[];

  constructor(
    private newsService: NewsService
  ) { }

  ngOnInit(): void {
    this.newsService.fetchNewsList()
      .then(newsList => this.news = newsList)
      .catch(err => this.news = []);

    this.newsService.fetchShinseiNewsList()
      .then(shinseiList => this.sinsei = shinseiList)
      .catch(err => this.sinsei = []);
  }
}
