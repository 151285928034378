<body>
    <app-menu>
        <div class="container g-0">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a href="#expiredDelete" class="nav-link" data-toggle="tab">期限切れユーザ削除</a>
                </li>
            </ul>
            <div class="tab-content">
                <div id="expiredDelete" class="tab-pane active">
                    <app-expired-delete></app-expired-delete>
                </div>
            </div>
        </div>
    </app-menu>
</body>
