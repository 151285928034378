import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserDataService } from '@service/user-data.service';
import { RirekiDetail } from '@class/rireki-detail';
import { RirekiSummary } from '@class/rireki-summary';

@Injectable({
  providedIn: 'root'
})
export class DbConnectService {

  constructor(
    private http: HttpClient,
    private userData: UserDataService
  ) { }

  // SQL実行
  CmdSql(name: string, id: string, prmList: any): Promise<any> {
    const url = 'https://6vvhmak3df.execute-api.ap-northeast-1.amazonaws.com/LambdaStage_Test/getdata';
    const params = {
      pageName: name,
      sqlId: id,
      params: encodeURI(JSON.stringify(prmList))
    };
    return this.http.get(url, {params}).toPromise().then(result => {
      if (result === null) {
        return null;
      }
      const res: any = result;
      return res.body;
    });
  }

  // ストアド実行
  CmdStored(name: string, id: string, prmList: any): Promise<any> {
    const url = 'https://6vvhmak3df.execute-api.ap-northeast-1.amazonaws.com/LambdaStage_Test/postdata';
    const params = {
      pageName: name,
      sqlId: id,
      params: encodeURI(JSON.stringify(prmList))
    };
    return this.http.post<any>(url, params).toPromise().then(result => {
      if (result === null) {
        return null;
      }
      const res: any = result;
      return res.body;
    });
  }

  // QRコード生成
  GenerateQrcode(str: string, flg: boolean): any {
    const url = 'https://wwkezc5o7b.execute-api.ap-northeast-1.amazonaws.com/SaveAndGetQRCode';
    const params = {
      dataString: str,
      updFlg: flg
    };
    return this.http.post<any>(url, params).toPromise().then(result => {
      if (result === null) {
        return null;
      }
      const res: any = result;
      return res.body;
    });
  }

  checkData(prmList: any){
    const url = 'https://6vvhmak3df.execute-api.ap-northeast-1.amazonaws.com/LambdaStage_Test/checkdata';
    const params = {
      params: encodeURI(JSON.stringify(prmList))
    };
    return this.http.get(url, {params}).toPromise().then(result => {
      if (result === null) {
        return null;
      }
      const res: any = result;
      return res.body;
    });

  }

  getNyukanHistory(sinseiNo: number): Promise<RirekiDetail>{
    const url = 'https://6vvhmak3df.execute-api.ap-northeast-1.amazonaws.com/LambdaStage_Test/gethistory';
    const params = {
      sinseiNo: sinseiNo.toString()
    };

    return this.http.get(url, {params}).toPromise().then(res => {
      if(res['statusCode'] == 400){
        Promise.reject();
      }

      return JSON.parse(res['body']);
    })
  }

  getNyukanSummaryList(id?: number, nameKn?: string, startDate?: Date, endDate?: Date, status?: string): Promise<RirekiSummary[]>{
    const formatDate = (date: Date) => {
      return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
    }
    const isEmptyOrNull = (str:string) => {
      return !str || str.replace(/ /g, '').replace(/　/g, '') === '';
    }
    const isInvalidDate = (date: Date) => {
      return !date || Number.isNaN(date.getTime());
  }
  
    const sinseiNo = id ? id.toString() : '';
    nameKn = isEmptyOrNull(nameKn) ? '' : nameKn;
    startDate = isInvalidDate(startDate) ? new Date(0) : startDate;
    endDate = isInvalidDate(endDate) ? new Date(864000000000000) : endDate;
    status = isEmptyOrNull(status) ? '(05|06)' : status;
  
    const paramList = [
      {
        key:':pSINSEI_NO',
        value:sinseiNo
      },
      {
        key:':pNAME_KN',
        value:nameKn
      },
      {
        key:':pSTART_DATE',
        value:formatDate(startDate)
      },
      {
        key:':pEND_DATE',
        value:formatDate(endDate)
      },
      {
        key:':pSTATUS',
        value:status
      }
    ];

    return this.CmdSql('nyukanRireki', '5', paramList).then(res => {
      const list = [];
        for(let data of res){
          list.push(new RirekiSummary(
            data.nst001_sinsei_no,
            data.nst001_ins_dat,
            data.nyukanYotei,
            data.taikanYotei,
            data.nsm001_name_kj,
            data.status
          ));
        }
        
        return list.sort((a, b) => b.id - a.id);
    })
  }

  isExistUser(mailAddress: string): Promise<boolean>{
    const url = 'https://6vvhmak3df.execute-api.ap-northeast-1.amazonaws.com/LambdaStage_Test/emailexists';

    const params = {
      mailAddress: mailAddress
    };

    return this.http.get(url, {params:params}).toPromise()
      .then(res => {
        return res['body'] ? true : false;
      })
      .catch(err => false);
  }

  getShinseiFromCode(code: string): Promise<any>{
    const url = 'https://6vvhmak3df.execute-api.ap-northeast-1.amazonaws.com/LambdaStage_Test/getshinsei';
    const params = {
      sinseiCode: code
    };

    return this.http.get(url, {params: params}).toPromise()
      .then((res) => res['body']);
  }
}
