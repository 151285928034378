import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AwsConnectService } from '@service/aws-connect.service';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-login-top',
  templateUrl: './login-top.component.html',
  styleUrls: ['../login.component.css']
})
export class LoginTopComponent implements OnInit {
  @Output() session = new EventEmitter();
  pageForm: FormGroup;

  constructor(
    private service: AwsConnectService,
    private fb: FormBuilder,
  ) {
    this.pageForm = this.fb.group({
      id: '',
      pass: ''
    });
  }

  ngOnInit(): void { }

  btnLoginClick(): void {
    this.service.UserCheck(this.pageForm.controls.id.value, this.pageForm.controls.pass.value)
      .subscribe(
        result => {
          // 成功
          this.session.emit({changeFlg: true, userId: this.pageForm.controls.id.value});
        },
        error => {
          // 失敗
          this.session.emit({changeFlg: false});
        });
  }

}
