import { Component, OnInit, ViewChild } from '@angular/core';
import { PageTransitionCheckService } from '@service/page-transition-check.service';
import { Router } from '@angular/router';
import { DbConnectService } from '@service/db-connect.service';
import { UserData } from '@class/user-data';
import { RoleChangeKensakuComponent } from './role-change-kensaku/role-change-kensaku.component';
import { RoleChangeNyuryokuComponent } from './role-change-nyuryoku/role-change-nyuryoku.component';
import { RoleChangeKakuninComponent } from './role-change-kakunin/role-change-kakunin.component';

@Component({
  selector: 'app-role-change',
  templateUrl: './role-change.component.html',
  styleUrls: ['./role-change.component.css']
})
export class RoleChangeComponent implements OnInit {
  @ViewChild(RoleChangeKensakuComponent) roleChangeKensaku: RoleChangeKensakuComponent;
  @ViewChild(RoleChangeNyuryokuComponent) roleChangeNyuryoku: RoleChangeNyuryokuComponent;
  @ViewChild(RoleChangeKakuninComponent) roleChangeKakunin: RoleChangeKakuninComponent;
  kensaku: boolean;
  nyuryoku: boolean;
  kakunin: boolean;
  gridData: UserData[];
  masterData: UserData[];
  syokaiData: UserData;
  kakuninData: UserData;

  constructor(
    private router: Router,
    private pageAuth : PageTransitionCheckService,
    private dbConnect: DbConnectService
  ) {
    this.gridData = [{
      kjnNo: '',
      userId: '',
      syainNo: '',
      role: '',
      group: '',
      nameKn: '',
      nameKj: '',
      telNo: '',
      email: '',
      //以下未使用
      mainKjnNo: '',
      subKjnNo: '',
      userKbn: ''
    }];

    this.syokaiData = {
      kjnNo: '',
      userId: '',
      syainNo: '',
      role: '',
      group: '',
      nameKn: '',
      nameKj: '',
      telNo: '',
      email: '',
      //以下未使用
      mainKjnNo: '',
      subKjnNo: '',
      userKbn: ''
    };

    this.kakuninData = {
      kjnNo: '',
      userId: '',
      syainNo: '',
      role: '',
      group: '',
      nameKn: '',
      nameKj: '',
      telNo: '',
      email: '',
      //以下未使用
      mainKjnNo: '',
      subKjnNo: '',
      userKbn: '',
    };
}

  ngOnInit(): void {
    if(!this.pageAuth.authCheck(this)){
      this.router.navigateByUrl('/home')
      return
    }

    this.getGridData();
  }

  getGridData(){
    this.gridData.length = 0;
    let sqlId = '1';
    const prmList = [];

    this.dbConnect.CmdSql('roleChange', sqlId, prmList).then(result => {
      if (result === null) {
        // 検索へ
        this.kensaku = true;
        this.kakunin = false;
        return;
      }

      for (const obj of result) {
        const data = new UserData(
          obj.nsm001_kjn_no,
          obj.nsm001_user_id,
          obj.nsm001_syain_no,
          obj.nsm001_role,
          obj.nsm001_group,
          obj.nsm001_name_kn,
          obj.nsm001_name_kj,
          obj.nsm001_tel_no,
          obj.nsm001_email,
          obj.nsm001_main_kjn_no,
          obj.nsm001_sub_kjn_no,
          this.SetUserKbn(obj.nsm001_user_id, obj.nsm001_role)
        );
        this.gridData.push(data);
      }

      // 検索へ
      this.roleChangeKensaku.setData();
      this.kensaku = true;
      this.nyuryoku = false;
      this.kakunin = false;
      window.scrollTo(0, 0);
    });
  }

  changeKensaku(kjnNo: string): void {
    this.masterData = this.gridData.filter(obj => obj.kjnNo === kjnNo);
    this.syokaiData = this.masterData[0];

    // 入力へ
    this.roleChangeNyuryoku.setData(this.syokaiData);
    this.kensaku = false;
    this.nyuryoku = true;
    this.kakunin = false;
    window.scrollTo(0, 0);
  }

  changeNyuryoku(changeFlg: boolean): void {
    if (changeFlg) {
      // 次へ
      this.roleChangeKakunin.setData(this.kakuninData);
      this.kensaku = false;
      this.nyuryoku = false;
      this.kakunin = true;
      window.scrollTo(0, 0);
    }else{
      //戻る
      this.kensaku = true;
      this.nyuryoku = false;
      this.kakunin = false;
      window.scrollTo(0, 0);
    }
  }

  changeKakunin(changeFlg: boolean): void {
    if (changeFlg) {
      // 登録する
      alert('登録が完了しました。');
      this.router.navigate(['/home']);
      window.scrollTo(0, 0);
    } else {
      // 戻る
      this.kensaku = false;
      this.nyuryoku = true;
      this.kakunin = false;
      window.scrollTo(0, 0);
    }
  }

  getKakuninData(data: UserData){
    this.kakuninData = data;
  }

  // ユーザ区分の設定
  SetUserKbn(userId: string, role: string){
    //区分
    let syubetu = userId.substring(0, 2);
    if(role === '5'){
      //ロール「5」は警備員固定
      return '30';
    }else if(syubetu == '10' || syubetu == '20'){
      //種別「10」「20」はそのまま
      return userId.substring(0, 2);
    }else{
      // 旧41～43は20として返却
      return '20';
    }
  }

}
