<body>
    <p class="h2 mb-5 title">入館者氏名変更手続き</p>
    <form class="needs-validation ml-3 mr-3 mb-3" [formGroup]="pageForm">
      <div class="form-row">
        <div class="col-md-12">
          <label class="font-weight-bold">入館者修正</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <label class="ml-4">変更前</label>
        </div>
        <div class="col-md-10">
            <div class="col-md-8 mb-3">
                <label>{{nyukansya[0].nameKj.replace("予定：", "").split('　入館：')[0]}}</label>
            </div>
            <div class="col-md-8 mb-3">
                <label>{{nyukansya[0].nameKn.replace("予定：", "").split('　入館：')[0]}}</label>
            </div>
        </div>
        <div class="col-md-12 textRed">
            <div class="col-md-12 mb-3">&nbsp;</div>
        </div>
        <div class="col-md-2">
            <label class="ml-4">変更後</label>
        </div>
        <div class="col-md-1">
            <label>氏名</label>
        </div>
        <div class="col-md-9">
            <ng-container
            *ngIf="(pageForm.controls.nameKj.hasError('required') || pageForm.controls.nameKj.hasError('whitespace')) &&
             (pageForm.controls.nameKj.touched || pageForm.controls.nameKj.dirty)">
            <div class="col-md-12">
              <span class="textRed">※氏名を入力してください。</span>
            </div>
            </ng-container>
            <div class="col-md-8 mb-3">
                <input type="text" class="form-control mb-3" placeholder="氏名" [formControl]="pageForm.controls.nameKj">
            </div>
        </div>
        <div class="col-md-2">
        </div>
        <div class="col-md-1">
            <label>カナ</label>
        </div>
        <div class="col-md-9">
            <ng-container
            *ngIf="(pageForm.controls.nameKn.hasError('required') || pageForm.controls.nameKn.hasError('whitespace')) &&
             (pageForm.controls.nameKn.touched || pageForm.controls.nameKn.dirty)">
            <div class="col-md-12">
              <span class="textRed">※カナを入力してください。</span>
            </div>
            </ng-container>
            <ng-container
            *ngIf="pageForm.controls.nameKn.hasError('katakana') && (pageForm.controls.nameKn.touched || pageForm.controls.nameKn.dirty)">
            <div class="col-md-12">
              <span class="textRed">※全角カナで入力してください。</span>
            </div>
            </ng-container>
            <div class="col-md-8 mb-3">
                <input type="text" class="form-control mb-3" placeholder="フリガナ" [formControl]="pageForm.controls.nameKn">
            </div>
        </div>
      </div>
    </form>
    <div class="form-row">
      <ng-container *ngIf="pageForm.invalid && touchFlg">
        <div class="col-md-3"></div>
        <div class="col-md-9 mb-3">
          <span class="textRed">※入力内容が正しくありません。入力内容をご確認ください。</span>
        </div>
      </ng-container>
    </div>
    <div class="center">
      <button class="btn btn-primary mr-3" type="submit" name="btnToroku" (click)="btnTorokuClick()">登　録</button>
      <button class="btn btn-secondary ml-3" type="submit" name="btnBack" (click)="btnBackClick()">戻　る</button>
    </div>
  </body>
  