import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from '@utility/custom-validator';
import { UserData } from '@class/user-data';
import { DbConnectService } from '@service/db-connect.service';

@Component({
  selector: 'app-role-change-nyuryoku',
  templateUrl: './role-change-nyuryoku.component.html',
  styleUrls: ['../role-change.component.css']
})
export class RoleChangeNyuryokuComponent implements OnInit {
  @Input() syokaiData: UserData;
  @Output() kakuninData = new EventEmitter();
  @Output() changeFlg = new EventEmitter();
  pageForm: FormGroup;
  item: UserData;

  selected_syubetu = '';
  selected_role = '';
  syain: boolean;
  touchFlg: boolean;

  constructor(
    private fb: FormBuilder,
    private dbConnect: DbConnectService,
  ) {
    this.pageForm = fb.group({
      role: ['']
    });

    this.item = {
      kjnNo: '',
      userId: '',
      syainNo: '',
      role: '',
      group: '',
      nameKn: '',
      nameKj: '',
      telNo: '',
      email: '',
      //以下未使用
      mainKjnNo: '',
      subKjnNo: '',
      userKbn: ''
    };
  }

  ngOnInit(): void {
    this.syain = false;
    this.touchFlg = false;
  }

  setData(data: UserData): void{
    let syubetu = data.userKbn;

    if(syubetu == '10'){
      //社員の場合
      this.syain = true;
    }else{
      //社外の場合
      this.syain = false;
    }
    if(data.role === null){
      data.role = '';
    }
    this.selected_role = data.role;
    this.pageForm.controls.role.setValue(data.role);
  }

  btnNextClick(): void{
    this.setTouched();
    if (this.pageForm.invalid) {
      return;
    }
    this.setKakuninData()
    this.kakuninData.emit(this.item);
    this.changeFlg.emit(true);
  }

  setTouched(): void {
    this.touchFlg = true;
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

  btnBackClick(): void {
    this.changeFlg.emit(false);
  }

  getUserKbn(userKbn: string): string {
    let result = '';
    if (userKbn === '10') {
      result = '社員';
    } else {
      result = '社外';
    }
    return result;
  }

  setKakuninData(){
    this.item.kjnNo = this.syokaiData.kjnNo;
    this.item.userId = this.syokaiData.userId;
    this.item.syainNo = this.syokaiData.syainNo;
    this.item.role = this.pageForm.controls.role.value;
    this.item.group = this.syokaiData.group;
    this.item.nameKn = this.syokaiData.nameKn;
    this.item.nameKj = this.syokaiData.nameKj;
    this.item.telNo = this.syokaiData.telNo;
    this.item.email = this.syokaiData.email;
    this.item.mainKjnNo = this.syokaiData.mainKjnNo;
    this.item.subKjnNo =this.syokaiData.subKjnNo;
    this.item.userKbn = this.syokaiData.userKbn;
  }
}
