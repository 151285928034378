import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MasterTorokuKakuninComponent } from './master-toroku-kakunin/master-toroku-kakunin.component';
import { MasterData } from '@class/master-data';
import { PageTransitionCheckService } from '@service/page-transition-check.service';

@Component({
  selector: 'app-master-toroku',
  templateUrl: './master-toroku.component.html',
  styleUrls: ['./master-toroku.component.css']
})
export class MasterTorokuComponent implements OnInit {
  @ViewChild(MasterTorokuKakuninComponent) masterTorokuKakunin: MasterTorokuKakuninComponent;
  masterData: MasterData;
  nyuryoku: boolean;
  kakunin: boolean;

  constructor(
    private router: Router,
    private pageAuth : PageTransitionCheckService
  ) {
    this.masterData = {
      syubetu: '',
      syainNo: '',
      role: '',
      roleDisp: '',
      group: '',
      nameKn: '',
      nameKj: '',
      telNo: '',
      email: '',
      kjnNoMain: '',
      syainNoMain: '',
      groupMain: '',
      nameKnMain: '',
      nameKjMain: '',
      telNoMain: '',
      emailMain: '',
      kjnNoSub: '',
      syainNoSub: '',
      groupSub: '',
      nameKnSub: '',
      nameKjSub: '',
      telNoSub: '',
      emailSub: ''
    };
  }

  ngOnInit(): void {
    if(!this.pageAuth.authCheck(this)){
      this.router.navigateByUrl('/home')
      return
    }

    this.nyuryoku = true;
    this.kakunin = false;
  }

  getMasterData(data: MasterData): void {
    this.masterData = data;
  }

  changeNyuryoku(changeFlg: boolean): void {
    if (changeFlg) {
      // 次へ
      this.nyuryoku = false;
      this.kakunin = true;
      window.scrollTo(0, 0);
    }
  }

  changeKakunin(changeFlg: boolean): void {
    if (changeFlg) {
      // 登録する
      alert('登録が完了しました。');
      this.router.navigate(['/home']);
      window.scrollTo(0, 0);
    } else {
      // 戻る
      this.nyuryoku = true;
      this.kakunin = false;
      window.scrollTo(0, 0);
    }
  }

}
