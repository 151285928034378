import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { MasterData } from '@class/master-data';
import { DbConnectService } from '@service/db-connect.service';
import { UserDataService } from '@service/user-data.service';
import { AwsConnectService } from '@service/aws-connect.service';
import { AwsMailService } from '@service/aws-mail.service';
import { ModalService } from '@service/modal.service'
import { SpinnerModalComponent } from '@spinner/spinner-modal.component';
import { MasterDataService } from '@service/master-data.service';

@Component({
  selector: 'app-master-toroku-kakunin',
  templateUrl: './master-toroku-kakunin.component.html',
  styleUrls: ['../master-toroku.component.css']
})
export class MasterTorokuKakuninComponent implements OnInit {
  @Input() masterData: MasterData;
  @Output() changeFlg = new EventEmitter();
  syain: boolean;

  constructor(
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
    private masterService: MasterDataService
  ) { }

  ngOnInit(): void {
    this.syain = false;
  }

  async btnTorokuClick(): Promise<any> {
    if (!confirm('登録します。よろしいですか？')) {
      return;
    }

    this.modalService.openModal(SpinnerModalComponent, this.viewContainerRef, []);
    await this.masterService.registerMasterData(this.masterData);
    this.modalService.closeModal();

    this.changeFlg.emit(true);
  }

  btnBackClick(): void {
    this.changeFlg.emit(false);
  }

  isSyain(): boolean {
    return this.masterData.syubetu === '社員';
  }
}
