<body>
  <div class="container">
    <div class="center">
      <p class="h3 font-weight-normal mb-5">株式会社GCC 入館申請システム</p>
    </div>
    <form class="form-inline justify-content-center" [formGroup]="pageForm">
      <div class="form-label-group mx-sm-3 mb-4">
        <label class="sr-only">ログインID</label>
        <input type="text" class="form-control formWidth" [formControl]="pageForm.controls.id" name="userid" placeholder="ログインID">
      </div>
      <div class="form-label-group mx-sm-3 mb-4">
        <label class="sr-only">パスワード</label>
        <input type="password" class="form-control formWidth" [formControl]="pageForm.controls.pass" name="userpass" placeholder="パスワード">
      </div>
    </form>
    <div class="center">
      <button class="btn btn-primary mb-4" name="btnLogin" (click)="btnLoginClick()">ログイン</button>
      <a class="nav-link" routerLink="/karitoroku">ユーザ登録をされていない場合</a>
      <a class="nav-link" routerLink="/forgetPassword">パスワードを忘れた場合</a>
    </div>
  </div>
</body>
