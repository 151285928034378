/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_16sphztP0",
    "aws_user_pools_web_client_id": "7o7sagfh34e6t7omhtt5ebnofb",
    "oauth": {},
    "aws_content_delivery_bucket": "yukaninseipp-20210119154302-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d99a00t3ywws6.cloudfront.net"
};


export default awsmobile;
