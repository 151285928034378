<div class="container">
    <p class="h2 mb-5 title">ユーザー検索</p>
    <form class="ml-3 mr-3 mb-3" [formGroup]="pageForm">

      <div class="form-row">
          <div class="col-md-2 mb-3 center">
            <label class="font-weight-bold">所属</label>
          </div>
          <div class="col-md-4 mb-3">
            <input type="text" class="form-control form-control-sm" [formControl]="pageForm.controls.group" >
          </div>

          <div class="col-md-2 mb-3 center">
            <label class="font-weight-bold">氏名（カナ）</label>
          </div>
          <div class="col-md-4 mb-3">
            <input type="text" class="form-control form-control-sm" [formControl]="pageForm.controls.nameKn">
          </div>
      </div>

      <div class="form-row">
        <div class="col-md-2 mb-3 center">
          <label class="font-weight-bold">電話番号</label>
        </div>
        <div class="col-md-4 mb-3">
          <input type="text" class="form-control form-control-sm" [formControl]="pageForm.controls.telNo">
        </div>

        <div class="col-md-2 mb-3 center">
          <label class="font-weight-bold">メールアドレス</label>
        </div>
        <div class="col-md-4 mb-3">
          <input type="text" class="form-control form-control-sm" [formControl]="pageForm.controls.email">
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-2 mb-3 center">
          <label class="font-weight-bold">区分</label>
        </div>
        <div class="col-md-4 mb-3">
          <select class="form-control" [formControl]="pageForm.controls.userKbn" (change)="userKbnChange()">
            <option value="">選択してください</option>
            <option value="10">社員</option>
            <option value="20">社外</option>
            <option value="30">警備員</option>
          </select>
        </div>

        <div class="col-md-2 mb-3 center" [hidden]="pageForm.controls.userKbn.value === ''">
          <label class="font-weight-bold">ロール</label>
        </div>
        <div class="col-md-4 mb-3" [hidden]="!syain || pageForm.controls.userKbn.value === ''">
          <select class="form-control" [formControl]="pageForm.controls.role">
            <option value="">選択してください</option>
            <option value="1">担当者</option>
            <option value="2">DS部　部次長</option>
            <option value="3">DS部　SOG社員</option>
            <option value="4">DS部　FOG社員</option>
          </select>
        </div>
      </div>
          
      <div class="col-md-12 mt-3 mb-5 center">
        <button type="button" class="btn btn-info" (click)="btnExtractClick()">検　索</button>
        <button type="button" class="btn btn-secondary ml-3" (click)="btnClearClick()">クリア</button>
      </div>
      
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-end">
          <li class="page-item">
            <button class="page-link" (click)="onBackPage()">前へ</button>
          </li>
          <li class="page-item disabled">
            <label class="page-link">{{pageIndex + 1}} / {{totalPage}}</label>
          </li>
          <li class="page-item">
            <button class="page-link" (click)="onNextPage()">次へ</button>
          </li>
        </ul>
      </nav>
      <div class="tableDetail">
        <table class="table table-hover mb-5">
          <thead class="thead-light center">
            <tr>
              <th class="thGroup">所属</th>
              <th class="thNameKj">名前</th>
              <th class="thUserKbn">区分</th>
              <th class="thRole">ロール</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let obj of masterDataDisp; let i = index" (click)="btnRecordClick(i)">
              <td>{{obj.group}}</td>
              <td>{{obj.nameKj}}</td>
              <td>{{getSyubetu(obj.userKbn)}}</td>
              <td>{{getRole(obj.role)}}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </form>
</div>
