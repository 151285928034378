<app-menu>
    <div class="container">
        <p class="h2 mb-5 title">入館履歴</p>

        <h2 class="h4 mt-3">入館申請情報</h2>
        
        <div class="pl-2">
            <div class="row">
                <div class="font-weight-bold col-3">
                    入館予定日時
                </div>
                <div class="col-9">
                    {{data?.nyukanYoteiDate| date:'yyyy年MM月dd日 HH時mm分'}}
                </div>
            </div>
            <div class="row">
                <div class="font-weight-bold col-3">
                    退館予定日時
                </div>
                <div class="col-9">
                    {{data?.taikanYoteiDate| date:'yyyy年MM月dd日 HH時mm分'}}
                </div>
            </div>
            <div class="row">
                <div class="font-weight-bold col-12">
                    社内担当者情報
                </div>
                <div class="col-3 pl-4">
                    担当者
                </div>
                <div class="col-9">
                    {{data?.tantouName}}
                </div>
                <div class="col-3 pl-4">
                    アテンド社員
                </div>
                <div class="col-9">
                    {{data?.attendName}}
                </div>
            </div>
            <div class="row">
                <div class="font-weight-bold col-3">
                    団体名
                </div>
                <div class="col-9">
                    {{data?.group}}
                </div>
            </div>


            <app-simple-collapse [isCollapsed]="true" [text]="'申請情報詳細'">
               
                <div class="row">
                    <div class="font-weight-bold col-3">
                        入館目的
                    </div>
                    <div class="col-9">
                        {{data?.mokuteki}}
                    </div>
                    <div class="pl-4 col-3">
                        詳細
                    </div>
                    <div class="col-9">
                        {{data?.mokutekiSyosai}}
                    </div>
                </div>
                <div class="row">
                    <div class="font-weight-bold col-12">
                        入館者
                    </div>
                    <ng-container *ngFor="let nyukansya of data?.nyukansyaList; let i = index">
                        <div class="pl-4 col-3">
                            入館者{{i+1}}
                        </div>
                        <div class="col-9">
                            {{nyukansya.name}}
                        </div>
                    </ng-container>
                </div>
                <div class="row">
                    <div class="font-weight-bold col-12">
                        入館場所
                    </div>
                    <div class="col-3 pl-4">
                        SPビル
                    </div>
                    <div class="col-9">
                        {{data?.spArea}}
                    </div>
                    <div class="col-3 pl-4">
                        プロセシングセンター
                    </div>
                    <div class="col-9">
                        {{data?.pcArea}}
                    </div>
                    <div class="col-3 pl-4">
                        GCC-DC
                    </div>
                    <div class="col-9">
                        {{data?.dcArea}}
                    </div>
                </div>
                
                <div class="row">
                    <div class="font-weight-bold col-3">
                        カメラ・デジタルカメラ
                    </div>
                    <div *ngIf="data?.camera" class="col-9">
                        持ち込みあり
                    </div>
                    <div *ngIf="!data?.camera" class="col-9">
                        持ち込み無し
                    </div>
                </div>
                <div class="row">
                    <div class="font-weight-bold col-3">
                        携帯電話・スマートフォン
                    </div>
                    <div *ngIf="data?.phone" class="col-9">
                        持ち込みあり
                    </div>
                    <div *ngIf="!data?.phone" class="col-9">
                        持ち込み無し
                    </div>
                </div>
            
            </app-simple-collapse>
        </div>

        <h2 class="h4 mt-5">入館実績</h2>

        <div class="visitor-block pl-2" *ngFor="let nyukansya of data?.nyukansyaList; let i = index">

            <div class="font-weight-bold mb-3">入館者{{i+1}}</div>
            <div class="row">
                <div class="col-7">
                    {{nyukansya.name}}
                </div>
                <div class="col-5">
                    <span *ngIf="!nyukansya.status" class="text-secondary">未入館</span>
                    <span *ngIf="nyukansya.status == '01'" class="text-success">入館中</span>
                    <span *ngIf="nyukansya.status == '02'" class="text-primary">一時退館中</span>
                    <span *ngIf="nyukansya.status == '03'" class="text-danger">退館済み</span>
                </div>
            </div>

            <app-simple-collapse [isCollapsed]="true" [text]="'入館実績一覧'">
                <table class="table table-bordered">
                    <thead class="thead-dark">
                        <tr>
                            <th>日時</th>
                            <th>状態</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let history of nyukansya.historyList">
                            <td>{{history.timeStamp | date:'yyyy年MM月dd日 HH時mm分'}}</td>
                            <td>
                                <span *ngIf="!history.status" class="text-secondary">未入館</span>
                                <span *ngIf="history.status == '01'" class="text-success">入館</span>
                                <span *ngIf="history.status == '02'" class="text-primary">一時退館</span>
                                <span *ngIf="history.status == '03'" class="text-danger">退館</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </app-simple-collapse>
        </div>
    </div>
</app-menu>