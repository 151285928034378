import { Component, OnInit, ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { DomainData } from '@class/domain-data';
import { DbConnectService } from '@service/db-connect.service';
import { PageTransitionCheckService } from '@service/page-transition-check.service';
import { DomainNyuryokuComponent } from './domain-nyuryoku/domain-nyuryoku.component';

@Component({
  selector: 'app-domain-kanri',
  templateUrl: './domain-kanri.component.html',
  styleUrls: ['./domain-kanri.component.css']
})
export class DomainKanriComponent implements OnInit {
  @ViewChild(DomainNyuryokuComponent) domainKanriNyuryoku: DomainNyuryokuComponent;
  gridData: DomainData[];
  nyuryoku: boolean;
  kakunin: boolean;

  constructor(
    private router: Router,
    private pageAuth : PageTransitionCheckService,
    private dbConnect: DbConnectService
  ) { 
    this.gridData = [{
      check: false,
      no: '',
      name: ''
    }];  
  }

  ngOnInit(): void {
    if(!this.pageAuth.authCheck(this)){
      this.router.navigateByUrl('/home')
      return
    }

    this.nyuryoku = true;
    this.kakunin = false;

    this.getGridData();
  }

  getGridData(){
    this.gridData.length = 0;
    let sqlId = '1';
    const prmList = [];

    this.dbConnect.CmdSql('domainKanri', sqlId, prmList).then(result => {
      if (result === null) {
        // 入力へ
        this.nyuryoku = true;
        this.kakunin = false;
        return;
      }

      for (const obj of result) {
        const data = new DomainData(
          false,
          obj.nsm002_domain_no,
          obj.nsm002_domain_name
        );
        this.gridData.push(data);
      }

      // 表示
      this.domainKanriNyuryoku.setData();
      this.nyuryoku = true;
      this.kakunin = false;
      window.scrollTo(0, 0);
    });
  }

}
