<div class="container">
    <p class="h2 mb-5 title">登録者情報</p>
    <form class="needs-validation ml-3 mr-3 mb-3" [formGroup]="pageForm">
      <div class="form-row">
          <div class="col-md-2 mb-3">
            <label class="font-weight-bold">所属</label>
          </div>
          <div class="col-md-8 mb-3">
            <label class="ml-3">{{kakuninData.group}}</label>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-2 mb-3">
            <label class="font-weight-bold">氏名（カナ）</label>
          </div>
          <div class="col-md-8 mb-3">
            <label class="ml-3">{{kakuninData.nameKn}}</label>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-2 mb-3">
            <label class="font-weight-bold">氏名（漢字）</label>
          </div>
          <div class="col-md-8 mb-3">
            <label class="ml-3">{{kakuninData.nameKj}}</label>
          </div>
        </div>

        <p class="h2 mb-5 title">役割変更</p>

        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label class="font-weight-bold">種別</label>
          </div>
          <div class="col-md-12 mb-3">
                <label class="ml-3">{{getSyubetu(kakuninData.userKbn)}}</label>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-12 mb-3">
            <label class="font-weight-bold">ロール</label>
          </div>
          <div class="col-md-12 mb-3">
                <label class="ml-3">{{getRole(kakuninData.role)}}</label>
          </div>
        </div>
  
      </form>

    <div class="center">
      <button class="btn btn-primary mr-3" type="submit" name="btnNext" (click)="btnNextClick()">変　更</button>
      <button class="btn btn-secondary ml-3" type="submit" name="btnBack" (click)="btnBackClick()">戻　る</button>
    </div>
  </div>
