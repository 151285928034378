<body>
    <div class="container">
      <p class="h2 mb-5 title">マスタ修正</p>
      <form class="needs-validation ml-3 mr-3 mb-3">
        <p class="h4 mb-4">申請者情報</p>
        <div class="form-row">
          <div class="col-md-2 mb-3">
            <label class="font-weight-bold">所属</label>
          </div>
          <div class="col-md-8 mb-3">
            <label class="ml-3">{{masterData.group}}</label>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-2 mb-3">
            <label class="font-weight-bold">氏名（カナ）</label>
          </div>
          <div class="col-md-8 mb-3">
            <label class="ml-3">{{masterData.nameKn}}</label>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-2 mb-3">
            <label class="font-weight-bold">氏名（漢字）</label>
          </div>
          <div class="col-md-8 mb-3">
            <label class="ml-3">{{masterData.nameKj}}</label>
          </div>
        </div>
        <div class="form-row" [hidden]="isSyain()">
          <div class="col-md-2 mb-3">
            <label class="font-weight-bold">携帯電話</label>
          </div>
          <div class="col-md-8 mb-3">
            <label class="ml-3">{{masterData.telNo}}</label>
          </div>
        </div>
        <div class="form-row" [hidden]="!isSyain()">
          <div class="col-md-2 mb-3">
            <label class="font-weight-bold">内線</label>
          </div>
          <div class="col-md-8 mb-3">
            <label class="ml-3">{{masterData.telNo}}</label>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-2 mb-3">
            <label class="font-weight-bold">メールアドレス</label>
          </div>
          <div class="col-md-8 mb-3">
            <label class="ml-3">{{masterData.email}}</label>
          </div>
        </div>
      </form>
      <div class="center">
        <button class="btn btn-primary mr-3" type="submit" name="btnToroku" (click)="btnTorokuClick()">登　録</button>
        <button class="btn btn-secondary ml-3" type="submit" name="btnBack" (click)="btnBackClick()">戻　る</button>
      </div>
    </div>
  </body>
  