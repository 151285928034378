import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserData } from '@class/user-data';

@Component({
  selector: 'app-role-change-kensaku',
  templateUrl: './role-change-kensaku.component.html',
  styleUrls: ['./role-change-kensaku.component.css']
})
export class RoleChangeKensakuComponent implements OnInit {
  @Input() gridData: UserData[];
  @Output() kjnNo = new EventEmitter();
  pageForm: FormGroup;
  masterData: UserData[];
  masterDataDisp: UserData[];
  syain: boolean;

  pageIndex: number;
  totalPage: number;
  dataCount: number;

  constructor(
    private fb: FormBuilder,
  ) { 
    this.pageForm = fb.group({
      group: '',
      nameKn: '',
      telNo: '',
      email: '',
      userKbn: '',
      role: ''
    });
    this.masterData = [{
      kjnNo: '',
      userId: '',
      syainNo: '',
      role: '',
      group: '',
      nameKn: '',
      nameKj: '',
      telNo: '',
      email: '',
      //以下未使用
      mainKjnNo: '',
      subKjnNo: '',
      userKbn: ''
    }];
    this.masterDataDisp = [{
      kjnNo: '',
      userId: '',
      syainNo: '',
      role: '',
      group: '',
      nameKn: '',
      nameKj: '',
      telNo: '',
      email: '',
      //以下未使用
      mainKjnNo: '',
      subKjnNo: '',
      userKbn: ''
    }];

  }

  ngOnInit(): void {
    this.pageIndex = 0;
    this.totalPage = 0;

    // 1ページの最大データ表示数
    this.dataCount = 5;
  }

    // クリアボタン　クリック
    btnClearClick(): void {
      this.pageForm.controls.group.setValue('');
      this.pageForm.controls.nameKn.setValue('');
      this.pageForm.controls.telNo.setValue('');
      this.pageForm.controls.email.setValue('');
      this.pageForm.controls.userKbn.setValue('');
      this.pageForm.controls.role.setValue('');
    }

    btnExtractClick(): void {
      this.setData();
  
      // 所属
      if (this.pageForm.controls.group.value !== '') {
        this.masterData = this.masterData.filter(obj => obj.group != null && obj.group.indexOf(this.pageForm.controls.group.value) > -1);
      }
      // 登録者氏名
      if (this.pageForm.controls.nameKn.value !== '') {
        this.masterData = this.masterData.filter(obj => obj.nameKn != null && obj.nameKn.indexOf(this.pageForm.controls.nameKn.value) > -1);
      }
      // 電話番号
      if (this.pageForm.controls.telNo.value !== '') {
        this.masterData = this.masterData.filter(obj => obj.telNo != null && obj.telNo === this.pageForm.controls.telNo.value);
      }
      // メールアドレス
      if (this.pageForm.controls.email.value !== '') {
        this.masterData = this.masterData.filter(obj => obj.email != null && obj.email === this.pageForm.controls.email.value);
      }
      // 区分
      if (this.pageForm.controls.userKbn.value !== '') {
        this.masterData = this.masterData.filter(obj => obj.userKbn != null && obj.userKbn === this.pageForm.controls.userKbn.value)
      }
      // ロール
      if (this.pageForm.controls.role.value.trim() !== '') {
        this.masterData = this.masterData.filter(obj => obj.role != null && obj.role === this.pageForm.controls.role.value.trim())
      }

      // ページネーション
      this.pageIndex = 0;
      this.totalPage = Math.ceil(this.masterData.length / this.dataCount);
      this.changePage();
    }
  
    onNextPage(): void {
      if (this.pageIndex === this.totalPage -1) {
        return;
      }
      this.pageIndex++;
      this.changePage();
  
    }
  
    onBackPage(): void {
      if (this.pageIndex === 0) {
        return;
      }
      this.pageIndex--;
      this.changePage();
    }

    changePage(): void {
      
      let val = 0;
      this.masterDataDisp.length = 0;
      while (val < this.dataCount) {
        const index = (this.pageIndex) * this.dataCount + val;
        if (index < this.masterData.length) {
          const data = new UserData(
            this.masterData[index].kjnNo,
            this.masterData[index].userId,
            this.masterData[index].syainNo,
            this.masterData[index].role,
            this.masterData[index].group,
            this.masterData[index].nameKn,
            this.masterData[index].nameKj,
            this.masterData[index].telNo,
            this.masterData[index].email,
            this.masterData[index].mainKjnNo,
            this.masterData[index].subKjnNo,
            this.masterData[index].userKbn
          );
          this.masterDataDisp.push(data);
        }
        val++;
      }
      
    }


  // データ行　クリック
  btnRecordClick(index: number): void {
    if (this.masterDataDisp[index].kjnNo === null || this.masterDataDisp[index].kjnNo === '') {
      return;
    }
    this.kjnNo.emit(this.masterDataDisp[index].kjnNo);
  }

  setData(): void {
    this.masterData.length = 0;
    this.gridData.forEach(obj => {
      const data = new UserData(
        obj.kjnNo,
        obj.userId,
        obj.syainNo,
        obj.role,
        obj.group,
        obj.nameKn,
        obj.nameKj,
        obj.telNo,
        obj.email,
        obj.mainKjnNo,
        obj.subKjnNo,
        obj.userKbn
      );
      this.masterData.push(data);
    });

    // ページネーション
    this.pageIndex = 0;
    this.totalPage = Math.ceil(this.masterData.length / this.dataCount);
    this.changePage();
  }

  getRole(role: string): string {
    let result = '';
    if (role === '1') {
      result = '担当者';
    } else if (role === '2') {
      result = 'DS部　部次長';
    } else if (role === '3') {
      result = 'DS部　SOG社員';
    } else if (role === '4') {
      result = 'DS部　FOG社員';
    } else if (role === '5') {
      result = '警備員'
    } else if (role === '' || role === null) {
      result = '一般'
    }
    return result;
  }

  getSyubetu(syubetu: string): string {
    let result = '';
    if (syubetu === '10') {
      result = '社員';
    } else {
      result = '社外';
    }
    return result;
  }

  userKbnChange(): void{
    if(this.pageForm.controls.userKbn.value === '10'){
      this.syain = true;
      this.pageForm.controls.role.setValue('');
    }else{
      this.syain = false;
      this.pageForm.controls.role.setValue('');
    }
  }

}
