import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef  } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { NyukansyaData } from '@class/nyukansya-data';
import { DbConnectService } from '@service/db-connect.service';
import { UserDataService } from '@service/user-data.service';
import { ModalService } from '@service/modal.service'
import { SpinnerModalComponent } from '@spinner/spinner-modal.component';
import { CustomValidator } from '@utility/custom-validator';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  @Input() nyukansya: NyukansyaData[];
  @Output() changeFlg = new EventEmitter();

  pageForm: FormGroup;
  touchFlg: boolean;
  prefix_str: string ="　入館：";
  suffix_str: string ="予定：";

  constructor(
    private fb: FormBuilder,
    private dbConnect: DbConnectService,
    private userData: UserDataService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
  ) { 
    this.pageForm = this.fb.group({
      nameKj: ['', [Validators.required, noWhitespaceValidator]],
      nameKn: ['', [Validators.required, CustomValidator.katakana, noWhitespaceValidator]]
    });
  }

  ngOnInit(): void {
    this.pageForm.controls.nameKj.setValue(this.getNameKj());
    this.pageForm.controls.nameKn.setValue(this.getNameKn());
    this.touchFlg = false;
  }

  getNameKj(): string{
    return this.nyukansya[0].nameKj.replace(this.suffix_str, "").split(this.prefix_str)[0];
  }

  getNameKn(): string{
    return this.nyukansya[0].nameKn.replace(this.suffix_str, "").split(this.prefix_str)[0];
  }

  setTouched(): void {
    this.touchFlg = true;
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

  async btnTorokuClick(): Promise<any> {
    this.setTouched();
    if (this.pageForm.invalid) {
      return;
    }

    let nameKj = this.getNameKj();
    let nameKn = this.getNameKn();
    let input_nameKj = this.pageForm.controls.nameKj.value;
    let input_nameKn = this.pageForm.controls.nameKn.value;

    if(input_nameKj.indexOf(this.suffix_str) >= 0 || input_nameKj.indexOf(this.prefix_str.trim()) >= 0
      || input_nameKn.indexOf(this.suffix_str) >= 0 || input_nameKn.indexOf(this.prefix_str.trim()) >= 0){
      alert("変更後氏名の入力文字が不正です。");
      return;
    }

    let message = "";
    let cancel = false;
    if(nameKj.trim() === input_nameKj.trim()){
      message = "入館者の変更を取り消します。";
      cancel = true;
    }else{
      message = "入館者の変更を登録します。";
      cancel = false;
    }

    if (!confirm(message + 'よろしいですか？')) {
      return;
    }
    
    this.modalService.openModal(SpinnerModalComponent, this.viewContainerRef, []);

    if(cancel){
      this.nyukansya[0].nameKj = nameKj;
      this.nyukansya[0].nameKn = nameKn;
    }else{
      this.nyukansya[0].nameKj = this.suffix_str + nameKj + this.prefix_str + input_nameKj.trim();
      this.nyukansya[0].nameKn = this.suffix_str + nameKn + this.prefix_str + input_nameKn.trim();
    }

    // 入館者データの修正
    const prmList = [{
      key: ':pSINSEI_NO',
      value: this.nyukansya[0].sinseiNo
    },
    {
      key: ':pSEQ_NO',
      value: this.nyukansya[0].seqNo
    },
    {
      key: ':pNAME_KN',
      value: this.nyukansya[0].nameKn
    },
    {
      key: ':pNAME_KJ',
      value: this.nyukansya[0].nameKj
    },
    {
      key: ':pUPD_KJN_NO',
      value: this.userData.keep.kjnNo
    }];
    const result = await this.dbConnect.CmdStored('nyutaikanUketuke', '13', prmList);

    this.modalService.closeModal();
    alert('入館者変更が完了しました。');

    this.changeFlg.emit(false);
  }

  btnBackClick(): void {
    // 戻る
    this.changeFlg.emit(false);
  }
}

export function noWhitespaceValidator(control: AbstractControl) {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { 'whitespace': true };
}