<body>
  <div class="container">
    <p class="h2 mb-5 title">マスタ登録</p>
    <form class="needs-validation ml-3 mr-3 mb-3">
      <p class="h4 mb-4">申請者情報</p>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">種別</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.syubetu}}</label>
        </div>
      </div>
      <div [hidden]="!isSyain()">
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">社員番号</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.syainNo}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">ロール</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.roleDisp}}</label>
        </div>
      </div>
    </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">所属</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.group}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">氏名（カナ）</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.nameKn}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">氏名（漢字）</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.nameKj}}</label>
        </div>
      </div>
      <div class="form-row" [hidden]="isSyain()">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">携帯電話</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.telNo}}</label>
        </div>
      </div>
      <div class="form-row" [hidden]="!isSyain()">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">内線</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.telNo}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">メールアドレス</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.email}}</label>
        </div>
      </div>
<!--
      <div [hidden]="isSyain()">
      <p class="h4 mt-3 mb-4">社内担当者（正）情報</p>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">社員番号</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.syainNoMain}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">所属</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.groupMain}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">氏名（カナ）</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.nameKnMain}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">氏名（漢字）</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.nameKjMain}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">内線</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.telNoMain}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">メールアドレス</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.emailMain}}</label>
        </div>
      </div>
      <p class="h4 mt-3 mb-4">社内担当者（副）情報</p>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">社員番号</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.syainNoSub}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">所属</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.groupSub}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">氏名（カナ）</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.nameKnSub}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">氏名（漢字）</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.nameKjSub}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">内線</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.telNoSub}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">メールアドレス</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{masterData.emailSub}}</label>
        </div>
      </div>
    </div>
-->
    </form>
    <div class="center">
      <button class="btn btn-primary mr-3" type="submit" name="btnToroku" (click)="btnTorokuClick()">登　録</button>
      <button class="btn btn-secondary ml-3" type="submit" name="btnBack" (click)="btnBackClick()">戻　る</button>
    </div>
  </div>
</body>
