import { FormGroup, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';

const ZENKAKU_REGEXP = /^[^\x01-\x7E\xA1-\xDF]+$/;
const KATAKANA_REGEXP = /^[ァ-ヶー－　]+$/;
const NUMERIC_REGEXP = /^[0-9]+$/;
const HANKAKU_KATAKANA_REGEXP = /^[ｦ-ﾟー－　]+$/;

// ハイフンの全角として長音記号 (U+30FC)と全角ハイフンマイナス (U+FF0D) を許容する。
// 半角ハイフンと呼ばれている記号は Unicode 上ではハイフンマイナスと呼ばれている。
const BANTI_REGEXP = /^[0-9\-０-９ー－]+$/;

//追加　パスワードチェック
const PASS_REGEXP = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[@!"#$%&'()*+-./:;<>=?[\]\^_{}|~\\])|(?=.*[A-Z])(?=.*[0-9])(?=.*[@!"#$%&'()*+-./:;<>=?[\]\^_{}|~\\])|(?=.*[a-z])(?=.*[0-9])(?=.*[@!"#$%&'()*+-./:;<>=?[\]\^_{}|~\\]))([a-zA-Z0-9@!"#$%&'()*+-./:;<>=?[\]\^_{}|~\\]){10,}$/

function isEmptyInputValue(val: any) {
    return val == null || val.length === 0;
}

function isInvalidDate(date: Date) {
    return Number.isNaN(date.getTime());
}

export class CustomValidator {
    static zenkaku(control: AbstractControl): ValidationErrors | null {
        if (isEmptyInputValue(control.value)) {
            return null;
        }
        return ZENKAKU_REGEXP.test(control.value) ? null : { 'zenkaku': true };
    }

    static katakana(control: AbstractControl): ValidationErrors | null {
        if (isEmptyInputValue(control.value)) {
            return null;
        }
        return KATAKANA_REGEXP.test(control.value) ? null : { 'katakana': true };
    }

    static hankakukatakana(control: AbstractControl): ValidationErrors | null {
        if (isEmptyInputValue(control.value)) {
            return null;
        }
        return HANKAKU_KATAKANA_REGEXP.test(control.value) ? null : { 'hankaku-katakana': true };
    }

    static numeric(control: AbstractControl): ValidationErrors | null {
        if (isEmptyInputValue(control.value)) {
            return null;
        }
        return NUMERIC_REGEXP.test(control.value) ? null : { 'numeric': true };
    }

    static minvalue(val): ValidationErrors | null {
        return (control: AbstractControl) => {
            if (isEmptyInputValue(control.value)) {
                return null;
            }

            if (!NUMERIC_REGEXP.test(control.value)) {
                return null;
            }

            if (val > control.value) {
                return { 'minvalue': true };
            }
        };
    }

    //追加　パスワードチェック
    static password(control: AbstractControl): ValidationErrors | null {
        if (isEmptyInputValue(control.value)){
            return null;
        }
        return PASS_REGEXP.test(control.value) ? null : { 'password':true};
    }

    static exitDate(control: AbstractControl): ValidationErrors | null {
        const admissionDate = new Date(`${control.get('nyukanYoteiYmd').value} ${control.get('nyukanYoteiTime').value}`);
        const exitDate = new Date(`${control.get('taikanYoteiYmd').value} ${control.get('taikanYoteiTime').value}`);
        if(isInvalidDate(admissionDate) && isInvalidDate(exitDate)){
            return null;
        }

        return exitDate >= admissionDate ? null : {'exitDate':true};
    }

    static admissionDate(control: AbstractControl): ValidationErrors | null {
        const admissionDate = new Date(`${control.get('nyukanYoteiYmd').value} ${control.get('nyukanYoteiTime').value}`);
        const now = new Date();
        if(isInvalidDate(admissionDate)){
            return null;
        }

        return admissionDate >= now ? null: {'admissionDate': true};
    }

    static period(control: AbstractControl): ValidationErrors | null {
        const admissionDate = new Date(`${control.get('nyukanYoteiYmd').value} ${control.get('nyukanYoteiTime').value}`);
        admissionDate.setDate(admissionDate.getDate() + 7);
        const exitDate = new Date(`${control.get('taikanYoteiYmd').value} ${control.get('taikanYoteiTime').value}`);
        if(isInvalidDate(admissionDate) && isInvalidDate(exitDate)){
            return null;
        }

        return admissionDate > exitDate ? null : {'period':true};
    }

    static existEmail(): AsyncValidatorFn{
        return (control: AbstractControl): Promise<ValidationErrors | null> => {
            return 
        }
    }
}