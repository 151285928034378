import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { HomeComponent } from './home/home.component';
import { MasterTorokuComponent } from './master-toroku/master-toroku.component';
import { SinseiTorokuComponent } from './sinsei-toroku/sinsei-toroku.component';
import { SinseiSyokaiComponent } from './sinsei-syokai/sinsei-syokai.component';
import { NyutaikanUketukeComponent } from './nyutaikan-uketuke/nyutaikan-uketuke.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { NyukansyouComponent } from './nyukansyou/nyukansyou.component';
import { MasterKanriComponent } from './master-kanri/master-kanri.component';
import { MasterSyuseiComponent } from './master-syusei/master-syusei.component';  //★
import { RoleChangeComponent } from './role-change/role-change.component'; //★
import { DomainKanriComponent } from './domain-kanri/domain-kanri.component'; //★
import { KaritorokuComponent } from './karitoroku/karitoroku.component';
import { RirekiListComponent } from './nyukan-rireki/rireki-list/rireki-list.component';
import { RirekiDetailComponent } from './nyukan-rireki/rireki-detail/rireki-detail.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'changePassword', component: ChangePasswordComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'forgetPassword', component: ForgetPasswordComponent },
  { path: 'masterToroku', component: MasterTorokuComponent, canActivate: [AuthGuard]},
  { path: 'masterKanri', component: MasterKanriComponent, canActivate: [AuthGuard]},
  { path: 'sinseiToroku', component: SinseiTorokuComponent, canActivate: [AuthGuard] },
  { path: 'sinseiSyokai', component: SinseiSyokaiComponent, canActivate: [AuthGuard] },
  { path: 'nyutaikanUketuke', component: NyutaikanUketukeComponent, canActivate: [AuthGuard] },
  { path: 'nyukansyou/:id', component: NyukansyouComponent, canActivate: [AuthGuard] },
  { path: 'nyukansyou/:id', component: NyukansyouComponent, canActivate: [AuthGuard] },
  { path: 'masterSyusei', component: MasterSyuseiComponent, canActivate: [AuthGuard]}, //★
  { path: 'roleChange', component: RoleChangeComponent, canActivate: [AuthGuard]}, //★
  { path: 'domainKanri', component: DomainKanriComponent, canActivate: [AuthGuard]}, //★
  { path: 'karitoroku', component: KaritorokuComponent}, //★
  { path: 'nyukanRireki', component: RirekiListComponent, canActivate: [AuthGuard]}, //★,
  { path: 'nyukanRireki/:id', component: RirekiDetailComponent, canActivate: [AuthGuard]}, //★
  { path: 'nyutaikanUketuke/:id', component: NyutaikanUketukeComponent }  //★
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
