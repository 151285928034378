import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from '@utility/custom-validator';
import { UserData } from '@class/user-data';
import { DbConnectService } from '@service/db-connect.service';
import { ModalService } from '@service/modal.service';
import { SpinnerModalComponent } from '@spinner/spinner-modal.component';

@Component({
  selector: 'app-role-change-kakunin',
  templateUrl: './role-change-kakunin.component.html',
  styleUrls: ['../role-change.component.css']
})
export class RoleChangeKakuninComponent implements OnInit {
  @Input() kakuninData: UserData;
  @Output() changeFlg = new EventEmitter();
  pageForm: FormGroup;
  syain: boolean;

  constructor(
    private dbConnect: DbConnectService,
    private fb: FormBuilder,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef
  ) {
    
    this.pageForm = fb.group({
      userkbn:['', [Validators.required]],
      syubetu: ['', [Validators.required]],
      group: ['', [Validators.required]],
      nameKn: ['', [Validators.required, CustomValidator.katakana]],
      nameKj: ['', [Validators.required]],
      role: ['', [Validators.required]]
    });
    
   }

  ngOnInit(): void {
  }

  getRole(role: string): string {
    let result = '';
    if (role === '1') {
      result = '担当者';
    } else if (role === '2') {
      result = 'DS部　部次長';
    } else if (role === '3') {
      result = 'DS部　SOG社員';
    } else if (role === '4') {
      result = 'DS部　FOG社員';
    } else if (role === '5') {
      result = '警備員'
    } else if (role === '') {
      result = '一般'
    }

    return result;
  }

  getSyubetu(syubetu: string): string {
    let result = '';
    if (syubetu === '10') {
      result = '社員';
    } else {
      result = '社外';
    }
    return result;
  }

  async btnNextClick(): Promise<any>{
    //登録処理
    if (!confirm('変更します。よろしいですか？')) {
      return;
    }
    
    this.modalService.openModal(SpinnerModalComponent, this.viewContainerRef, []);

    const prmList = [{
      key: ':pUSER_ID',
      value: this.kakuninData.userId
    },{
      key: ':pROLE',
      value: this.kakuninData.role
    }];

    let sqlId = '2';
    const result = await this.dbConnect.CmdStored('roleChange', sqlId, prmList);

    this.modalService.closeModal();
    this.changeFlg.emit(true);
  }

  btnBackClick(){
    this.changeFlg.emit(false);
  }

  setData(data: UserData){
    if(data.userKbn === '10'){
      this.syain = true;
    }else{
      this.syain = false;
    }
  }

}
