export class NyukansyaData {
  sinseiNo: string; // 申請番号
  seqNo: string; // 整理番号
  nameKn: string; // 氏名カナ
  nameKj: string; // 氏名漢字
  status: string; // ステータス
  kakunin1: boolean; // 本人確認
  kakunin2: boolean; // 指透過
  kakunin3: boolean; // 金属探知
  key: string; // 鍵番号
  iccard: string; // ICカード番号

  constructor(
    sinseiNo: string,
    seqNo: string,
    nameKn: string,
    nameKj: string,
    status: string,
    kakunin1: boolean,
    kakunin2: boolean,
    kakunin3: boolean,
    key: string,
    iccard: string
  ) {
    this.sinseiNo = sinseiNo;
    this.seqNo = seqNo;
    this.nameKn = nameKn;
    this.nameKj = nameKj;
    this.status = status;
    this.kakunin1 = kakunin1;
    this.kakunin2 = kakunin2;
    this.kakunin3 = kakunin3;
    this.key = key;
    this.iccard = iccard;
  }
}
