<body>
  <div class="container">
    <p class="h2 mb-5 title">申請情報照会</p>
    <form class="needs-validation ml-3 mr-3 mb-3">
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">入館予定日時</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{nyukanYoteiDat}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">退館予定日時</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{taikanYoteiDat}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <label class="font-weight-bold">社内担当者情報</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 ml-3">
          <label>主担当</label>
        </div>
          <label>{{mainTanto}}</label>
      </div>
      <!--
      <div class="form-row">
        <div class="col-md-2 ml-3">
          <label>副担当</label>
        </div>
          <label>{{subTanto}}</label>
      </div>
      -->

      <div class="form-row">
        <div class="col-md-2 ml-3 mb-3">
          <label>アテンド社員</label>
        </div>
        <label [hidden]="syokaiData[0].status === '01'">{{attendTanto}}</label>
        <div class="col-md-9 mb-3"  [hidden]="syokaiData[0].status !== '01' || !kyakka">
          <input class="form-check-input" type="checkbox" [formControl]="pageForm.controls.dojo" (click)="clickDojo()">
          <label class="form-check-label">同上</label>
          <div [hidden]="pageForm.controls.dojo.value">
            <div class="form-row">
              <div class="col-md-12 mb-3">
                <label class="font-weight-bold">社員番号</label>
                <ng-container
                  *ngIf="pageForm.controls.syainNo.hasError('required') && (pageForm.controls.syainNo.touched || pageForm.controls.syainNo.dirty)">
                  <div class="col-md-12">
                    <span class="textRed">※社員番号を入力してください。</span>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="pageForm.controls.syainNoCheck.value == false">
                  <div class="col-md-12">
                    <span class="textRed">※入力した社員番号は存在しません。</span>
                  </div>
                </ng-container>
                <input type="number" class="form-control mb-3" [formControl]="pageForm.controls.syainNo" [class.input-invalid]="pageForm.controls.syainNo.invalid && (pageForm.controls.syainNo.touched || pageForm.controls.syainNo.dirty)">
                <button class="btn btn-primary btn-sm" name="btnKensakuMain" (click)="btnKensakuMainClick()">検索</button> 
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-4 mb-3">
                <label class="font-weight-bold">所属</label>
              </div>
              <div class="col-md-8 mb-3">
                <label class="ml-3">{{itemattend.groupMain}}</label>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-4 mb-3">
                <label class="font-weight-bold">氏名（カナ）</label>
              </div>
              <div class="col-md-8 mb-3">
                <label class="ml-3">{{itemattend.nameKnMain}}</label>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-4 mb-3">
                <label class="font-weight-bold">氏名（漢字）</label>
              </div>
              <div class="col-md-8 mb-3">
                <label class="ml-3">{{itemattend.nameKjMain}}</label>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-4 mb-3">
                <label class="font-weight-bold">内線</label>
              </div>
              <div class="col-md-8 mb-3">
                <label class="ml-3">{{itemattend.telNoMain}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">団体名</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{syokaiData[0].group}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">入館目的</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{syokaiData[0].mokuteki}}</label>
        </div>
      </div>
      <div class="form-row mb-3">
        <div class="col-md-2 ml-2">
          <label>詳細</label>
        </div>
        <div class="col-md-8">
          <label>{{syokaiData[0].mokutekiSyosai}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">添付資料</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3" [hidden]="!(uploadList.length === 0)">搬入物なし</label>
          <label class="btn btn-success btn-sm ml-3" [hidden]="!upload">
            <input style="display: none;" class="file-upload" id="upload" type="file" accept=".pdf" (change)="onUpload($event)">
            申請書類添付
          </label>
        </div>
      </div>
      <div class="col-md-12 mb-3">
        <span class="advice">※GCC-DCの搬入口よりエレベータを経由して機材等を搬出入する場合は「搬出入許可申請書」を作成の上、添付してください。</span>
        <br>
        <span class="advice">※GCC-DCにて設備工事や、サーバ機器設置工事を行う場合は「工事計画申請書」を作成の上、添付してください。</span>
      </div>
      <div *ngFor="let upload of uploadList; let i = index">
        <div class="form-row mb-3">
          <div class="col-md-2 ml-3">
            <label>添付資料{{i + 1}}</label>
          </div>
          <div class="col-md-8">
            <div class="form-row">
            <label>{{upload}}</label>
            <button type="button" class="btn btn-success btn-sm ml-3" name="btnDownload" (click)="onDownload(i)">ダウンロード</button>
            <button type="button" class="btn btn-danger btn-sm ml-3" name="btnDelete" (click)="onDelete(i)">削除</button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <label class="font-weight-bold">入館者</label>
        </div>
      </div>
      <div *ngFor="let nyukansya of nyukansyaData; let i = index">
        <div class="form-row mb-3">
          <div class="col-md-2 ml-3">
            <label>入館者{{i + 1}}</label>
          </div>
          <div class="col-md-8">
            <label>{{nyukansya.nameKj}}</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mt-3">
          <label class="font-weight-bold">搬入物</label>
        </div>
        <div class="col-md-8 mt-3" [hidden]="!noHannyu">
          <label class="ml-3">搬入物なし</label>
        </div>
      </div>
      <div *ngFor="let hannyu of hannyuList; let i = index">
      <div class="form-row">
        <div class="col-md-2 ml-3">
          <label>搬入物{{i + 1}}</label>
        </div>
        <div class="col-md-8">
          <label class="ml-3">{{hannyu.meisyo}}</label>
        </div>
      </div>
    </div>
      <div class="form-row">
        <div class="col-md-2 mt-3">
          <label class="font-weight-bold">搬出物</label>
        </div>
        <div class="col-md-8 mt-3" [hidden]="!noHansyutu">
          <label class="ml-3">搬出物なし</label>
        </div>
      </div>
      <div *ngFor="let hansyutu of hansyutuList; let i = index">
      <div class="form-row">
        <div class="col-md-2 ml-3">
          <label>搬出物{{i + 1}}</label>
        </div>
        <div class="col-md-8">
          <label class="ml-3">{{hansyutu.meisyo}}</label>
        </div>
      </div>
    </div>

    <div [hidden]="syokaiData[0].status === '01'">
      <div class="form-row">
        <div class="col-md-2 mt-3">
          <label class="font-weight-bold">入館場所</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-3">
          <label class="ml-3">SPビル</label>
        </div>
        <div class="col-md-7">
          <label class="ml-3">{{syokaiData[0].spAreas}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-3">
          <label class="ml-3">プロセシングセンター</label>
        </div>
        <div class="col-md-7">
          <label class="ml-3">{{syokaiData[0].pcAreas}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-3">
          <label class="ml-3">GCC-DC</label>
        </div>
        <div class="col-md-7">
          <label class="ml-3">{{syokaiData[0].dcAreas}}</label>
        </div>
      </div>
      <!-- <div class="form-row">
        <div class="col-md-3 mb-3">
          <label class="ml-3">第二データセンター</label>
        </div>
        <div class="col-md-7 mb-3">
          <label class="ml-3">{{syokaiData[0].sdAreas}}</label>
        </div>
      </div> -->
    </div>

      <!-- ★追加 -->
      <div [hidden]="syokaiData[0].status !== '01' || !kyakka">
      <form class="needs-validation ml-3 mr-3 mb-3" [formGroup]="pageForm">
      <label class="font-weight-bold">入館場所</label>
      <div class="form-row ">
        <div class="col-md-12 mb-3">
          <select class="form-control" name="CmbNyukanArea" (change)="CmbNyukanAreaChange()" [formControl]="pageForm.controls.nyukanArea">
            <option value="00">選択してください</option>
            <option value="01">SPビル・プロセシングセンター</option>
            <option value="02">GCC-DC</option>
          </select>
        </div>
      </div>
      <div class="form-row" [hidden]="!sp">
        <div class="col-md-12">
          <label>SPビル</label>
        </div>
        <div class="col-md-12 mb-3">
          <!-- <div class="displayFlexWrap" formArrayName="spAreas"> -->
          <div formArrayName="spAreas">
            <div *ngFor="let spArea of spAreaList; let i = index">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" [formControlName]="i"
                  (change)="chkAreaChange()">
                <!-- <label class="form-check-label mr-3">{{spArea.meisyo}}</label> -->
                <label class="form-check-label mr-3">{{spArea.meisyo.split('\\')[0]}}</label>
              </div>
              <div class="">
                <label class="form-check-label mr-3 small">{{spArea.meisyo.split('\\')[0]}}詳細：{{spArea.meisyo.split('\\')[1]}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row" [hidden]="!pc">
        <div class="col-md-12">
          <label>プロセシングセンター</label>
        </div>
        <div class="col-md-12 mb-3">
          <!-- <div class="displayFlexWrap" formArrayName="pcAreas"> -->
            <div formArrayName="pcAreas">
              <div *ngFor="let pcArea of pcAreaList; let i = index">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" [formControlName]="i"
                  (change)="chkAreaChange()">
                <!-- <label class="form-check-label mr-3">{{pcArea.meisyo}}</label> -->
                <label class="form-check-label mr-3">{{pcArea.meisyo.split('\\')[0]}}</label>
              </div>
              <div class="">
                <label class="form-check-label mr-3 small">{{pcArea.meisyo.split('\\')[0]}}詳細：{{pcArea.meisyo.split('\\')[1]}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row" [hidden]="!dc">
        <div class="col-md-12">
          <label>GCC-DC</label>
        </div>
        <div class="col-md-12 mb-3">
          <!-- <div class="displayFlexWrap" formArrayName="dcAreas"> -->
          <div formArrayName="dcAreas">
            <div *ngFor="let dcArea of dcAreaList; let i = index">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" [formControlName]="i"
                  (change)="chkAreaChange()">
                <!-- <label class="form-check-label mr-3">{{dcArea.meisyo}}</label> -->
                <label class="form-check-label mr-3">{{dcArea.meisyo.split('\\')[0]}}</label>
              </div>
              <div class="">
                <label class="form-check-label mr-3 small">{{dcArea.meisyo.split('\\')[0]}}詳細：{{dcArea.meisyo.split('\\')[1]}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div [hidden]="!sp && !pc && !dc">
        <div class="col-md-12">
          <span class="advice" [hidden]="!this.noCamera">※カメラ・デジタルカメラの持込申請があるためLevel4、5のエリアは選択できません。</span>
        </div>
        <div class="col-md-12">
          <span class="advice" [hidden]="!this.noPhone">※携帯電話・スマートフォンの持込申請があるためLevel4、5のエリアは選択できません。</span>
        </div>
      
        <div [hidden]="!checkMotikomi()">
          <div class="form-check form-check-inline">
            <label class="ml-0 mt-4 form-check-label font-weight-bold text-danger">※持ち込み物　特別許可申請</label>
            <input class="ml-4 mt-4 form-check-input" name="ChkspecialCheck" type="checkbox" [formControl]="pageForm.controls.specialflg" (change)="ChkSpecialCheckChange()">
          </div>
          <div [hidden]="!isSpecialCheck() || !checkMotikomi()">
            <label class="font-weight-bold mt-3 text-danger lead">特別許可申請理由　</label>
            <ng-container
              *ngIf="pageForm.controls.specialextra.hasError('required') && (pageForm.controls.specialextra.touched || pageForm.controls.specialextra.dirty)">
                <span class="textRed">※持ち込み禁止エリアへの持ち込み理由を選択してください。</span>
            </ng-container>
            <select class="form-control" (change)="CmbReasonChange()" [formControl]="pageForm.controls.specialreason" [class.input-invalid]="pageForm.controls.specialreason.invalid && (pageForm.controls.specialreason.touched || pageForm.controls.specialreason.dirty)">
              <option value="">選択してください</option>
              <option value="1">作業完了報告書出力のため</option>
              <option value="2">テザリング利用のため</option>
              <option value="9">その他</option>
            </select>
            <span [hidden]="pageForm.controls.specialreason.value !== '9'">
            <span class="textRed">※その他の場合は持ち込み理由を入力してください。</span>
            <input type="text" class="form-control" [formControl]="pageForm.controls.specialextra" [class.input-invalid]="pageForm.controls.specialextra.invalid && (pageForm.controls.specialextra.touched || pageForm.controls.specialextra.dirty)">
            </span>
          </div>
        </div>
      </div>

    </form>
      </div>
      <!-- ★追加 -->
      <div class="form-row">
        <div class="col-md-2">
          <label class="font-weight-bold">持込作業PC</label>
        </div>
        <div class="col-md-8 mt-3" [hidden]="!noPc">
          <label class="ml-3">持込作業PCなし</label>
        </div>
      </div>
      <div *ngFor="let pc of pcList; let i = index">
        <div class="form-row">
          <div class="col-md-2 ml-3">
            <label>持込作業PC{{i + 1}}</label>
          </div>
          <div class="col-md-8">
            <label class="ml-3">コンピュータ名：{{pc.meisyo}}</label>
            <label class="ml-3">接続先：{{pc.setuzokusaki}}</label>
            <label class="ml-3">MACアドレス：{{pc.macaddress}}</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mt-3">
          <label class="font-weight-bold">持込工具類</label>
        </div>
        <div class="col-md-8 mt-3" [hidden]="!noTool">
          <label class="ml-3">持込工具類なし</label>
        </div>
      </div>
      <div *ngFor="let tool of toolList; let i = index">
      <div class="form-row">
        <div class="col-md-2 ml-3">
          <label>持込工具類{{i + 1}}</label>
        </div>
        <div class="col-md-8">
          <label class="ml-3">{{tool.meisyo}}</label>
        </div>
      </div>
    </div>
      <div class="form-row">
        <div class="col-md-4 mt-3 mb-3">
          <label class="font-weight-bold">持込カメラ・デジタルカメラ</label>
        </div>
        <div class="col-md-6 mt-3 mb-3">
          <label class="ml-3">{{camera}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label class="font-weight-bold">持込携帯電話・スマートフォン</label>
        </div>
        <div class="col-md-6 mb-3">
          <label class="ml-3">{{phone}}</label>
        </div>
      </div>

      <div [hidden]="syokaiData[0].status === '01'">
        <div class="form-row">
          <div class="col-md-4 mb-3">
            <label class="font-weight-bold">特別許可申請</label>
          </div>
          <div class="col-md-6 mb-3">
            <label class="ml-3 font-weight-bold text-danger">{{special}}</label>
          </div>
        </div>
        <div class="form-row" [hidden]="!noSpecial">
          <div class="col-md-4 mb-3">
            <label class="font-weight-bold">特別許可理由</label>
          </div>
          <div class="col-md-6 mb-3">
            <label class="ml-3 font-weight-bold text-danger">{{getSpecialReason()}}</label>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-2">
          <label class="font-weight-bold">その他持込物</label>
        </div>
        <div class="col-md-8" [hidden]="!noOther">
          <label class="ml-3">その他持込物なし</label>
        </div>
      </div>
      <div *ngFor="let other of otherList; let i = index">
      <div class="form-row">
        <div class="col-md-2 ml-3">
          <label>その他持込物{{i + 1}}</label>
        </div>
        <div class="col-md-8">
          <label class="ml-3">{{other.meisyo}}</label>
        </div>
      </div>
    </div>
    </form>
    <form *ngIf="kyakka" class="mx-3" [formGroup]="kyakkaForm">
      <div class="mb-3">
        <label class="form-label font-weight-bold">却下理由</label>
        <div class="advice">※却下する場合のみ記入してください。</div>
        <textarea class="form-control col-md-8" [formControl]="kyakkaForm.controls.reason"></textarea>
      </div>
    </form>
    <div class="center">
      <button class="btn btn-primary mr-3" [hidden]="!syonin" type="submit" name="btnSyonin" (click)="btnSyoninClick()">承　認</button>
      <button class="btn btn-danger mr-3" [hidden]="!kyakka" type="submit" name="btnKyakka" (click)="btnKyakkaClick()">却　下</button>
      <button class="btn btn-outline-danger mr-3" [hidden]="!kaijo" type="submit" name="btnKaijo" (click)="btnKaijoClick()">解　除</button>
      <button class="btn btn-secondary ml-3" type="submit" name="btnBack" (click)="btnBackClick()">戻　る</button>
      <button class="btn btn-success ml-3" type="submit" name="btnUketuke" (click)="btnUketukeClick()" *ngIf="isKeibi()">受　付</button>
    </div>
  </div>
</body>
