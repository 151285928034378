<body>
  <app-menu>
    <div class="container">
      <p class="h2 mb-5 title">入退館受付</p>
      <form class="needs-validation ml-3 mr-3 mb-3" [formGroup]="pageForm">
        <div class="form-row">
          <div class="col-md-12 mb-3">
            <input type="text" class="form-control" placeholder="QRコードを読み取ってください" [formControl]="pageForm.controls.sinseiNo">
          </div>
        </div>
        <div class="center">
          <button class="btn btn-primary" type="submit" name="btnRead" (click)="btnReadClick()">詳　細</button>
        </div>
      </form>
    </div>
  </app-menu>
</body>
