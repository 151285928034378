<body>
  <app-menu>
    <div class="container">
      <p class="h2 mb-3 title">Newsエリア</p>
      <div *ngFor="let obj of news">
        <p class="h5 ml-2 mr-2 mb-2 textBlue">{{obj}}</p>
      </div>
      <p class="h2 mt-5 mb-3 title">あなたの承認待ちの申請</p>
      <div *ngFor="let obj2 of sinsei">
        <p class="h5 ml-2 mr-2 mb-2 textBlue">{{obj2}}</p>
      </div>
    </div>
  </app-menu>
</body>
