import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DbConnectService } from '@service/db-connect.service';
import { AwsConnectService } from '@service/aws-connect.service';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  pageForm: FormGroup;
  failedCount: number;
  errorMessage: string = 'ログインIDまたはメールアドレスが間違っているか、期限切れにより削除された可能性があります。\n期限切れの場合は担当者へ再度マスタ登録の申請をお願いいたします。'
  + '\n\n※アカウントの有効期限は登録してから最初の3月31日を迎えるまでとなります。'
  + '\nただし、3月に登録した場合は翌年の3月31日まで';

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dbConnect: DbConnectService,
    private awsService: AwsConnectService,
  ) {
    this.pageForm = this.fb.group({
      id: '',
      email: ''
    });
  }

  ngOnInit(): void {
    this.failedCount = 0;
  }

  btnSendClick(): void {
    const prmList = [{
      key: ':pUSER_ID',
      value: this.pageForm.controls.id.value
    },
    {
      key: ':pEMAIL',
      value: this.pageForm.controls.email.value
    }];

    this.awsService.isEnableUser(this.pageForm.controls.id.value)
    .then(res => {
      if(!res){
        this.failedCount++;
        alert(this.errorMessage);
        return;
      }

      this.dbConnect.checkData(prmList).then(result => {
        if (result === null || result[0].count == 0) {
          this.failedCount++
          alert(this.errorMessage);
          return;
        }
        this.awsService.passwordReset(this.pageForm.controls.id.value).then(result2 => {
          if (result2 != null)
          {
            return;
          }
          this.router.navigate(['/changePassword']);
        });
      });
    })
  }

  btnBackClick(): void {
    this.router.navigate(['/login']);
  }

}
