import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { DbConnectService } from '@service/db-connect.service';

@Injectable({
  providedIn: 'root'
})
export class AsyncValidatorService {

  constructor(
    private dbConnect: DbConnectService
    ) { }

  existEmail(): AsyncValidatorFn {
    return (control: AbstractControl):Promise<ValidationErrors|null> => {
      return this.dbConnect.isExistUser(control.value).then(res => {
          return res ? {existEmail: true} : null
        })
        .catch(err => null)
    }
  }
}
