import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AwsConnectService } from '@service/aws-connect.service';
import { DbConnectService } from '@service/db-connect.service';
import { UserDataService } from '@service/user-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  top: boolean;
  ninsyo: boolean;
  userId: string;
  returnUrl: string;

  errorMessage: string = 'ログインID、パスワードが間違っているか、期限切れにより削除された可能性があります。\n期限切れの場合は担当者へ再度マスタ登録の申請をお願いいたします。'
  + '\n\n※アカウントの有効期限は登録してから最初の3月31日を迎えるまでとなります。'
  + '\nただし、3月に登録した場合は翌年の3月31日まで';

  constructor(
    private router: Router,
    private AwsConnect: AwsConnectService,
    private dbConnect: DbConnectService,
    private userData: UserDataService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.top = true;
    this.ninsyo = false;
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
  }

  changeTop(session: any): void {

    // ログイン失敗
    if(!session.changeFlg){
      this.AwsConnect.userKbn = '';
      alert(this.errorMessage);
      return;
    }

    this.userData.SetUserKbn(session.userId);
    const userKbn = this.userData.keep.userKbn;
    this.AwsConnect.userKbn = this.userData.keep.userKbn;

    // 初回ログイン時はパスワード変更へ
    if (this.AwsConnect.UserDataConfirm != null) {
      if (this.AwsConnect.UserDataConfirm.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.AwsConnect.pwChgFlg = false;
        this.router.navigate(['/changePassword']);
        return;
      }
    }

    if (userKbn === '00' || userKbn === '10') {
      // TOPページへ（管理者・社員）
      const prmList = [{
        key: ':pUSER_ID',
        value: session.userId
      }];
      this.dbConnect.CmdSql('login', '1', prmList).then(result => {
        this.userData.SetUserData(result[0]);

        const prmList2 = [{
          key: ':pKJN_NO',
          value: this.userData.keep.kjnNo
        }, {
          key: ':pLOG_FLG',
          value: '1'
        }];
        this.dbConnect.CmdSql('common', '3', prmList2).then(result2 => {
          this.router.navigate([this.returnUrl]);
        });
      });
    //} else if (userKbn === '20' || userKbn === '30' || userKbn === '41' || userKbn === '42' || userKbn === '43' || userKbn === '44') { //★警備員は19
    } else if (userKbn === '20') {
      // SMS認証へ（社外・警備員）
      this.userId = session.userId;
      this.top = false;
      this.ninsyo = true;
    }
    
  }

  changeNinsyo(session: any): void {

    //認証失敗
    if(!session.changeFlg){
      alert('認証に失敗しました。');
      return;
    }

    // 認証成功
    // Topページへ
    const prmList = [{
      key: ':pUSER_ID',
      value: session.userId
    }];
    this.dbConnect.CmdSql('login', '1', prmList).then(result => {
      this.userData.SetUserData(result[0]);

      const prmList2 = [{
        key: ':pKJN_NO',
        value: this.userData.keep.kjnNo
      }, {
        key: ':pLOG_FLG',
        value: '1'
      }];
      this.dbConnect.CmdSql('common', '3', prmList2).then(result2 => {
        this.router.navigate([this.returnUrl]);
      });
    });
  }

  changeForgottenPassword(changeFlg: boolean): void {
    if (changeFlg) {
    } else {
      // TOPページへ
      this.top = true;
      this.ninsyo = false;
    }
  }

  private isEnableUser(userId: string): Promise<boolean>{
    const limitDate = this.getLimitDate();
    const prmList = [{
      key: ':pUSER_ID',
      value: userId
    }];
    return this.dbConnect.CmdSql('login', '2', prmList).then(result => {
      if(result === null){
        return false;
      }

      if(new Date(result[0].nsm001_ins_dat) >= limitDate){
        return true;
      }else{
        return false;
      }
    })
  }

  //現時点でのユーザの有効期限日の次の日を返す
  private getLimitDate(): Date{
    const now = new Date();
    let targetDate;
    if(now.getMonth() >= 3){
        targetDate = new Date(now.getFullYear(), 2, 1);
    }else{
        targetDate = new Date(now.getFullYear() - 1, 2, 1);
    }
    return targetDate;
  }

}
