<body>
  <div class="container">
    <p class="h2 mb-5 title">入館申請登録</p>
    <form class="needs-validation ml-3 mr-3 mb-3" [formGroup]="pageForm">
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <button class="btn btn-primary" type="submit" name="btnCopy" (click)="btnCopyClick()">前回の申請をコピーする</button>
        </div>
      </div>
      <label class="font-weight-bold">入館予定日時</label>
      <div class="form-row">
        <ng-container
          *ngIf="pageForm.controls.nyukanYoteiYmd.hasError('required') && (pageForm.controls.nyukanYoteiYmd.touched || pageForm.controls.nyukanYoteiYmd.dirty)">
          <div class="col-md-12">
            <span class="textRed">※入館予定日を入力してください。</span>
          </div>
        </ng-container>
        <ng-container
          *ngIf="pageForm.controls.nyukanYoteiTime.hasError('required') && (pageForm.controls.nyukanYoteiTime.touched || pageForm.controls.nyukanYoteiTime.dirty)">
          <div class="col-md-12">
            <span class="textRed">※入館予定時間を入力してください。</span>
          </div>
        </ng-container>
        <ng-container
          *ngIf="pageForm.errors?.admissionDate && (pageForm.controls.nyukanYoteiYmd.touched || pageForm.controls.nyukanYoteiYmd.dirty
          || pageForm.controls.nyukanYoteiTime.touched || pageForm.controls.nyukanYoteiTime.dirty)">
          <div class="col-md-12">
            <span class="textRed">※現在日時以前の日時は指定できません。</span>
          </div>
        </ng-container>
        <div class="col-md-6 mb-3">
          <input type="date" class="form-control" [formControl]="pageForm.controls.nyukanYoteiYmd"
            (change)="cmbNyukanYoteiYmdChange()">
        </div>
        <div class="col-md-6 mb-3">
          <input type="time" class="form-control" [formControl]="pageForm.controls.nyukanYoteiTime">
        </div>
      </div>
      <label class="font-weight-bold">退館予定日時</label>
      <div class="form-row">
        <ng-container
          *ngIf="pageForm.controls.taikanYoteiYmd.hasError('required') && (pageForm.controls.taikanYoteiYmd.touched || pageForm.controls.taikanYoteiYmd.dirty)">
          <div class="col-md-12">
            <span class="textRed">※退館予定日を入力してください。</span>
          </div>
        </ng-container>
        <ng-container
          *ngIf="pageForm.controls.taikanYoteiTime.hasError('required') && (pageForm.controls.taikanYoteiTime.touched || pageForm.controls.taikanYoteiTime.dirty)">
          <div class="col-md-12">
            <span class="textRed">※退館予定時間を入力してください。</span>
          </div>
        </ng-container>
        <ng-container
          *ngIf="pageForm.errors?.exitDate && (pageForm.controls.taikanYoteiYmd.touched || pageForm.controls.taikanYoteiYmd.dirty
          || pageForm.controls.taikanYoteiTime.touched || pageForm.controls.taikanYoteiTime.dirty)">
          <div class="col-md-12">
            <span class="textRed">※入館日時以前の日時は指定できません。</span>
          </div>
        </ng-container>
        <ng-container
          *ngIf="pageForm.errors?.period && (pageForm.controls.taikanYoteiYmd.touched || pageForm.controls.taikanYoteiYmd.dirty
          || pageForm.controls.taikanYoteiTime.touched || pageForm.controls.taikanYoteiTime.dirty)">
          <div class="col-md-12">
            <span class="textRed">※入館日から１週間以上の期間申請はできません。</span>
          </div>
        </ng-container>
        <div class="col-md-6 mb-3">
          <input type="date" class="form-control" [formControl]="pageForm.controls.taikanYoteiYmd">
        </div>
        <div class="col-md-6 mb-3">
          <input type="time" class="form-control" [formControl]="pageForm.controls.taikanYoteiTime">
        </div>
      </div> 
      <label class="font-weight-bold">団体名</label>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <ng-container
            *ngIf="pageForm.controls.group.hasError('required') && (pageForm.controls.group.touched || pageForm.controls.group.dirty)">
            <div class="col-md-12">
              <span class="textRed">※団体名を入力してください。</span>
            </div>
          </ng-container>
          <input type="text" class="form-control" [formControl]="pageForm.controls.group">
        </div>
      </div>
      <label class="font-weight-bold">入館目的</label>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <ng-container
            *ngIf="pageForm.controls.mokuteki.hasError('required') && (pageForm.controls.mokuteki.touched || pageForm.controls.mokuteki.dirty)">
            <div class="col-md-12">
              <span class="textRed">※入館目的を選択してください。</span>
            </div>
          </ng-container>
          <!-- <input type="text" class="form-control" [formControl]="pageForm.controls.mokuteki"> -->
          <select class="form-control" [formControl]="pageForm.controls.mokuteki"
          [class.input-invalid]="pageForm.controls.mokuteki.invalid && (pageForm.controls.mokuteki.touched || pageForm.controls.mokuteki.dirty)">
            <option *ngFor="let mokuteki of mokutekiList" [ngValue]="mokuteki">{{mokuteki}}</option>
          </select>
        </div>
      </div>
      <div *ngIf="pageForm.controls.mokuteki.valid" class="form-row">
        <ng-container
        *ngIf="pageForm.controls.mokutekiSyosai.hasError('required') && (pageForm.controls.mokutekiSyosai.touched || pageForm.controls.mokutekiSyosai.dirty)">
          <div class="col-md-12">
            <span class="textRed">※入館目的の詳細を入力してください。</span>
          </div>
        </ng-container>
        <div class="col-md-12 mb-3">
          <label class="col-md-12 font-weight-normal">詳細</label>
          <textarea class="form-control" [formControl]="pageForm.controls.mokutekiSyosai"
          [class.input-invalid]="pageForm.controls.mokutekiSyosai.invalid && (pageForm.controls.mokutekiSyosai.touched || pageForm.controls.mokutekiSyosai.dirty)"></textarea>
        </div>
      </div>
      <label class="font-weight-bold">入館者情報</label>
      <div formArrayName="nyukansyas">
        <div *ngFor="let nyukansya of nyukansyas.controls; let i = index" [formGroupName]="i">
          <div class="form-row">
            <div class="col-md-12">
              <label>入館者{{i + 1}}</label>
              <button [hidden]="nyukansyas.controls.length === 1" type="button" class="btn btn-outline-success btn-sm ml-3 row-delete" name="btnNyukansyaDel"
                (click)="btnNyukansyaDelClick(i)">削除</button>
            </div>
            <div class="col-md-12 mb-3">
              <label>氏名（カナ）</label>
              <ng-container
                *ngIf="nyukansya.controls.nameKnSei.hasError('katakana') && (nyukansya.controls.nameKnSei.touched || nyukansya.controls.nameKnSei.dirty)">
                <div class="col-md-12">
                  <span class="textRed">※全角カナで入力してください。</span>
                </div>
              </ng-container>
              <input type="text" class="form-control mb-3" placeholder="セイ" formControlName="nameKnSei">
              <ng-container
                *ngIf="nyukansya.controls.nameKnMei.hasError('katakana') && (nyukansya.controls.nameKnMei.touched || nyukansya.controls.nameKnMei.dirty)">
                <div class="col-md-12">
                  <span class="textRed">※全角カナで入力してください。</span>
                </div>
              </ng-container>
              <input type="text" class="form-control" placeholder="メイ" formControlName="nameKnMei">
            </div>
            <div class="col-md-12 mb-3">
              <label>氏名（漢字）</label>
              <input type="text" class="form-control mb-3" placeholder="姓" formControlName="nameKjSei">
              <input type="text" class="form-control" placeholder="名" formControlName="nameKjMei">
            </div>
          </div>
        </div>
        <div class="mb-3">
          <button type="button" class="btn btn-success btn-sm" name="btnNyukansyaAdd"
            (click)="btnNyukansyaAddClick()">追加</button>
        </div>
      </div>
      <label class="font-weight-bold">搬入物</label>
      <div formArrayName="hannyus">
        <div *ngFor="let hannyu of hannyus.controls; let i = index" [formGroupName]="i">
          <div class="form-row">
            <div class="col-md-12">
              <label>搬入物{{i + 1}}</label>
              <button [hidden]="hannyus.controls.length === 1" type="button" class="btn btn-outline-success btn-sm ml-3 row-delete" name="btnHannyuDel"
                (click)="btnHannyuDelClick(i)">削除</button>
              <input type="text" class="form-control" formControlName="hannyu">
            </div>
            <div class="col-md-12">
              <span class="advice">※当社敷地内に据え置きの機材や什器等を搬入する場合は当項目へ入力してください。</span>
            </div>
            <div class="col-md-12">
              <span class="advice">※作業指示書や筆記用具等、当日中に持ち帰りが想定される物品は持込物へ入力してください。</span>
            </div>
            <div class="col-md-12 mb-3">
              <span class="advice">※持込作業PCや工具類については下記の所定欄に入力してください。</span>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <button type="button" class="btn btn-success btn-sm" name="btnHannyuAdd"
            (click)="btnHannyuAddClick()">追加</button>
        </div>
      </div>
      <label class="font-weight-bold">搬出物</label>
      <div formArrayName="hansyutus">
        <div *ngFor="let hansyutu of hansyutus.controls; let i = index" [formGroupName]="i">
          <div class="form-row">
            <div class="col-md-12">
              <label>搬出物{{i + 1}}</label>
              <button [hidden]="hansyutus.controls.length === 1" type="button" class="btn btn-outline-success btn-sm ml-3 row-delete" name="btnHansyutuDel"
                (click)="btnHansyutuDelClick(i)">削除</button>
              <input type="text" class="form-control" formControlName="hansyutu">
            </div>
            <div class="col-md-12">
              <span class="advice">※当社敷地内から据え置きの機材や什器等を搬出する場合は当項目へ入力してください。</span>
            </div>
            <div class="col-md-12">
              <span class="advice">※作業指示書や筆記用具等、当日中に持ち帰りが想定される物品は持込物へ入力してください。</span>
            </div>
            <div class="col-md-12 mb-3">
              <span class="advice">※持込作業PCや工具類については下記の所定欄に入力してください。</span>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <button type="button" class="btn btn-success btn-sm" name="btnHansyutuAdd"
            (click)="btnHansyutuAddClick()">追加</button>
        </div>
      </div>
      <label class="font-weight-bold">持込作業PC</label>
      <div formArrayName="pcs">
        <div *ngFor="let pc of pcs.controls; let i = index" [formGroupName]="i">
          <div class="form-row">
            <div class="col-md-12">
              <label>持込作業PC{{i + 1}}</label>
              <button [hidden]="pcs.controls.length === 1" type="button" class="btn btn-outline-success btn-sm ml-3 row-delete" name="btnPcDel"
                (click)="btnPcDelClick(i)">削除</button>
            </div>
            <div class="col-md-12">
              <label>管理番号・コンピュータ名</label>
              <input type="text" class="form-control" formControlName="pcName">
            </div>
            <div class="col-md-12 mb-3">
              <span class="advice">※持込物検査時に提示頂きます。また、個人所有のPCの持込はできません。</span>
            </div>
            <div class="col-md-12">
              <label>接続先</label>
              <input type="text" class="form-control" formControlName="setuzokusaki">
            </div>
            <div class="col-md-12 mb-3">
              <span class="advice">※Wi-Fi等通信モバイルを使用する場合は接続先を入力してください。</span>
            </div>
            <div class="col-md-12">
              <label>MACアドレス</label>
              <input type="text" class="form-control" formControlName="macaddress">
            </div>
            <div class="col-md-12 mb-3">
              <span class="advice">※当社キッティングルームは利用する場合はMACアドレスを入力してください。</span>
            </div>
            <div class="col-md-12">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" formControlName="securityPatch">
                <label class="form-check-label">サポート対象のOSで、最新のセキュリティパッチが適用されています。</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" formControlName="patternFile">
                <label class="form-check-label">ウィルス対策ソフトがインストールされ、最新のパターンファイルが適用されています。</label>
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <span class="advice">※上記に適合しないPCの持込はできません。</span>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <button type="button" class="btn btn-success btn-sm" name="btnPcAdd" (click)="btnPcAddClick()">追加</button>
        </div>
      </div>
      <label class="font-weight-bold">持込工具類</label>
      <div formArrayName="tools">
        <div *ngFor="let tool of tools.controls; let i = index" [formGroupName]="i">
          <div class="form-row">
            <div class="col-md-12 mb-3">
              <label>持込工具類{{i + 1}}</label>
              <button [hidden]="tools.controls.length === 1" type="button" class="btn btn-outline-success btn-sm ml-3 row-delete" name="btnToolDel"
                (click)="btnToolDelClick(i)">削除</button>
              <input type="text" class="form-control" formControlName="tool">
            </div>
          </div>
        </div>
        <div class="mb-3">
          <button type="button" class="btn btn-success btn-sm" name="btnToolAdd" (click)="btnToolAddClick()">追加</button>
        </div>
      </div>
      <label class="font-weight-bold">持込カメラ・デジタルカメラ</label>
      <div class="col-md-12">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" [formControl]="pageForm.controls.camera">
          <label class="form-check-label">持ち込む</label>
        </div>
      </div>
      <div class="col-md-12">
        <span class="advice" [hidden]="camera">※持込禁止のエリアへ入室する場合は選択しないでください。</span>
      </div>
      <div class="col-md-12 mb-3">
        <span class="advice" [hidden]="phone">※詳細な確認が必要な場合については社内担当者へご相談ください。</span>
      </div>
      <label class="font-weight-bold">持込携帯電話・スマートフォン</label>
      <div class="col-md-12">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" [formControl]="pageForm.controls.phone">
          <label class="form-check-label">持ち込む</label>
        </div>
      </div>
      <div class="col-md-12">
        <span class="advice" [hidden]="camera">※持込禁止のエリアへ入室する場合は選択しないでください。</span>
      </div>
      <div class="col-md-12 mb-3">
        <span class="advice" [hidden]="phone">※詳細な確認が必要な場合については社内担当者へご相談ください。</span>
      </div>
      <label class="font-weight-bold">その他持込物</label>
      <div formArrayName="others">
        <div *ngFor="let other of others.controls; let i = index" [formGroupName]="i">
          <div class="form-row">
            <div class="col-md-12 mb-3">
              <label>持込物{{i + 1}}</label>
              <button [hidden]="others.controls.length === 1" type="button" class="btn btn-outline-success btn-sm ml-3 row-delete" name="btnOtherDel"
                (click)="btnOtherDelClick(i)">削除</button>
              <input type="text" class="form-control" formControlName="other">
            </div>
          </div>
        </div>
        <div class="mb-3">
          <button type="button" class="btn btn-success btn-sm" name="btnOtherAdd" (click)="btnOtherAddClick()">追加</button>
        </div>
      </div>
      <label class="font-weight-bold">担当者メールアドレス(※弊社担当者のメールアドレスをご入力ください。)</label>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <ng-container
            *ngIf="pageForm.controls.tantomail.hasError('required') && (pageForm.controls.tantomail.touched || pageForm.controls.tantomail.dirty)">
            <div class="col-md-12">
              <span class="textRed">※弊社担当者のメールアドレスをご入力ください。</span>
            </div>
          </ng-container>
          <div class="col-md-12" [hidden]="!mailFlg">
            <span class="textRed">※入力されたメールアドレスの担当者は存在しません。今一度ご確認ください。</span>
          </div>
          <input type="text" class="form-control" [formControl]="pageForm.controls.tantomail" (blur)="checkSyain()">
        </div>
      </div>
    </form>
    <ng-container
      *ngIf="pageForm.invalid && touchFlg">
      <span class="textRed">※入力内容が正しくありません。入力内容をご確認ください。</span>
    </ng-container>
    <div class="center">
      <button class="btn btn-info" type="submit" name="btnNext" (click)="btnNextClick()">次　へ</button>
    </div>
  </div>
</body>
