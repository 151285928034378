import { Injectable } from '@angular/core';
import { MasterData } from '@class/master-data';
import { AwsConnectService } from './aws-connect.service';
import { DbConnectService } from './db-connect.service';
import { NewsService, newsCategory } from './news.service';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  constructor(
    private dbConnect: DbConnectService,
    private userData: UserDataService,
    private aws: AwsConnectService,
    private news: NewsService
  ) { }

  async registerMasterData(data: MasterData): Promise<void>{
    let workData:MasterData = Object.assign({}, data);

    let userId;
    if(workData.syubetu == '社員'){
      //「10xxxx」の書式
      userId = '10' + ('000' + workData.syainNo).slice(-4);
    }else{
      userId = await this.dbConnect.CmdSql('masterToroku', '2', []).then(res => res[0].user_id);
    }

    //DBにユーザ作成
    {
      if(workData.syubetu == '社員'){
      // 社員の担当者は社員自身
      workData.kjnNoMain = userId;
      }

      if(workData.syubetu == '警備員'){
        workData.role = '5';
      }
  
      const dbParams = [{
          key:':pUSER_ID',
          value:userId
        },{
          key:':pGROUP',
          value:workData.group
        },{
          key:':pNAME_KN',
          value:workData.nameKn
        },{
          key:':pNAME_KJ',
          value:workData.nameKj
        },{
          key:':pTEL_NO',
          value:workData.telNo
        },{
          key:':pEMAIL',
          value:workData.email
        },{
          key:':pINS_KJN_NO',
          value:this.userData.keep.kjnNo
        },{
          key:':pUPD_KJN_NO',
          value:this.userData.keep.kjnNo
        },{
          key:':pSYAIN_NO',
          value:workData.syainNo
        },{
          key:':pROLE',
          value:workData.role
        }];
  
      let sql;
      if(workData.syubetu == '社員'){
        sql = '3';
      }else if(workData.syubetu == '警備員'){
        sql = '4';
      }else{
        sql = '5';
      }
      await this.dbConnect.CmdStored('masterToroku', sql, dbParams);
    }

    const groupAndName = `${workData.group}　${workData.nameKj}`;

    //cognitoにユーザ作成
    {
      let telNo;
      if(workData.syubetu !== '社員'){
        telNo = `+81${workData.telNo}`;
      }else{
        telNo = '';
      }
  
      const cognitoParams = {
        username: userId,
        email: workData.email,
        phoneNumber: telNo,
        nameKj:groupAndName
      };

      await this.aws.createUser(cognitoParams);
    }

    //ニュース登録
    {
      const params = [{
        key: ':pUSER_ID',
        value: userId
      }];
      const KjnNo = await this.dbConnect.CmdSql('login', '1', params).then(res => res[0].nsm001_kjn_no);
      await this.news.registerNews(KjnNo, newsCategory.masterToroku, groupAndName);
    }
  }
}
