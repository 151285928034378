import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MotikomiData } from '@class/motikomi-data';
import { SyokaiData } from '@class/syokai-data';
import { SinseiData, SpArea, PcArea, DcArea } from '@class/sinsei-data';
import { NyukansyaData } from '@class/nyukansya-data';
import { DbConnectService } from '@service/db-connect.service';
import { UserDataService } from '@service/user-data.service';
import { S3fileService } from '@service/s3file.service';

import { ModalService } from '@service/modal.service'
import { SpinnerModalComponent } from '@spinner/spinner-modal.component';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

import { AreaData } from '@class/area-data';
import { MasterData } from '@class/master-data';
import { CustomValidator } from '@utility/custom-validator';

import { Router } from '@angular/router';

@Component({
  selector: 'app-sinsei-syokai-kakunin',
  templateUrl: './sinsei-syokai-kakunin.component.html',
  styleUrls: ['../sinsei-syokai.component.css']
})
export class SinseiSyokaiKakuninComponent implements OnInit {
  @Input() syokaiData: SyokaiData[];
  @Input() nyukansyaData: NyukansyaData[];
  @Input() motikomiData: MotikomiData[];
  @Output() session = new EventEmitter();
  @Output() sinseiData = new EventEmitter();

  hannyuList: MotikomiData[];
  hansyutuList: MotikomiData[];
  pcList: MotikomiData[];
  toolList: MotikomiData[];
  otherList: MotikomiData[];

  uploadList: string[];
  upload: boolean;

  nyukanYoteiDat: string;
  taikanYoteiDat: string;
  mainTanto: string;
  //subTanto: string; //★削除
  status: string;
  camera: string;
  phone: string;
  special: string;

  noUpload: boolean;
  noHannyu: boolean;
  noHansyutu: boolean;
  noPc: boolean;
  noTool: boolean;
  noOther: boolean;
  noCamera: boolean;
  noPhone: boolean;
  syonin: boolean;
  kyakka: boolean;
  noSpecial: boolean;
  dojo: boolean;
  attendTanto: string;

  kyakkaForm: FormGroup = this.fb.group({
    reason: ['', Validators.required]
  });

  pageForm: FormGroup;
  spAreaList: AreaData[];
  pcAreaList: AreaData[];
  dcAreaList: AreaData[];
  sp: boolean;
  pc: boolean;
  dc: boolean;
  item: SinseiData;
  touchFlg: boolean;
  itemattend: MasterData;
  kaijo : boolean;

  issyainnoDisabled = true;
  isreasonDisabled = true;
  isextraDisabled = true;
  
  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private dbConnect: DbConnectService,
    private userData: UserDataService,
    private s3file: S3fileService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
    private fb: FormBuilder
  ) {
    this.hannyuList = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];

    this.hansyutuList = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];

    this.pcList = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];

    this.toolList = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];

    this.otherList = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];

    this.pageForm = this.fb.group({
      nyukanArea: ['', [Validators.required]],
      spAreas: new FormArray([]),
      pcAreas: new FormArray([]),
      dcAreas: new FormArray([]),
      specialflg: false,
      specialreason: [{value: '', disabled: this.isreasonDisabled}, [Validators.required]],
      specialextra: [{value: '', disabled: this.isextraDisabled}, [Validators.required]],
      syainNo: [{value: '', disabled: this.issyainnoDisabled}, Validators.compose([Validators.required, CustomValidator.numeric])],
      syainNoCheck: null,
      dojo: true
    });
   
    this.item = {
      nyukanYoteiYmd: '',
      nyukanYoteiTime: '',
      taikanYoteiYmd: '',
      taikanYoteiTime: '',
      group: '',
      mokuteki: '',
      mokutekiSyosai: '',
      nyukansyas: [{ seqNo: '', nameKn: '', nameKj: '' }],
      hannyus: [{ seqNo: '', meisyo: '' }],
      hansyutus: [{ seqNo: '', meisyo: '' }],
      spAreas: [{ no: '', meisyo: '', nyukanFlg: false }],
      pcAreas: [{ no: '', meisyo: '', nyukanFlg: false }],
      dcAreas: [{ no: '', meisyo: '', nyukanFlg: false }],
      pcs: [{ seqNo: '', pcName: '', setuzokusaki: '', macaddress: '' }],
      tools: [{ seqNo: '', meisyo: '' }],
      camera: false,
      phone: false,
      others: [{ seqNo: '', meisyo: '' }],
      mainGroup: '',
      mainNameKn: '',
      mainNameKj: '',
      mainTelNo: '',
      mainEmail: '',
      subGroup: '',
      subNameKn: '',
      subNameKj: '',
      subTelNo: '',
      subEmail: ''
    };


    this.spAreaList = [{
      no: '',
      kbn: '',
      level: '',
      taisyo: '',
      meisyo: ''
    }];

    this.pcAreaList = [{
      no: '',
      kbn: '',
      level: '',
      taisyo: '',
      meisyo: ''
    }];

    this.dcAreaList = [{
      no: '',
      kbn: '',
      level: '',
      taisyo: '',
      meisyo: ''
    }];

    this.uploadList = [];

    this.itemattend = {
      syubetu: '',
      syainNo: '',
      role: '',
      roleDisp: '',
      group: '',
      nameKn: '',
      nameKj: '',
      telNo: '',
      email: '',
      kjnNoMain: '',
      syainNoMain: '',
      groupMain: '',
      nameKnMain: '',
      nameKjMain: '',
      telNoMain: '',
      emailMain: '',
      kjnNoSub: '',
      syainNoSub: '',
      groupSub: '',
      nameKnSub: '',
      nameKjSub: '',
      telNoSub: '',
      emailSub: ''
    };
  }

  ngOnInit(): void {
    this.noUpload = false;
    this.noHannyu = false;
    this.noHansyutu = false;
    this.noPc = false;
    this.noTool = false;
    this.noOther = false;
    this.syonin = false;
    this.kyakka = false;
    this.upload = false;
    this.touchFlg = false;
    this.kaijo = false;

    this.setAreaList(); 
  }


  setTouched(): void {
    this.touchFlg = true;
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

  async btnSyoninClick(): Promise<void> {

    this.setSinseiData();

    if(this.getNyukanArea(this.item.spAreas) === '入館なし' &&
       this.getNyukanArea(this.item.pcAreas) === '入館なし' &&
       this.getNyukanArea(this.item.dcAreas) === '入館なし' &&
       this.syokaiData[0].status === '01'){
      alert('入館場所を選択してください。');
      return;
    }

    this.setTouched();
    if(this.pageForm.invalid){
      alert('入力項目に不備があります。確認してください。');
      return;
    }

  if(this.pageForm.controls.specialflg.value){
    if(this.pageForm.controls.specialreason.value === ''){
        alert('特別申請許可理由を選択してください');
        return;
    }
    if(this.pageForm.controls.specialreason.value === '9' && this.pageForm.controls.specialextra.value === ''){
        alert('特別申請許可理由を入力してください');
        return;
    }
  }


    if (!confirm('承認します。よろしいですか？')) {
      return;
    }

    let sta = '0' + (Number(this.syokaiData[0].status) + 1).toString();
    //担当者かつステータスが「01」の場合、一気にDS部次長承認とする
    if(this.userData.keep.userKbn === '10' && this.syokaiData[0].status === '01'){
      sta = '0' + (Number(this.syokaiData[0].status) + 2).toString();
    }

    if(this.syokaiData[0].status === '01'){
      await this.sinseiUpdate();
    }
    this.session.emit({changeFlg: true, syoninFlg: true, status: sta, sinseiNo: this.syokaiData[0].sinseiNo, uploadList: this.uploadList});
  }

  async sinseiUpdate(){
    //申請データの入館場所上書き
    const prmList = [{
      key: ':pSINSEI_NO',
      value: this.syokaiData[0].sinseiNo
    },
    {
      key: ':pAREA_SP',
      value: this.getNyukanArea(this.item.spAreas)
    },
    {
      key: ':pAREA_PC',
      value: this.getNyukanArea(this.item.pcAreas)
    },
    {
      key: ':pAREA_DC',
      value: this.getNyukanArea(this.item.dcAreas)
    },
    {
      key: ':pATTEND_KJN_NO',
      value: this.itemattend.kjnNoMain
    },
    {
      key: ':pUPD_KJN_NO',
      value: this.userData.keep.kjnNo
    },
    {
      key: ':pSPECIAL_FLG',
      value: this.pageForm.controls.specialflg.value === true ? 1 : 0
    },
    {
      key: ':pSPECIAL_REASON',
      value: this.pageForm.controls.specialreason.value
    }];

    //理由「その他」選択時
    let reason_extra = ""
    if(this.pageForm.controls.specialreason.value === "9"){
      reason_extra = this.pageForm.controls.specialextra.value
    }
    const prm = {
      key: ':pSPECIAL_EXTRA',
      value: reason_extra
    };
    prmList.push(prm);

    await this.dbConnect.CmdStored('sinseiSyokai', '12', prmList);
  }

  btnKyakkaClick(): void {
    if(this.kyakkaForm.invalid){
      alert('却下理由を入力してください');
      return;
    }
    
    if (!confirm('却下します。よろしいですか？')) {
      return;
    }

    this.session.emit({
      changeFlg: true, 
      userId: false, 
      sinseiNo: this.syokaiData[0].sinseiNo, 
      uploadList: this.uploadList, 
      reason: this.kyakkaForm.value.reason});
  }

  btnBackClick(): void {
    this.session.emit({changeFlg: false});
  }

  async setData(): Promise<void> {
    this.setMotikomiList();
    await this.setSyokaiData();

    this.pageForm.controls.nyukanArea.setValue('00');
    this.CmbNyukanAreaChange();
    this.pageForm.controls.specialflg.setValue(false);
  }

  setMotikomiList(): void {
    this.hannyuList.length = 0;
    this.hansyutuList.length = 0;
    this.pcList.length = 0;
    this.toolList.length = 0;
    this.otherList.length = 0;
    for (const obj of this.motikomiData) {
      if (obj.kbn.toString() === '1') {
        // 搬入物
        this.hannyuList.push(obj);
      } else if (obj.kbn.toString() === '2') {
        // 搬出物
        this.hansyutuList.push(obj);
      } else if (obj.kbn.toString() === '3') {
        // 持込作業PC
        this.pcList.push(obj);
      } else if (obj.kbn.toString() === '4') {
        // 持込工具類
        this.toolList.push(obj);
      } else if (obj.kbn.toString() === '5') {
        // その他持込物
        this.otherList.push(obj);
      }
    }
  }

  async setSyokaiData(): Promise<void> {
    this.nyukanYoteiDat = '';
    this.taikanYoteiDat = '';
    this.camera = '';
    this.phone = '';
    this.mainTanto = '';
    this.attendTanto = '';

    // 入館予定日時
    if (this.syokaiData[0].nyukanYoteiYmd !== '' && this.syokaiData[0].nyukanYoteiTime !== '') {
      const nyukanYoteiDat0 = this.syokaiData[0].nyukanYoteiYmd + ' ' + this.syokaiData[0].nyukanYoteiTime;
      this.nyukanYoteiDat = this.datePipe.transform(nyukanYoteiDat0, 'yyyy年MM月dd日')
        + '　' + this.datePipe.transform(nyukanYoteiDat0, 'HH時mm分');
    }

    // 退館予定日時
    if (this.syokaiData[0].taikanYoteiYmd !== '' && this.syokaiData[0].taikanYoteiTime !== '') {
      const taikanYoteiDat0 = this.syokaiData[0].taikanYoteiYmd + ' ' + this.syokaiData[0].taikanYoteiTime;
      this.taikanYoteiDat = this.datePipe.transform(taikanYoteiDat0, 'yyyy年MM月dd日')
        + '　' + this.datePipe.transform(taikanYoteiDat0, 'HH時mm分');
    }

    // 主担当・副担当
    const prmList2 = [{
      key: ':pMAIN_KJN_NO',
      value: this.syokaiData[0].mainKjnNo
    }];

    this.dbConnect.CmdSql('common', '7', prmList2).then(result2 => {
      if(result2 === undefined){
        alert('入館申請データに不備があるため検索画面へ戻ります。');
        this.btnBackClick();
        return;
      }

      this.mainTanto = result2[0].main_group + '　' + result2[0].main_name_kj + '　　内線：' + result2[0].main_tel_no;

      // 却下ボタンの表示・非表示
      this.kyakka = false;
      if (this.userData.keep.userKbn === '10') {
        if ((this.userData.keep.kjnNo === this.syokaiData[0].mainKjnNo && Number(this.syokaiData[0].status) < 5)
          || (this.userData.keep.role === '2' && this.syokaiData[0].status === '03')) {
          // 主担当者の場合(05未満の場合のみ[入館中、終了、却下は除く])
          // DS部部次長でステータスが03申請中（副担当者が承認済の申請）の場合
          this.kyakka = true;
        };
      };

      if (this.userData.keep.userKbn === '00') this.kyakka = true;


      // 承認ボタンの表示・非表示
      this.syonin = false;
      if (this.userData.keep.userKbn === '10') {
        if ((this.userData.keep.kjnNo === this.syokaiData[0].mainKjnNo && this.syokaiData[0].status === '01')
          || (this.userData.keep.role === '2' && this.syokaiData[0].status === '03')) {
          // 主担当者でステータスが01申請中（新規の申請）の場合
          // DS部部次長でステータスが03申請中（副担当者が承認済の申請）の場合
          this.syonin = true;
        }
      }

      // 申請書類添付ボタンの表示・非表示
      this.upload = false;
      if (this.userData.keep.userKbn === '10') {
        if (this.userData.keep.kjnNo === this.syokaiData[0].mainKjnNo && this.syokaiData[0].status === '01') {
          // 主担当者でステータスが01申請中（新規の申請）の場合
          this.upload = true;
        }
      }
    });

    // アテンド社員取得
    await this.getAttendSyain();
    
    // 特別許可申請
    this.special = this.syokaiData[0].specialFlg === true ? '特別許可申請あり' : '';
    this.noSpecial = this.syokaiData[0].specialFlg;

    this.noUpload = this.uploadList.length === 0 ? true : false;

    // 持込カメラ・デジタルカメラ
    this.camera = this.syokaiData[0].camera === true ? '持ち込みあり' : '持ち込みなし';
    this.noCamera = this.syokaiData[0].camera;

    // 持込携帯電話・スマートフォン
    this.phone = this.syokaiData[0].phone === true ? '持ち込みあり' : '持ち込みなし';
    this.noPhone = this.syokaiData[0].phone;

    // 搬入物
    if (this.hannyuList.length === 0) {
      this.noHannyu = true;
    } else {
      this.noHannyu = false;
    }

    // 搬出物
    if (this.hansyutuList.length === 0) {
      this.noHansyutu = true;
    } else {
      this.noHansyutu = false;
    }

    // 持込作業PC
    if (this.pcList.length === 0) {
      this.noPc = true;
    } else {
      this.noPc = false;
    }

    // 持込工具類
    if (this.toolList.length === 0) {
      this.noTool = true;
    } else {
      this.noTool = false;
    }

    // その他持込物
    if (this.otherList.length === 0) {
      this.noOther = true;
    } else {
      this.noOther = false;
    }

    //添付資料
    this.uploadList = this.syokaiData[0].uploadFile === null || this.syokaiData[0].uploadFile === "" ? [] : this.syokaiData[0].uploadFile.split('　');

    //解除ボタンの表示・非表示
    this.kaijo = false;
    if (this.userData.keep.userKbn === '10') {
      if ((this.userData.keep.role === '3' && this.syokaiData[0].status === '06')
        || (this.userData.keep.role === '2' && this.syokaiData[0].status === '06')) {
        // DS部部次長、DS部SOGでステータスが06終了の場合
        this.kaijo = true;
      };
    };
  }

  async onUpload(event){
    if (event.target.files.length <= 0) return;

    this.modalService.openModal(SpinnerModalComponent, this.viewContainerRef, []);

    const file = event.target.files[0];
    if (this.uploadList.find(upload => upload == file.name) != null) return;
    const fr = new FileReader();
    fr.onload = async (e) => {
      const filebase64 = e.target.result
      await this.s3file.uploadFile(file,filebase64,this.syokaiData[0].sinseiNo);
      this.uploadList.push(file.name);
      await this.updateUploadFile();
      event.target.value = '';
      this.modalService.closeModal();
      alert("アップロードが完了しました。");
        };
    fr.readAsDataURL(file);
  }

  async onDownload(i){
    this.modalService.openModal(SpinnerModalComponent, this.viewContainerRef, []);

    await this.s3file.downloadFile(this.uploadList[i], this.syokaiData[0].sinseiNo);
    
    this.modalService.closeModal();

  }

  async onDelete(i){
    this.modalService.openModal(SpinnerModalComponent, this.viewContainerRef, []);

    await this.s3file.deleteFile(this.uploadList[i], this.syokaiData[0].sinseiNo);
    this.uploadList.splice(i,1);
    await this.updateUploadFile();

    this.modalService.closeModal();

    alert("削除が完了しました。");
  }

  async updateUploadFile(){
    const prmList = [
    {
      key: ':pUPLOAD_FILE',
      value: this.uploadList.join("　")
    },
    {
      key: ':pUPD_KJN_NO',
      value: this.userData.keep.kjnNo
    },
    {
      key: ':pDEL_KJN_NO',
      value: this.userData.keep.kjnNo
    },
    {
      key: ':pSINSEI_NO',
      value: this.syokaiData[0].sinseiNo
    }];
    await this.dbConnect.CmdStored('sinseiSyokai', '8', prmList);
  }

  getNyukanArea(item: any): string {
    let areas = '';
    for (const area of item) {
      if (area.nyukanFlg) {
        //areas = areas + (areas === '' ? '' : '　') + area.meisyo;
        areas = areas + (areas === '' ? '' : '　') + area.meisyo.split('\\')[0];
      }
    }
    return areas === '' ? '入館なし' : areas;
  }

  // 入館場所
  CmbNyukanAreaChange(): void {
    // 選択場所のクリア
    for (let i = 0; i < this.spAreaList.length; i++) {
      this.spAreas.controls[i].setValue(false);
      //特別許可なし＆持ち込みありの場合、Level4、5は選択不可エリアとする
      if(!this.isSpecialCheck() && this.checkMotikomi()){
        if (this.spAreaList[i].level === '4' || this.spAreaList[i].level === '5') {
          this.spAreas.controls[i].disable();
        }
      }else{
        this.spAreas.controls[i].enable();
      }
    }
    for (let i = 0; i < this.pcAreaList.length; i++) {
      this.pcAreas.controls[i].setValue(false);
      //特別許可なし＆持ち込みありの場合、Level4、5は選択不可エリアとする
      if(!this.isSpecialCheck() && this.checkMotikomi()){
        if (this.pcAreaList[i].level === '4' || this.pcAreaList[i].level === '5') {
          this.pcAreas.controls[i].disable();
        }
      }else{
        this.pcAreas.controls[i].enable();
      }
    }
    for (let i = 0; i < this.dcAreaList.length; i++) {
      this.dcAreas.controls[i].setValue(false);
      //特別許可なし＆持ち込みありの場合、Level4、5は選択不可エリアとする
      if(!this.isSpecialCheck() && this.checkMotikomi()){
        if (this.dcAreaList[i].level === '4' || this.dcAreaList[i].level === '5') {
          this.dcAreas.controls[i].disable();
        }
      }else{
        this.dcAreas.controls[i].enable();
      }
    }

    if (this.pageForm.controls.nyukanArea.value === '01') {
      // SP・プロセシングセンター
      this.sp = true;
      this.pc = true;
      this.dc = false;
    } else if (this.pageForm.controls.nyukanArea.value === '02') {
      // GCC-DC
      this.sp = false;
      this.pc = false;
      this.dc = true;
    } else {
      this.sp = false;
      this.pc = false;
      this.dc = false;
    }
  }

  // 入館場所（SPビル）
  addSpArea(): void {
    this.spAreaList.forEach(() => {
      this.spAreas.push(new FormControl(false));
    });
  }

  get spAreas(): FormArray {
    return this.pageForm.controls.spAreas as FormArray;
  }

  // 入館場所（プロセシングセンター）
  addPcArea(): void {
    this.pcAreaList.forEach(() => {
      this.pcAreas.push(new FormControl(false));
    });
  }

  get pcAreas(): FormArray {
    return this.pageForm.controls.pcAreas as FormArray;
  }

  // 入館場所（GCC-DC）
  addDcArea(): void {
    this.dcAreaList.forEach(() => {
      this.dcAreas.push(new FormControl(false));
    });
  }
  
  get dcAreas(): FormArray {
    return this.pageForm.controls.dcAreas as FormArray;
  }

  // 入館場所チェックボックス　チェンジ
  chkAreaChange(): void {
    let count = 0;

    // 入館場所（SPビル）
    for (let i = 0; i < this.spAreaList.length; i++) {
      if (this.spAreaList[i].level === '4' || this.spAreaList[i].level === '5') {
        if (this.spAreas.controls[i].value) {
          count++;
        }
      }
    }

    // 入館場所（プロセシングセンター）
    for (let i = 0; i < this.pcAreaList.length; i++) {
      if (this.pcAreaList[i].level === '4' || this.pcAreaList[i].level === '5') {
        if (this.pcAreas.controls[i].value) {
          count++;
        }
      }
    }

    // 入館場所（GCC-DC）
    for (let i = 0; i < this.dcAreaList.length; i++) {
      if (this.dcAreaList[i].level === '4' || this.dcAreaList[i].level === '5') {
        if (this.dcAreas.controls[i].value) {
          count++;
        }
      }
    }

  }

  checkMotikomi(): boolean{
      //持ち込みチェック
      return (this.syokaiData[0].phone || this.syokaiData[0].camera);
  }

  setAreaList(): void {
    this.spAreaList = [];
    this.pcAreaList = [];
    this.dcAreaList = [];
    const prmList = [{
      key: '',
      value: ''
    }];
    this.dbConnect.CmdSql('sinseiToroku', '6', prmList).then(result => {
      for (const obj of result) {
        const areaData = new AreaData(
          obj.nsc001_no,
          obj.nsc001_kbn,
          obj.nsc001_level,
          obj.nsc001_taisyo,
          obj.nsc001_meisyo
        );
        const areaTaisyo = JSON.parse(obj.nsc001_taisyo);
        const userKbn = this.userData.keep.userKbn;
        if (areaTaisyo[userKbn] || userKbn === '00') {
          const kbn = obj.nsc001_kbn;
          if (kbn === '1') {
            this.spAreaList.push(areaData);
          } else if (kbn === '2') {
            this.pcAreaList.push(areaData);
          } else if (kbn === '3') {
            this.dcAreaList.push(areaData);
          } 
        }
      }
      this.addSpArea();
      this.addPcArea();
      this.addDcArea();
      this.chkAreaChange();
    });
  }


  setSinseiData(): void {
    // 入館場所（SPビル）
    const spArea0 = [];
    for (let i = 0; i < this.spAreaList.length; i++) {
      const spArea = new SpArea(
        this.spAreaList[i].no,
        this.spAreaList[i].meisyo,
        this.spAreas.controls[i].value
      );
      spArea0.push(spArea);
    }
    this.item.spAreas = spArea0;

    // 入館場所（プロセシングセンター）
    const pcArea0 = [];
    for (let i = 0; i < this.pcAreaList.length; i++) {
      const pcArea = new PcArea(
        this.pcAreaList[i].no,
        this.pcAreaList[i].meisyo,
        this.pcAreas.controls[i].value
      );
      pcArea0.push(pcArea);
    }
    this.item.pcAreas = pcArea0;

    // 入館場所（GCC-DC）
    const dcArea0 = [];
    for (let i = 0; i < this.dcAreaList.length; i++) {
      const dcArea = new DcArea(
        this.dcAreaList[i].no,
        this.dcAreaList[i].meisyo,
        this.dcAreas.controls[i].value
      );
      dcArea0.push(dcArea);
    }
    this.item.dcAreas = dcArea0;
  }

  ChkSpecialCheckChange(): void{
    if(this.pageForm.controls.specialflg.value){
      if(confirm("持ち込み不可エリアへの持ち込み許可を申請しますか？")){
        this.pageForm.controls.specialreason.enable();
        this.isreasonDisabled = false;
        this.CmbNyukanAreaChange();
      }else{
        this.pageForm.controls.specialflg.setValue(!this.pageForm.controls.specialflg.value);
      }
    }else{
      this.pageForm.controls.specialreason.disable();
      this.isreasonDisabled = true;
      this.CmbNyukanAreaChange();
    }
    
  }

  isSpecialCheck(): boolean{
    return this.pageForm.controls.specialflg.value;
  }

  getSpecialReason(): string{
    let response = "";
    if(this.syokaiData[0].specialReason === '1'){
      response = "作業完了報告書出力のため";
    }else if(this.syokaiData[0].specialReason === '2'){
      response = "テザリング利用のため";
    }else if(this.syokaiData[0].specialReason === '9'){
      response = this.syokaiData[0].specialExtra;
    }
    return response;
  }

  btnKensakuMainClick() : void{
    const syainNo = this.pageForm.controls.syainNo.value;

    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
    if (syainNo === null || syainNo === '') {
      return;
    }

    const prmList = [
      {
        key: ':pSYAIN_NO',
        value: syainNo
      }];

    this.dbConnect.CmdSql('masterToroku', '1', prmList).then(result => {
      if (result === null) {
        //社員が存在しない場合
        this.initItem();
        this.pageForm.controls.syainNoCheck.setValue(false);
        return;
      }
      const data = result[0];
      this.itemattend.syainNoMain = syainNo.toString();
      this.itemattend.kjnNoMain = data.nsm001_kjn_no;
      this.itemattend.groupMain = data.nsm001_group;
      this.itemattend.nameKnMain = data.nsm001_name_kn;
      this.itemattend.nameKjMain = data.nsm001_name_kj;
      this.itemattend.telNoMain = data.nsm001_tel_no;
      this.itemattend.emailMain = data.nsm001_email;
      this.pageForm.controls.syainNoCheck.setValue(true);
    });  
  }

  async getAttendSyain() : Promise<void>{
    //アテンド社員個人番号から社員番号を取得
    let kjn_no = "";
    if(this.syokaiData[0].attendKjnNo === null){
      kjn_no = this.syokaiData[0].mainKjnNo;
    }else{
      kjn_no = this.syokaiData[0].attendKjnNo;
    }

    const prmList = [
      {
        key: ':pKJN_NO',
        value: kjn_no
      }];

    await this.dbConnect.CmdSql('common', '1', prmList).then(result => {
      if (result === null) {
        //社員が存在しない場合
        this.attendTanto = this.mainTanto;
      }
      const data = result[0];
      this.itemattend.kjnNoMain = data.nsm001_kjn_no;
      this.itemattend.groupMain = data.nsm001_group;
      this.itemattend.nameKnMain = data.nsm001_name_kn;
      this.itemattend.nameKjMain = data.nsm001_name_kj;
      this.itemattend.telNoMain = data.nsm001_tel_no;
      this.itemattend.emailMain = data.nsm001_email;
      this.attendTanto = this.itemattend.groupMain + '　' + this.itemattend.nameKjMain + '　　内線：' + this.itemattend.telNoMain;
    });
    
  }

  clickDojo() : void{
    if(this.pageForm.controls.dojo.value){
      this.initItem();
      this.pageForm.controls.syainNo.setValue('');
      this.pageForm.controls.syainNo.enable();
    }else{
      this.pageForm.controls.syainNo.disable();
    }
    this.issyainnoDisabled = !this.issyainnoDisabled;
  }

  initItem() : void{
    this.itemattend.syainNoMain = "";
    this.itemattend.kjnNoMain = "";
    this.itemattend.groupMain = "";
    this.itemattend.nameKnMain = "";
    this.itemattend.nameKjMain = "";
    this.itemattend.telNoMain = "";
    this.itemattend.emailMain = "";
  }

  CmbReasonChange() : void{
    if(this.pageForm.controls.specialreason.value === '9'){
      this.pageForm.controls.specialextra.enable();
      this.isextraDisabled = false;
    }else{
      this.pageForm.controls.specialextra.setValue('');
      this.pageForm.controls.specialextra.disable();
      this.isextraDisabled = true;
    }
  }

  async btnKaijoClick() : Promise<void>{
    if(!confirm("処理が終了しているデータの完了状態を解除します。\nよろしいですか？")){
      return;
    }

    let sts = '06'

    //入館中、退館済、承認済のいずれかの状態取得
    const prmList1 = [{
      key: ':pSINSEI_NO',
      value: this.syokaiData[0].sinseiNo
    }];

    await this.dbConnect.CmdSql('sinseiSyokai', '13', prmList1).then(result => {
      if (result === null) {
        //履歴が存在しない場合
        //ステータスを承認済みに変更する
        sts = '04';
      }else{
        const data = result;
        data.some(obj => {
          if(obj.nst011_status == '01'){
            //入館中の場合
            sts = '05';
            return true;
          }else if(obj.nst011_status == '03'){
            //退館済の場合
            sts = '05';
            return true;
          }else{
            //一時退館の場合
            sts = '05';
            return true;
          }
        });
      }
    });

    // 申請ステータス変更
    const prmList2 = [{
      key: ':pSTATUS',
      value: sts
    },
    {
      key: ':pUPD_KJN_NO',
      value: this.userData.keep.kjnNo
    },
    {
      key: ':pSINSEI_NO',
      value: this.syokaiData[0].sinseiNo
    }];
    await this.dbConnect.CmdStored('sinseiSyokai', '4', prmList2);
    alert("解除しました。")
  }

  // 申請照会詳細から受付画面へ遷移
  btnUketukeClick(): void {
    if(this.syokaiData[0].sinseiNo != null){
      // 2022.09.07 申請Noを暗号化して受付画面へナビゲートするように修正
      let encSinseiNo = this.encrypto(this.syokaiData[0].sinseiNo);
      this.router.navigate(['/nyutaikanUketuke'], { queryParams: { id: encSinseiNo }});
      //this.router.navigate(['/nyutaikanUketuke'], { queryParams: { id: this.syokaiData[0].sinseiNo }});
    }
  }

  // 2022.09.07 暗号化用メソッド追加
  encrypto(str : string) : string {
    const {AES, enc} = require('crypto-js');
    const KEY = '4SA9H8NEHGIT0DSA8';
    const json = AES.encrypt(JSON.stringify(str), KEY).toString();

    return enc.Base64.stringify(enc.Latin1.parse(json));
  }


  isKeibi(): boolean {
    // 2022.09.07 バグ修正
    //return this.userData.keep.role === '05' && this.syokaiData[0].status=='05';
    return this.userData.keep.role === '5' && (this.syokaiData[0].status=='04' || this.syokaiData[0].status=='05');
  }

}

