import { Injectable } from '@angular/core';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})

export class PageTransitionCheckService {

  private pageAuth = {
    'MasterTorokuComponent':{'00':true, '20': false, '30':false, '41':false, '42':false, '43':false, '44':false},
    'SinseiTorokuComponent':{'00':true, '20': true, '30':false, '41':true, '42':true, '43':true, '44':true},
    'SinseiSyokaiComponent':{'00':true, '20': true, '30':true, '41':true, '42':true, '43':true, '44':true},
    'NyutaikanUketukeComponent':{'00':true, '20': false, '30':true, '41':false, '42':false, '43':false, '44':false},
    'MasterSyuseiComponent':{'00':true, '20': true, '30':true, '41':true, '42':true, '43':true, '44':true}, //★
    'RoleChangeComponent':{'00':true, '20': false, '30':false, '41':false, '42':false, '43':false, '44':false}, //★
    'DomainKanriComponent':{'00':true, '20': false, '30':false, '41':false, '42':false, '43':false, '44':false}, //★
    'RirekiDetailComponent':{'00':true, '20': false, '30':true, '41':false, '42':false, '43':false, '44':false}, //★
    'RirekiListComponent':{'00':true, '20': false, '30':true, '41':false, '42':false, '43':false, '44':false}, //★
  }

  private employeePageAuth = {
    'MasterTorokuComponent':{'1':false, '2': false, '3':true, '4':false},
    'SinseiTorokuComponent':{'1':true, '2': true, '3':true, '4':true},
    'SinseiSyokaiComponent':{'1':true, '2': true, '3':true, '4':true},
    'NyutaikanUketukeComponent':{'1':false, '2': false, '3':false, '4':false},
    'MasterSyuseiComponent':{'1':true, '2': true, '3':true, '4':true}, //★
    'RoleChangeComponent':{'1':false, '2': true, '3':true, '4':true}, //★
    'DomainKanriComponent':{'1':false, '2': true, '3':true, '4':true}, //★
    'RirekiDetailComponent':{'1':false, '2': true, '3':true, '4':false}, //★
    'RirekiListComponent':{'1':false, '2': true, '3':true, '4':false}, //★
  }

  constructor(
    private userData : UserDataService
  ) { }

  authCheck(page:any) : boolean{
    const userKbn = this.userData.keep.userKbn
    const role = this.userData.keep.role
    
    if(userKbn != '10'){
      const authList = this.pageAuth[page.constructor.name]
      return authList[userKbn]
    }else if (userKbn === '10'){
      const authList = this.employeePageAuth[page.constructor.name]
      return authList[role]
    }else{
      return false
    }
  }
}
