import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SinseiData } from '@class/sinsei-data';
import { MotikomiData } from '@class/motikomi-data';
import { DbConnectService } from '@service/db-connect.service';
import { UserDataService } from '@service/user-data.service';
import { AwsMailService } from '@service/aws-mail.service';

import { ModalService } from '@service/modal.service';
import { SpinnerModalComponent } from '@spinner/spinner-modal.component';
import { newsCategory, NewsService } from '@service/news.service';

@Component({
  selector: 'app-sinsei-toroku-kakunin',
  templateUrl: './sinsei-toroku-kakunin.component.html',
  styleUrls: ['../sinsei-toroku.component.css']
})
export class SinseiTorokuKakuninComponent implements OnInit {
  @Input() sinseiData: SinseiData;
  @Output() changeFlg = new EventEmitter();
  motikomiData: MotikomiData[];
  nyukanYoteiDat: string;
  taikanYoteiDat: string;
  spAreas: string;
  pcAreas: string;
  dcAreas: string;
  sdAreas: string;
  camera: string;
  phone: string;
  noHannyu: boolean;
  noHansyutu: boolean;
  noPc: boolean;
  noTool: boolean;
  noOther: boolean;
  mainTanto: string;
  //subTanto: string; //★削除

  constructor(
    private datePipe: DatePipe,
    private dbConnect: DbConnectService,
    private userData: UserDataService,
    private mailService: AwsMailService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
    private newsService: NewsService
  ) { }

  ngOnInit(): void {
  }

  async btnTorokuClick(): Promise<any> {
    if (!confirm('登録します。よろしいですか？')) {
      return;
    }

    this.modalService.openModal(SpinnerModalComponent, this.viewContainerRef, []);

    this.setMotikomiData();

    // 新規申請番号の取得
    const prmList = [{
      key: '',
      value: ''
    }];
    const result = await this.dbConnect.CmdSql('sinseiToroku', '1', prmList);
    const sinseiNo = result[0].sinsei_no;

    // nst001_sinseiの更新
    const prmList2 = [{
      key: ':pSINSEI_NO',
      value: sinseiNo
    },
    {
      key: ':pSINSEI_KJN_NO',
      value: this.userData.keep.kjnNo
    },
    {
      key: ':pSTATUS',
      value: '01'
    },
    {
      key: ':pNYUKAN_YOTEI_YMD',
      value: this.sinseiData.nyukanYoteiYmd
    },
    {
      key: ':pNYUKAN_YOTEI_TIME',
      value: this.sinseiData.nyukanYoteiTime
    },
    {
      key: ':pTAIKAN_YOTEI_YMD',
      value: this.sinseiData.taikanYoteiYmd
    },
    {
      key: ':pTAIKAN_YOTEI_TIME',
      value: this.sinseiData.taikanYoteiTime
    },
    {
      key: ':pGROUP',
      value: this.sinseiData.group
    },
    {
      key: ':pMOKUTEKI',
      value: this.sinseiData.mokuteki
    },
    {
      key: ':pSYOSAI_MOKUTEKI',
      value: this.sinseiData.mokutekiSyosai
    },
    {
      key: ':pAREA_SP',
      value: ''
    },
    {
      key: ':pAREA_PC',
      value: ''
    },
    {
      key: ':pAREA_DC',
      value: ''
    },
    // {
    //   key: ':pAREA_SD',
    //   value: this.getNyukanArea(this.sinseiData.sdAreas)
    // },
    {
      key: ':pCAMERA',
      value: this.sinseiData.camera ? '1' : '0'
    },
    {
      key: ':pPHONE',
      value: this.sinseiData.phone ? '1' : '0'
    },
    {
      key: ':pMAIN_KJN_NO',
      value: this.userData.keep.mainKjnNo
    },
    {
      key: ':pINS_KJN_NO',
      value: this.userData.keep.kjnNo
    },
    {
      key: ':pUPD_KJN_NO',
      value: this.userData.keep.kjnNo
    }];
    await this.dbConnect.CmdStored('sinseiToroku', '11', prmList2);

    // nst010_nyukansyaの更新
    for (const obj of this.sinseiData.nyukansyas) {
      const prmList3 = [{
        key: ':pSINSEI_NO',
        value: sinseiNo
      },
      {
        key: ':pSEQ_NO',
        value: Number(obj.seqNo) + 1
      },
      {
        key: ':pNAME_KN',
        value: obj.nameKn
      },
      {
        key: ':pNAME_KJ',
        value: obj.nameKj
      },
      {
        key: ':pINS_KJN_NO',
        value: this.userData.keep.kjnNo
      },
      {
        key: ':pUPD_KJN_NO',
        value: this.userData.keep.kjnNo
      }];
      await this.dbConnect.CmdStored('sinseiToroku', '3', prmList3);
    }

    // nst020_motikomiの更新
    for (const obj of this.motikomiData) {
      const prmList4 = [{
        key: ':pSINSEI_NO',
        value: sinseiNo
      },
      {
        key: ':pKBN',
        value: obj.kbn
      },
      {
        key: ':pSEQ_NO',
        value: Number(obj.seqNo) + 1
      },
      {
        key: ':pMEISYO',
        value: obj.meisyo
      },
      {
        key: ':pSETUZOKUSAKI',
        value: obj.setuzokusaki
      },
      {
        key: ':pMACADDRESS',
        value: obj.macaddress
      },
      {
        key: ':pINS_KJN_NO',
        value: this.userData.keep.kjnNo
      },
      {
        key: ':pUPD_KJN_NO',
        value: this.userData.keep.kjnNo
      }];
      await this.dbConnect.CmdStored('sinseiToroku', '4', prmList4);
    }

    // nst030_newsの更新
    await this.newsService.registerNews(this.userData.keep.kjnNo, newsCategory.sinseiKihyo,
       this.userData.keep.group + '　' + this.userData.keep.nameKj);

    // 担当者に申請登録通知メール
    const prmMainInfo = [{
      key: ':pMAIN_KJN_NO',
      value: this.userData.keep.mainKjnNo
    }];

    this.dbConnect.CmdSql('common', '7', prmMainInfo).then(async result2 => {
      const groupName = this.userData.keep.group + '　' + this.userData.keep.nameKj;
      const res = result2[0];

      const prmMain = {
        tantouAddr: res.main_email,
        tantouNm: res.main_group + '　' + res.main_name_kj,
        sinseiShaNm: groupName
      };
      await this.mailService.sendSinseiTorokuTuti(prmMain);

      this.modalService.closeModal();
      this.changeFlg.emit(true);
    });
  }

  btnBackClick(): void {
    this.changeFlg.emit(false);
  }

  getYoteiDat(date: string, time: string): string {
    if (date === '' || time === '') {
      return '';
    }
    const dat0 = date + ' ' + time;
    return this.datePipe.transform(dat0, 'yyyy年MM月dd日') + '　' + this.datePipe.transform(dat0, 'HH時mm分');
  }

  getLengthZero(item: any): boolean {
    return item.length === 0;
  }

  getMoticomiUmu(item: boolean): string {
    return item ? '持ち込みあり' : '持ち込みなし';
  }

  getTanto(group: string, name: string, telNo: string): string {
    return group + '　' + name + '　内線：' + telNo;
  }

  setMotikomiData(): void {
    this.motikomiData = [];

    // 搬入物
    this.sinseiData.hannyus.forEach(obj => {
      const item = new MotikomiData(
        '',
        '1',
        obj.seqNo,
        obj.meisyo,
        '',
        '',
        ''
      );
      this.motikomiData.push(item);
    });

    // 搬出物
    this.sinseiData.hansyutus.forEach(obj => {
      const item = new MotikomiData(
        '',
        '2',
        obj.seqNo,
        obj.meisyo,
        '',
        '',
        ''
      );
      this.motikomiData.push(item);
    });

    // 持込作業PC
    this.sinseiData.pcs.forEach(obj => {
      const item = new MotikomiData(
        '',
        '3',
        obj.seqNo,
        obj.pcName,
        obj.setuzokusaki,
        obj.macaddress,
        ''
      );
      this.motikomiData.push(item);
    });

    // 持込工具類
    this.sinseiData.tools.forEach(obj => {
      const item = new MotikomiData(
        '',
        '4',
        obj.seqNo,
        obj.meisyo,
        '',
        '',
        ''
      );
      this.motikomiData.push(item);
    });

    // その他持込物
    this.sinseiData.others.forEach(obj => {
      const item = new MotikomiData(
        '',
        '5',
        obj.seqNo,
        obj.meisyo,
        '',
        '',
        ''
      );
      this.motikomiData.push(item);
    });
  }

}
