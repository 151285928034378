<body>
  <app-menu>
    <div class="container">
      <p class="h2 mb-5 title">申請情報照会</p>
      <form class="ml-3 mr-3 mb-3" [formGroup]="pageForm">
        <div class="form-row">
          <div class="col-md-2 mb-3">
            <label class="font-weight-bold">申請番号</label>
          </div>
          <div class="col-md-4 mb-3">
            <input type="text" class="form-control form-control-sm" [formControl]="pageForm.controls.sinseiNo">
          </div>
          <div class="col-md-2 mb-3 center">
            <label class="font-weight-bold">ステータス</label>
          </div>
          <div class="col-md-4 mb-3">
            <select class="form-control form-control-sm" size="1" [formControl]="pageForm.controls.status">
              <option value="">選択してください</option>
              <option value="01">申請中</option>
              <option value="02">承認済</option>
              <option value="06">未入館</option>
              <option value="03">入館中</option>
              <option value="05">退館済</option>
              <option value="04">終了</option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-2 mb-3">
            <label class="font-weight-bold">入館予定日</label>
          </div>
          <div class="col-md-4 mb-3">
            <input type="date" class="form-control form-control-sm"
              [formControl]="pageForm.controls.nyukanYoteiYmdFrom">
          </div>
          <div class="col-md-2 mb-3 center">
            <label class="font-weight-bold">〜</label>
          </div>
          <div class="col-md-4 mb-3">
            <input type="date" class="form-control form-control-sm" [formControl]="pageForm.controls.nyukanYoteiYmdTo">
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-3 mb-3">
            <label class="font-weight-bold">申請者氏名（カナ）</label>
          </div>
          <div class="col-md-9 mb-3">
            <input type="text" class="form-control form-control-sm" [formControl]="pageForm.controls.sinseiNameKn">
          </div>
        </div>
        <div class="col-md-12 mt-3 mb-5 center">
          <button type="button" class="btn btn-info" (click)="btnExtractClick()">抽　出</button>
          <button type="button" class="btn btn-secondary ml-3" (click)="btnClearClick()">クリア</button>
          <span *ngIf="keibi">
            <button type="button" class="btn btn-primary ml-3" (click)="btnExtractTodayClick()">当日検索</button>
            <button type="button" class="btn btn-success ml-3" (click)="btnExtractTodayClick(1)">翌日検索</button>
          </span>
        </div>
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-end">
            <li class="page-item">
              <button class="page-link" (click)="onBackPage()" [disabled]="this.syokaiData.length == 0">前へ</button>
            </li>
            <li class="page-item disabled">
              <label class="page-link">{{pageIndex + 1}} / {{totalPage}}</label>
            </li>
            <li class="page-item">
              <button class="page-link" (click)="onNextPage()" [disabled]="this.syokaiData.length == 0">次へ</button>
            </li>
          </ul>
        </nav>
        <div class="tableDetail">
          <table class="table table-hover mb-5">
            <thead class="thead-light center">
              <tr>
                <th class="thSinseiNo">申請番号</th>
                <th class="thStatus">ステータス</th>
                <th class="thNyukanYoteiYmd">入館予定日</th>
                <th class="thSinseiName">申請者氏名</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let obj of syokaiDataDisp; let i = index" (click)="btnRecordClick(i)">
                <td>{{obj.sinseiNo}}</td>
                <td>{{obj.statusDisp}}</td>
                <td>{{obj.nyukanYoteiYmd}}</td>
                <td>{{obj.sinseiNameKj}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </app-menu>
</body>
