
<div class="container">
    <div class="center">
    <p class="h3 font-weight-normal mb-5">株式会社GCC 入館申請システム</p>
    </div>
    <form class="form-inline justify-content-center mb-4" [formGroup]="form">
        <div class="form-label-group mx-sm-3">
            <label class="sr-only">メールアドレス</label>
            <input type="text" class="form-control formWidth" [formControl]="form.controls.mailAddress" name="mailAddress" placeholder="メールアドレス"
            [class.input-invalid]="form.controls.mailAddress.invalid && (form.controls.mailAddress.touched || form.controls.mailAddress.dirty)">
        </div>
        <ng-container
            *ngIf="form.controls.mailAddress.hasError('existEmail') && (form.controls.mailAddress.touched || form.controls.mailAddress.dirty)">
            <div class="">
              <span class="textRed">※そのメールアドレスは既に存在しています。</span>
            </div>
        </ng-container>
    </form>
    <div class="center">
        <button class="btn btn-primary mr-3" name="btnLogin" (click)="onSubmit()">登　録</button>
        <button class="btn btn-secondary ml-3" name="btnLogin" (click)="onBack()">戻　る</button>
    </div>
</div>
