import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { History, RirekiDetail } from '@class/rireki-detail';
import { SyokaiData } from '@class/syokai-data';
import { DbConnectService } from '@service/db-connect.service';
import { PageTransitionCheckService } from '@service/page-transition-check.service';

@Component({
  selector: 'app-rireki-detail',
  templateUrl: './rireki-detail.component.html',
  styleUrls: ['./rireki-detail.component.css']
})
export class RirekiDetailComponent implements OnInit {
  data: RirekiDetail;

  constructor(
    private awsService: DbConnectService,
    private pageAuth: PageTransitionCheckService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if(!this.pageAuth.authCheck(this)){
      this.router.navigateByUrl('/home');
    }

    const id = this.activatedRoute.snapshot.params.id;
    this.fetchRirekiData(id);
  }

  fetchRirekiData(id: number): Promise<void>{
    return this.awsService.getNyukanHistory(id)
      .then(res => {
        console.log(res)
        this.data = res;
      });
  }

}
