import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SinseiTorokuKakuninComponent } from './sinsei-toroku-kakunin/sinsei-toroku-kakunin.component';
import { SinseiData } from '@class/sinsei-data';
import { PageTransitionCheckService } from '@service/page-transition-check.service';

@Component({
  selector: 'app-sinsei-toroku',
  templateUrl: './sinsei-toroku.component.html',
  styleUrls: ['./sinsei-toroku.component.css']
})
export class SinseiTorokuComponent implements OnInit {
  @ViewChild(SinseiTorokuKakuninComponent) sinseiTorokuKakunin: SinseiTorokuKakuninComponent;
  sinseiData: SinseiData;
  nyuryoku: boolean;
  kakunin: boolean;

  constructor(
    private router: Router,
    private pageAuth : PageTransitionCheckService
  ) {
    this.sinseiData = {
      nyukanYoteiYmd: '',
      nyukanYoteiTime: '',
      taikanYoteiYmd: '',
      taikanYoteiTime: '',
      group: '',
      mokuteki: '',
      mokutekiSyosai: '',
      nyukansyas: [{ seqNo: '', nameKn: '', nameKj: '' }],
      hannyus: [{ seqNo: '', meisyo: '' }],
      hansyutus: [{ seqNo: '', meisyo: '' }],
      spAreas: [{ no: '', meisyo: '', nyukanFlg: false }],
      pcAreas: [{ no: '', meisyo: '', nyukanFlg: false }],
      dcAreas: [{ no: '', meisyo: '', nyukanFlg: false }],
      //sdAreas: [{ no: '', meisyo: '', nyukanFlg: false }],
      pcs: [{ seqNo: '', pcName: '', setuzokusaki: '', macaddress: '' }],
      tools: [{ seqNo: '', meisyo: '' }],
      camera: false,
      phone: false,
      others: [{ seqNo: '', meisyo: '' }],
      mainGroup: '',
      mainNameKn: '',
      mainNameKj: '',
      mainTelNo: '',
      mainEmail: '',
      subGroup: '',
      subNameKn: '',
      subNameKj: '',
      subTelNo: '',
      subEmail: ''
    };
   }

  ngOnInit(): void {
    if(!this.pageAuth.authCheck(this)){
      this.router.navigateByUrl('/home')
      return
    }

    this.nyuryoku = true;
    this.kakunin = false;
  }

  getSinseiData(data: SinseiData): void {
    this.sinseiData = data;
  }

  changeNyuryoku(changeFlg: boolean): void {
    if (changeFlg) {
      // 次へ
      this.nyuryoku = false;
      this.kakunin = true;
      window.scrollTo(0, 0);
    }
  }

  changeKakunin(changeFlg: boolean): void {
    if (changeFlg) {
      // 申請する
      alert('登録が完了しました。');
      this.router.navigate(['/home']);
      window.scrollTo(0, 0);
    } else {
      // 戻る
      this.nyuryoku = true;
      this.kakunin = false;
      window.scrollTo(0, 0);
    }
  }

}
