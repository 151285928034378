import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DomainData } from '@class/domain-data';
import { DbConnectService } from '@service/db-connect.service';
import { UserDataService } from '@service/user-data.service';

@Component({
  selector: 'app-domain-nyuryoku',
  templateUrl: './domain-nyuryoku.component.html',
  styleUrls: ['../domain-kanri.component.css']
})
export class DomainNyuryokuComponent implements OnInit {
  @Input() gridData: DomainData[];
  @Output() gridRefresh = new EventEmitter();
  pageForm: FormGroup;
  domainData: DomainData[];
  domainDataDisp: DomainData[];
  syain: boolean;
  deleteData: DomainData[];
  
  pageIndex: number;
  totalPage: number;
  dataCount: number;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private dbConnect: DbConnectService,
    private userData: UserDataService,
  ) { 
    this.pageForm = fb.group({
      check: false,
      no: '',
      name: ''
    });
    this.domainData = [{
      check: false,
      no: '',
      name: ''
    }];
    this.domainDataDisp = [{
      check: false,
      no: '',
      name: ''
    }];
    this.deleteData = [{
      check: false,
      no: '',
      name: ''     
    }]
  }

  ngOnInit(): void {
    this.pageIndex = 0;
    this.totalPage = 0;

    // 1ページの最大データ表示数
    this.dataCount = 5;
  }

  setData(){
    this.domainData.length = 0;
    this.gridData.forEach(obj => {
      const data = new DomainData(
        false,
        obj.no,
        obj.name
      );
      this.domainData.push(data);
    })

    // ページネーション
    this.pageIndex = 0;
    this.totalPage = Math.ceil(this.domainData.length / this.dataCount);
    this.changePage();
  }

  onNextPage(): void {
    if (this.pageIndex === this.totalPage -1) {
      return;
    }
    this.pageIndex++;
    this.changePage();

  }

  onBackPage(): void {
    if (this.pageIndex === 0) {
      return;
    }
    this.pageIndex--;
    this.changePage();
  }

  changePage(): void {
    let val = 0;
    this.domainDataDisp.length = 0;
    while (val < this.dataCount) {
      const index = (this.pageIndex) * this.dataCount + val;
      if (index < this.domainData.length) {
        const data = new DomainData(
          this.domainData[index].check,
          this.domainData[index].no,
          this.domainData[index].name
        );
        this.domainDataDisp.push(data);
      }
      val++;
    }
  }

  // データ行　クリック
  btnRecordClick(index: number): void {
    if (this.domainDataDisp[index].no === null || this.domainDataDisp[index].no === '') {
      return;
    }
    //this.domainDataDisp[index].check = !this.domainDataDisp[index].check;
    //alert("[" + index + "]" + this.domainDataDisp[index].check);

    //this.domainNo.emit(this.domainDataDisp[index].no);
  }

  async btnDeleteClick(){
    let message = "";
    this.deleteData.length = 0;
    for(let i = 0; i < this.domainDataDisp.length; i++){
      if(this.domainDataDisp[i].check){
        message = message + "[No." + this.domainDataDisp[i].no + "] " + this.domainDataDisp[i].name + "\n";

        const data = new DomainData(
          this.domainDataDisp[i].check,
          this.domainDataDisp[i].no,
          this.domainDataDisp[i].name
        );
        this.deleteData.push(data);
      }
    }

    if(this.deleteData.length == 0){
      return;
    }

    let ret = confirm(message + "を削除します。よろしいですか？");
    if(ret){
      for(let i = 0; i < this.deleteData.length; i++){
        const prmList = [
          {
            key: ':pDOMAIN_NO',
            value: this.deleteData[i].no
          }];
          await this.dbConnect.CmdStored('domainKanri', '2', prmList);
      }
      this.gridRefresh.emit();
      alert('削除しました。');
    }
  }

/**
  btnBackClick(): void {
    this.router.navigateByUrl('/home')
    return
  }
**/

  async btnAddClick(){
    const regex = /^([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    if(!regex.test(this.pageForm.controls.name.value.trim())){
      alert("ドメイン名が規則性に沿っていません。");
      return;
    }
    
    const prmList = 
    [{
      key: ':pDOMAIN_NAME',
      value: this.pageForm.controls.name.value.trim()
    },{
      key: ':pKJN_NO',
      value: this.userData.keep.kjnNo
    }];
    await this.dbConnect.CmdStored('domainKanri', '3', prmList);

    this.gridRefresh.emit();
    alert("ドメイン [" + this.pageForm.controls.name.value.trim() + "] を追加しました。");
    this.btnClearClick();

  }

  btnClearClick(): void{
    this.pageForm.controls.name.setValue('');
  }

}
