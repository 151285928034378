    <div class="container">
      <p class="h2 mb-5 title">登録者情報</p>
      <form class="needs-validation ml-3 mr-3 mb-3" [formGroup]="pageForm">
        <div class="form-row">
            <div class="col-md-2 mb-3">
              <label class="font-weight-bold">所属</label>
            </div>
            <div class="col-md-8 mb-3">
              <label class="ml-3">{{syokaiData.group}}</label>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2 mb-3">
              <label class="font-weight-bold">氏名（カナ）</label>
            </div>
            <div class="col-md-8 mb-3">
              <label class="ml-3">{{syokaiData.nameKn}}</label>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2 mb-3">
              <label class="font-weight-bold">氏名（漢字）</label>
            </div>
            <div class="col-md-8 mb-3">
              <label class="ml-3">{{syokaiData.nameKj}}</label>
            </div>
          </div>

          <p class="h2 mb-5 title">役割変更</p>

          <div class="form-row">
            <div class="col-md-12 mb-3">
              <label class="font-weight-bold">種別</label>
              <div class="col-md-8 mb-3">
                <label class="ml-3">{{getUserKbn(syokaiData.userKbn)}}</label>
              </div>
            </div>
          </div>

          <div class="col-md-12 mb-3">
            <label class="font-weight-bold">ロール</label>
            <div class="form-row" [hidden]="!syain">
              <select [(ngModel)]="selected_role" class="form-control"
              [formControl]="pageForm.controls.role" [class.input-invalid]="pageForm.controls.role.invalid && (pageForm.controls.role.touched || pageForm.controls.role.dirty)">
                <option value="1" [selected]="selected_role == '1'">担当者</option>
                <option value="2" [selected]="selected_role == '2'">DS部　部次長</option>
                <option value="3" [selected]="selected_role == '3'">DS部　SOG社員</option>
                <option value="4" [selected]="selected_role == '4'">DS部　FOG社員</option>
              </select>
            </div>
            <div class="form-row" [hidden]="syain">
              <select [(ngModel)]="selected_role" class="form-control"
              [formControl]="pageForm.controls.role">
                <option value="" [selected]="selected_role == ''">一般</option>
                <option value="5" [selected]="selected_role == '5'">警備員</option>
              </select>
            </div>
          </div>
        </form>
      <div class="center">
        <button class="btn btn-info" type="submit" name="btnNext" (click)="btnNextClick()">次　へ</button>
        <button class="btn btn-secondary ml-3" type="submit" name="btnBack" (click)="btnBackClick()">戻　る</button>
      </div>
    </div>
 