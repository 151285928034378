import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-collapse',
  templateUrl: './simple-collapse.component.html',
  styleUrls: ['./simple-collapse.component.css']
})
export class SimpleCollapseComponent implements OnInit {
  @Input() isCollapsed:boolean = false;
  @Input() text:string;

  constructor() { }

  ngOnInit(): void {
  }

}
