import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MasterData } from '@class/master-data';
import { DbConnectService } from '@service/db-connect.service';
import { CustomValidator } from '@utility/custom-validator';
import { UserDataService } from '@service/user-data.service'; //★

@Component({
  selector: 'app-master-syusei-nyuryoku',
  templateUrl: './master-syusei-nyuryoku.component.html',
  styleUrls: ['../master-syusei.component.css']
})
export class MasterSyuseiNyuryokuComponent implements OnInit {
  @Output() masterData = new EventEmitter();
  @Output() changeFlg = new EventEmitter();
  pageForm: FormGroup;
  item: MasterData;
  syain: boolean;
  touchFlg: boolean;

  constructor(
    private fb: FormBuilder,
    private dbConnect: DbConnectService,
    private userData: UserDataService //★
  ) {
    this.pageForm = fb.group({
      syubetu: [''],
      group: ['', [Validators.required]],
      nameKnSei: ['', [Validators.required, CustomValidator.katakana]],
      nameKnMei: ['', [Validators.required, CustomValidator.katakana]],
      nameKjSei: ['', [Validators.required]],
      nameKjMei: ['', [Validators.required]],
      telNo: ['', [Validators.required, CustomValidator.numeric]],
      telNoCheck: ['', [Validators.required]],
      telNoEqual: [null, [Validators.requiredTrue]],
      email: ['', [Validators.required]],
      emailCheck: ['', [Validators.required]],
      emailEqual: [null, [Validators.requiredTrue]]
    });

    this.item = {
      syubetu: '',
      syainNo: '',
      role: '',
      roleDisp: '',
      group: '',
      nameKn: '',
      nameKj: '',
      telNo: '',
      email: '',
      //以下未使用
      kjnNoMain: '',
      syainNoMain: '',
      groupMain: '',
      nameKnMain: '',
      nameKjMain: '',
      telNoMain: '',
      emailMain: '',
      kjnNoSub: '',
      syainNoSub: '',
      groupSub: '',
      nameKnSub: '',
      nameKjSub: '',
      telNoSub: '',
      emailSub: ''
    };
  }

  ngOnInit(): void {
    this.syain = false;
    this.touchFlg = false;
    this.getMasterData();
  }

  btnNextClick(): void {
    this.setTouched();
    if (this.pageForm.invalid) {
      return;
    }

    this.setMasterData();
    this.masterData.emit(this.item);
    this.changeFlg.emit(true);
  }

  setTouched(): void {
    this.touchFlg = true;
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

  setMasterData(): void {
    this.item.syubetu = this.getUserKbn(this.item.syubetu);
    this.item.group = this.pageForm.controls.group.value;
    this.item.nameKn = this.pageForm.controls.nameKnSei.value + '　' + this.pageForm.controls.nameKnMei.value;
    this.item.nameKj = this.pageForm.controls.nameKjSei.value + '　' + this.pageForm.controls.nameKjMei.value;
    this.item.telNo = this.pageForm.controls.telNo.value;
    this.item.email = this.pageForm.controls.email.value;
  }

  //★ マスター修正のための元データ取得 Start
  getMasterData(): void {
    const prmList = [{
      key: ':pKJN_NO',
      value: this.userData.keep.kjnNo
    }]

    this.dbConnect.CmdSql('common', '1', prmList).then(result => {
      if (result === null) {
        return;
      }
      const data = result[0];
      this.item.syubetu = data.nsm001_user_id.toString().substring(0, 2);
      if (this.item.syubetu === '10') {
        // 社員
        this.syain = true;
      }
      this.item.syainNo = data.nsm001_syain_no;
      this.item.role = data.nsm001_role;
      this.item.roleDisp = this.getRole(data.nsm001_role);
      this.item.group = data.nsm001_group;
      this.item.nameKn = data.nsm001_name_kn;
      this.item.nameKj = data.nsm001_name_kj;
      this.item.telNo = data.nsm001_tel_no;
      this.item.email = data.nsm001_email;

      //画面に設定
      this.pageForm.controls.group.setValue(this.item.group);
      this.pageForm.controls.nameKnSei.setValue(this.item.nameKn.toString().split('　')[0]);
      this.pageForm.controls.nameKnMei.setValue(this.item.nameKn.toString().split('　')[1]);
      this.pageForm.controls.nameKjSei.setValue(this.item.nameKj.toString().split('　')[0]);
      this.pageForm.controls.nameKjMei.setValue(this.item.nameKj.toString().split('　')[1]);
      this.pageForm.controls.telNo.setValue(this.item.telNo);
      this.pageForm.controls.email.setValue(this.item.email);
    });

  }
  //★ End

  checkTelNo() {
    const equal = this.pageForm.controls.telNo.value === this.pageForm.controls.telNoCheck.value;
    this.pageForm.controls.telNoEqual.setValue(equal);
  }

  checkEmail() {
    const equal = this.pageForm.controls.email.value === this.pageForm.controls.emailCheck.value;
    this.pageForm.controls.emailEqual.setValue(equal);
  }

  getUserKbn(userKbn: string): string {
    let result = '';
    if (userKbn === '10') {
      result = '社員';
    } else if (userKbn === '20') {
      result = '社外';
    } else if (userKbn === '30') {
      result = '警備員';
/**
    } else if (userKbn === '41') {
      result = 'ベンダー（Gr.1）';
    } else if (userKbn === '42') {
      result = 'ベンダー（Gr.2）';
    } else if (userKbn === '43') {
      result = 'ベンダー（Gr.3）';
    } else if (userKbn === '44') {
      result = 'ベンダー（Gr.4）';
**/
    }

    return result;
  }

  getRole(role: string): string {
    let result = '';
    if (role === '1') {
      result = '担当者';
    } else if (role === '2') {
      result = 'DS部　部次長';
    } else if (role === '3') {
      result = 'DS部　SOG社員';
    } else if (role === '4') {
      result = 'DS部　FOG社員';
    }

    return result;
  }

}
