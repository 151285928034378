import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class S3fileService {

  url= "https://9vl42drmmk.execute-api.ap-northeast-1.amazonaws.com/PdfDownload_Any/";

  downloadUrl = "https://9vl42drmmk.execute-api.ap-northeast-1.amazonaws.com/PdfDownload_Any/";
  uploadUrl = "https://yhx0otdsea.execute-api.ap-northeast-1.amazonaws.com/S3FileUpload";
  deleteUrl = "https://hv8b7xfy30.execute-api.ap-northeast-1.amazonaws.com/S3FileDelete";

  constructor(private http: HttpClient) { }

  async downloadFile(filename, sinseiNo){
    const params = {
      bucket: "pdftempstorage",
      objectKey: sinseiNo.toString() + "/" + filename,
    }

    await this.http.get(this.downloadUrl, { params, responseType: 'text' }).toPromise().then((result) =>{
        const source = 'data:application/pdf;base64,' + result;
        const link = document.createElement("a");
        link.href = source;
        link.download = filename;
        link.click();
    })
  }

  async uploadFile(file, filebase64, sinseiNo){
    const params = {
      bucket: "pdftempstorage",
      key: sinseiNo.toString() + "/" + file.name, 
      contentType: "application/pdf",
      buffer: filebase64.toString().split(",")[1]
    }

    await this.http.post(this.uploadUrl, params).toPromise().then((result) =>{
      return;
    })
  }

  async deleteFile(filename, sinseiNo){
    const params = {
      bucket: "pdftempstorage",
      key: sinseiNo.toString() + "/" + filename,
    }

    await this.http.post(this.deleteUrl, params).toPromise().then((result) =>{
      return;
    })
  }
}
