<body>
  <div class="container">
    <p class="h2 mb-5 title">入館申請登録</p>
    <form class="needs-validation ml-3 mr-3 mb-3">
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">入館予定日時</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{getYoteiDat(sinseiData.nyukanYoteiYmd, sinseiData.nyukanYoteiTime)}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">退館予定日時</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{getYoteiDat(sinseiData.taikanYoteiYmd, sinseiData.taikanYoteiTime)}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">団体名</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{sinseiData.group}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mb-3">
          <label class="font-weight-bold">入館目的</label>
        </div>
        <div class="col-md-8 mb-3">
          <label class="ml-3">{{sinseiData.mokuteki}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <label>詳細</label>
        </div>
        <div class="col-md-8">
          {{sinseiData.mokutekiSyosai}}
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <label class="font-weight-bold">入館者</label>
        </div>
      </div>
      <div *ngFor="let nyukansya of sinseiData.nyukansyas; let i = index">
        <div class="form-row">
          <div class="col-md-2 ml-3">
            <label>入館者{{i + 1}}</label>
          </div>
          <div class="col-md-8">
            <label class="ml-3">{{nyukansya.nameKj}}</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mt-3">
          <label class="font-weight-bold">搬入物</label>
        </div>
        <div class="col-md-8 mt-3" [hidden]="!getLengthZero(sinseiData.hannyus)">
          <label class="ml-3">搬入物なし</label>
        </div>
      </div>
      <div *ngFor="let hannyu of sinseiData.hannyus; let i = index">
        <div class="form-row">
          <div class="col-md-2 ml-3">
            <label>搬入物{{i + 1}}</label>
          </div>
          <div class="col-md-8">
            <label class="ml-3">{{hannyu.meisyo}}</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mt-3">
          <label class="font-weight-bold">搬出物</label>
        </div>
        <div class="col-md-8 mt-3" [hidden]="!getLengthZero(sinseiData.hansyutus)">
          <label class="ml-3">搬出物なし</label>
        </div>
      </div>
      <div *ngFor="let hansyutu of sinseiData.hansyutus; let i = index">
        <div class="form-row">
          <div class="col-md-2 ml-3">
            <label>搬出物{{i + 1}}</label>
          </div>
          <div class="col-md-8">
            <label class="ml-3">{{hansyutu.meisyo}}</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <label class="font-weight-bold">持込作業PC</label>
        </div>
        <div class="col-md-8" [hidden]="!getLengthZero(sinseiData.pcs)">
          <label class="ml-3">持込作業PCなし</label>
        </div>
      </div>
      <div *ngFor="let pc of sinseiData.pcs; let i = index">
        <div class="form-row">
          <div class="col-md-2 ml-3">
            <label>持込作業PC{{i + 1}}</label>
          </div>
          <div class="col-md-8">
            <label class="ml-3">コンピュータ名：{{pc.pcName}}</label>
            <label class="ml-3">接続先：{{pc.setuzokusaki}}</label>
            <label class="ml-3">MACアドレス：{{pc.macaddress}}</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mt-3">
          <label class="font-weight-bold">持込工具類</label>
        </div>
        <div class="col-md-8 mt-3" [hidden]="!getLengthZero(sinseiData.tools)">
          <label class="ml-3">持込工具類なし</label>
        </div>
      </div>
      <div *ngFor="let tool of sinseiData.tools; let i = index">
        <div class="form-row">
          <div class="col-md-2 ml-3">
            <label>持込工具類{{i + 1}}</label>
          </div>
          <div class="col-md-8">
            <label class="ml-3">{{tool.meisyo}}</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4 mt-3 mb-3">
          <label class="font-weight-bold">持込カメラ・デジタルカメラ</label>
        </div>
        <div class="col-md-6 mt-3 mb-3">
          <label class="ml-3">{{getMoticomiUmu(sinseiData.camera)}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label class="font-weight-bold">持込携帯電話・スマートフォン</label>
        </div>
        <div class="col-md-6 mb-3">
          <label class="ml-3">{{getMoticomiUmu(sinseiData.phone)}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2">
          <label class="font-weight-bold">その他持込物</label>
        </div>
        <div class="col-md-8" [hidden]="!getLengthZero(sinseiData.others)">
          <label class="ml-3">その他持込物なし</label>
        </div>
      </div>
      <div *ngFor="let other of sinseiData.others; let i = index">
        <div class="form-row">
          <div class="col-md-2 ml-3">
            <label>その他持込物{{i + 1}}</label>
          </div>
          <div class="col-md-8">
            <label class="ml-3">{{other.meisyo}}</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-2 mt-3">
          <label class="font-weight-bold">社内担当者情報</label>
        </div>
      </div>
        <div class="form-row">
          <div class="col-md-2 ml-3">
            <label>主担当</label>
          </div>
          <div class="col-md-8">
            <label class="ml-3">{{getTanto(sinseiData.mainGroup, sinseiData.mainNameKj, sinseiData.mainTelNo)}}</label>
          </div>
        </div>
        <!--
        <div class="form-row">
          <div class="col-md-2 ml-3">
            <label>副担当</label>
          </div>
          <div class="col-md-8">
            <label class="ml-3">{{getTanto(sinseiData.subGroup, sinseiData.subNameKj, sinseiData.subTelNo)}}</label>
          </div>
        </div>
        -->
    </form>
    <div class="center">
      <button class="btn btn-primary mr-3" type="submit" name="btnToroku" (click)="btnTorokuClick()">登　録</button>
      <button class="btn btn-secondary ml-3" type="submit" name="btnBack" (click)="btnBackClick()">戻　る</button>
    </div>
  </div>
</body>
