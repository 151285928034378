export class DomainData {
    check: boolean; // 削除
    no: string;     // ドメインNo
    name: string;    // ドメイン名
  
    constructor(
      check: boolean,
      no: string,
      name: string,
    ) {
      this.check = false;
      this.no = no;
      this.name = name;
    }
  }
  