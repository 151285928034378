import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { SinseiData, Nyukansya, Hannyu, Hansyutu, SpArea, PcArea, DcArea, /*SdArea,*/ Pc, Tool, Other } from '@class/sinsei-data';
import { AreaData } from '@class/area-data';
import { SyokaiData } from '@class/syokai-data';
import { NyukansyaData } from '@class/nyukansya-data';
import { MotikomiData } from '@class/motikomi-data';
import { DbConnectService } from '@service/db-connect.service';
import { UserDataService } from '@service/user-data.service';
import { CustomValidator } from '@utility/custom-validator';

@Component({
  selector: 'app-sinsei-toroku-nyuryoku',
  templateUrl: './sinsei-toroku-nyuryoku.component.html',
  styleUrls: ['../sinsei-toroku.component.css']
})
export class SinseiTorokuNyuryokuComponent implements OnInit {
  @Output() sinseiData = new EventEmitter();
  @Output() changeFlg = new EventEmitter();
  pageForm: FormGroup;
  item: SinseiData;
  camera: boolean;
  phone: boolean;
  sinseiCopy: SyokaiData[];
  nyukansyaCopy: NyukansyaData[];
  motikomiCopy: MotikomiData[];
  touchFlg: boolean;
  mailFlg: boolean;

  mokutekiList = [
    '',
    '作業',
    '工事',
    '点検',
    '搬入出',
    '外部審査',
    '視察',
    'その他'
  ];

  constructor(
    private fb: FormBuilder,
    private dbConnect: DbConnectService,
    private userData: UserDataService
  ) {
    this.pageForm = this.fb.group({
      nyukanYoteiYmd: ['', [Validators.required]],
      nyukanYoteiTime: ['', [Validators.required]],
      taikanYoteiYmd: ['', [Validators.required]],
      taikanYoteiTime: ['', [Validators.required]],
      group: ['', [Validators.required]],
      mokuteki: ['', [Validators.required]],
      mokutekiSyosai: ['', [Validators.required]],
      nyukansyas: this.fb.array([]),
      hannyus: this.fb.array([]),
      hansyutus: this.fb.array([]),
      pcs: this.fb.array([]),
      tools: this.fb.array([]),
      camera: false,
      phone: false,
      others: this.fb.array([]),
      tantomail: ['', [Validators.required]]
    }, {validators: [CustomValidator.exitDate, CustomValidator.admissionDate, CustomValidator.period]});

    this.item = {
      nyukanYoteiYmd: '',
      nyukanYoteiTime: '',
      taikanYoteiYmd: '',
      taikanYoteiTime: '',
      group: '',
      mokuteki: '',
      mokutekiSyosai: '',
      nyukansyas: [{ seqNo: '', nameKn: '', nameKj: '' }],
      hannyus: [{ seqNo: '', meisyo: '' }],
      hansyutus: [{ seqNo: '', meisyo: '' }],
      spAreas: [{ no: '', meisyo: '', nyukanFlg: false }],
      pcAreas: [{ no: '', meisyo: '', nyukanFlg: false }],
      dcAreas: [{ no: '', meisyo: '', nyukanFlg: false }],
      //sdAreas: [{ no: '', meisyo: '', nyukanFlg: false }],
      pcs: [{ seqNo: '', pcName: '', setuzokusaki: '', macaddress: '' }],
      tools: [{ seqNo: '', meisyo: '' }],
      camera: false,
      phone: false,
      others: [{ seqNo: '', meisyo: '' }],
      mainGroup: '',
      mainNameKn: '',
      mainNameKj: '',
      mainTelNo: '',
      mainEmail: '',
      subGroup: '',
      subNameKn: '',
      subNameKj: '',
      subTelNo: '',
      subEmail: ''
    };

    this.sinseiCopy = [{
      sinseiNo: '',
      sinseiKjnNo: '',
      sinseiGroup: '',
      sinseiNameKn: '',
      sinseiNameKj: '',
      status: '',
      statusDisp: '',
      nyukanYoteiYmd: '',
      nyukanYoteiTime: '',
      taikanYoteiYmd: '',
      taikanYoteiTime: '',
      group: '',
      mokuteki: '',
      mokutekiSyosai: '',
      spAreas: '',
      pcAreas: '',
      dcAreas: '',
      //sdAreas: '',
      camera: false,
      phone: false,
      uploadFile: '',
      mainKjnNo: '',
      //subKjnNo: '',   //★削除
      attendKjnNo: '',
      specialFlg: false,
      specialReason: '',
      specialExtra: ''
    }];

    this.nyukansyaCopy = [{
      sinseiNo: '',
      seqNo: '',
      nameKn: '',
      nameKj: '',
      status: '',
      kakunin1: false,
      kakunin2: false,
      kakunin3: false,
      key: '',
      iccard: ''
    }];

    this.motikomiCopy = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];
  }

  ngOnInit(): void {
    this.touchFlg = false;
    this.mailFlg = false;

    this.pageForm.controls.group.setValue(this.userData.keep.group);
    this.nyukansyas.push(this.fb.group({
      nameKnSei: [this.userData.keep.nameKn.slice(0, this.userData.keep.nameKn.indexOf('　')), [CustomValidator.katakana]],
      nameKnMei: [this.userData.keep.nameKn.slice(this.userData.keep.nameKn.indexOf('　') + 1), [CustomValidator.katakana]],
      nameKjSei: [this.userData.keep.nameKj.slice(0, this.userData.keep.nameKj.indexOf('　')), []],
      nameKjMei: [this.userData.keep.nameKj.slice(this.userData.keep.nameKj.indexOf('　') + 1), []]
    }));

    this.btnHannyuAddClick();
    this.btnHansyutuAddClick();
    this.btnPcAddClick();
    this.btnToolAddClick();
    this.btnOtherAddClick();

  }

  // 前回の申請をコピーする
  async btnCopyClick(): Promise<any> {
    const prmList = [{
      key: ':pKJN_NO',
      value: this.userData.keep.kjnNo
    }];

    this.sinseiCopy.length = 0;
    const result = await this.dbConnect.CmdSql('sinseiToroku', '5', prmList);
    if (result === null) {
      alert('前回の申請情報がありません。');
      return;
    }

    for (const obj of result) {
      const data = new SyokaiData(
        obj.nst001_sinsei_no,
        obj.nst001_sinsei_kjn_no,
        obj.nsm001_group,
        obj.nsm001_name_kn,
        obj.nsm001_name_kj,
        obj.nst001_status,
        obj.nst001_status,
        obj.nst001_nyukan_yotei_ymd,
        obj.nst001_nyukan_yotei_time,
        obj.nst001_taikan_yotei_ymd,
        obj.nst001_taikan_yotei_time,
        obj.nst001_group,
        obj.nst001_mokuteki,
        obj.nst001_mokuteki_syosai,
        obj.nst001_area_sp,
        obj.nst001_area_pc,
        obj.nst001_area_dc,
        //obj.nst001_area_sd,
        obj.nst001_camera === '1' ? true : false,
        obj.nst001_phone === '1' ? true : false,
        obj.nst001_upload_file,
        obj.nsm001_main_kjn_no,
        //obj.nsm001_sub_kjn_no,  //★削除
        obj.nst001_attend_kjn_no,
        obj.nst001_special_flg === '1' ? true : false,
        obj.nst001_special_reason,
        obj.nst001_special_extra
      );
      this.sinseiCopy.push(data);
    }

    this.pageForm.controls.nyukanYoteiYmd.setValue(this.sinseiCopy[0].nyukanYoteiYmd);
    this.pageForm.controls.nyukanYoteiTime.setValue(this.sinseiCopy[0].nyukanYoteiTime);
    this.pageForm.controls.taikanYoteiYmd.setValue(this.sinseiCopy[0].taikanYoteiYmd);
    this.pageForm.controls.taikanYoteiTime.setValue(this.sinseiCopy[0].taikanYoteiTime);
    this.pageForm.controls.group.setValue(this.sinseiCopy[0].group);
    this.pageForm.controls.mokuteki.setValue(this.sinseiCopy[0].mokuteki);
    this.pageForm.controls.mokutekiSyosai.setValue(this.sinseiCopy[0].mokutekiSyosai);
    this.pageForm.controls.camera.setValue(this.sinseiCopy[0].camera);
    this.pageForm.controls.phone.setValue(this.sinseiCopy[0].phone);

    const prmList2 = [{
      key: ':pSINSEI_NO',
      value: this.sinseiCopy[0].sinseiNo
    }];

    // 入館者
    this.nyukansyaCopy.length = 0;
    const result2 = await this.dbConnect.CmdSql('sinseiSyokai', '2', prmList2);
    if (result2 != null) {
      for (const obj of result2) {
        this.nyukansyaCopy.push({
          sinseiNo: obj.nst010_sinsei_no,
          seqNo: obj.nst010_seq_no,
          nameKn: obj.nst010_name_kn,
          nameKj: obj.nst010_name_kj,
          status: '',
          kakunin1: false,
          kakunin2: false,
          kakunin3: false,
          key: '',
          iccard: ''
        });
      }
    }

    let i = this.nyukansyas.length;
    while (i > 0) {
      this.nyukansyas.removeAt(i - 1);
      i--;
    }
    for (const obj of this.nyukansyaCopy) {
      this.nyukansyas.push(this.fb.group({
        nameKnSei: [obj.nameKn.slice(0, obj.nameKn.indexOf('　')), [CustomValidator.katakana]],
        nameKnMei: [obj.nameKn.slice(obj.nameKn.indexOf('　') + 1), [CustomValidator.katakana]],
        nameKjSei: [obj.nameKj.slice(0, obj.nameKj.indexOf('　')), []],
        nameKjMei: [obj.nameKj.slice(obj.nameKj.indexOf('　') + 1), []]
      }));
    }

    // 持込物
    this.motikomiCopy.length = 0;
    const result3 = await this.dbConnect.CmdSql('sinseiSyokai', '3', prmList2);

    if(result3 != null){
      for (const obj of result3) {
        this.motikomiCopy.push({
          sinseiNo: obj.nst020_sinsei_no,
          kbn: obj.nst020_kbn,
          seqNo: obj.nst020_seq_no,
          meisyo: obj.nst020_meisyo,
          setuzokusaki: obj.nst020_setuzokusaki,
          macaddress: obj.nst020_macaddress,
          status: ''
        });
      }
      const kbnList = ['1', '2', '3', '4', '5'];
      for(const kbn of kbnList){
        const kbnCopy = this.motikomiCopy.filter(obj => obj.kbn.toString() === kbn);
        if(kbnCopy.length == 0) continue;

        if(kbn === '1'){
          this.hannyus.removeAt(0);
          for(const obj of kbnCopy){
            // 搬入物
            this.hannyus.push(this.fb.group({
              hannyu: obj.meisyo
            }));
          }
        }
        if(kbn === '2'){
          this.hansyutus.removeAt(0);
          for(const obj of kbnCopy){
            // 搬出物
            this.hansyutus.push(this.fb.group({
              hansyutu: obj.meisyo
            }));
          }
        }
        if(kbn === '3'){
          this.pcs.removeAt(0);
          for(const obj of kbnCopy){
            // 持込作業PC
            this.pcs.push(this.fb.group({
              pcName: obj.meisyo,
              setuzokusaki: obj.setuzokusaki,
              macaddress: obj.macaddress,
              securityPatch: false,
              patternFile: false
            }));
          }
        }
        if(kbn === '4'){
          this.tools.removeAt(0);
          for(const obj of kbnCopy){
            // 持込工具類
            this.tools.push(this.fb.group({
              tool: obj.meisyo
            }));
          }
        }
        if(kbn === '5'){
          this.others.removeAt(0);
          for(const obj of kbnCopy){
            // その他持込物
            this.others.push(this.fb.group({
              other: obj.meisyo
            }));
          }
        }
      }
    }

    //入退館日時がユーザによって変更された扱いに設定（バリデーションを有効化するため）
    this.pageForm.controls.nyukanYoteiYmd.markAsDirty();
    this.pageForm.controls.nyukanYoteiTime.markAsDirty();
    this.pageForm.controls.taikanYoteiYmd.markAsDirty();
    this.pageForm.controls.taikanYoteiTime.markAsDirty();
  }

  // 入館者
  btnNyukansyaAddClick(): void {
    this.nyukansyas.push(this.nyukansyaForm);
  }

  btnNyukansyaDelClick(i): void {
    this.nyukansyas.removeAt(i);
  }

  get nyukansyas(): FormArray {
    return this.pageForm.get('nyukansyas') as FormArray;
  }

  get nyukansyaForm(): FormGroup {
    return this.fb.group({
      nameKnSei: ['', [CustomValidator.katakana]],
      nameKnMei: ['', [CustomValidator.katakana]],
      nameKjSei: ['', []],
      nameKjMei: ['', []]
    });
  }

  // 搬入物
  btnHannyuAddClick(): void {
    this.hannyus.push(this.hannyuForm);
  }

  btnHannyuDelClick(i): void {
    this.hannyus.removeAt(i);
  }

  get hannyus(): FormArray {
    return this.pageForm.get('hannyus') as FormArray;
  }

  get hannyuForm(): FormGroup {
    return this.fb.group({
      hannyu: ['']
    });
  }

  // 搬出物
  btnHansyutuAddClick(): void {
    this.hansyutus.push(this.hansyutuForm);
  }

  btnHansyutuDelClick(i): void {
    this.hansyutus.removeAt(i);
  }

  get hansyutus(): FormArray {
    return this.pageForm.get('hansyutus') as FormArray;
  }

  get hansyutuForm(): FormGroup {
    return this.fb.group({
      hansyutu: ['']
    });
  }

  // 持込作業PC
  btnPcAddClick(): void {
    this.pcs.push(this.pcForm);
  }

  btnPcDelClick(i): void {
    this.pcs.removeAt(i);
  }

  get pcs(): FormArray {
    return this.pageForm.get('pcs') as FormArray;
  }

  get pcForm(): FormGroup {
    return this.fb.group({
      pcName: [''],
      setuzokusaki: [''],
      macaddress: [''],
      securityPatch: [''],
      patternFile: ['']
    });
  }

  // 持込工具類
  btnToolAddClick(): void {
    this.tools.push(this.toolForm);
  }

  btnToolDelClick(i): void {
    this.tools.removeAt(i);
  }

  get tools(): FormArray {
    return this.pageForm.get('tools') as FormArray;
  }

  get toolForm(): FormGroup {
    return this.fb.group({
      tool: ['']
    });
  }

  // その他持込物
  btnOtherAddClick(): void {
    this.others.push(this.otherForm);
  }

  btnOtherDelClick(i): void {
    this.others.removeAt(i);
  }

  get others(): FormArray {
    return this.pageForm.get('others') as FormArray;
  }

  get otherForm(): FormGroup {
    return this.fb.group({
      other: ['']
    });
  }

  // 入館予定日コンボボックス　チェンジ
  cmbNyukanYoteiYmdChange(): void {
    if (this.pageForm.value.taikanYoteiYmd === '') {
      this.pageForm.controls.taikanYoteiYmd.setValue(this.pageForm.value.nyukanYoteiYmd);
    }
  }

  // 次へボタン　クリック
  btnNextClick(): void {
    this.setTouched();
    if (this.pageForm.invalid) {
      return;
    }

    //★追加・修正
    this.checkSyain().then((res) => {
      if(res){
        return;
      }else{
        this.setSinseiData();
        this.sinseiData.emit(this.item);
        this.changeFlg.emit(true);
      }
    });
  }

  setTouched(): void {
    this.touchFlg = true;
    this.pageForm.markAllAsTouched();
    this.pageForm.updateValueAndValidity();
  }

  setSinseiData(): void {
    this.item.nyukanYoteiYmd = this.pageForm.controls.nyukanYoteiYmd.value;
    this.item.nyukanYoteiTime = this.pageForm.controls.nyukanYoteiTime.value;
    this.item.taikanYoteiYmd = this.pageForm.controls.taikanYoteiYmd.value;
    this.item.taikanYoteiTime = this.pageForm.controls.taikanYoteiTime.value;
    this.item.group = this.pageForm.controls.group.value;
    this.item.mokuteki = this.pageForm.controls.mokuteki.value;
    this.item.mokutekiSyosai = this.pageForm.controls.mokutekiSyosai.value;
    this.item.camera = this.pageForm.controls.camera.value;
    this.item.phone = this.pageForm.controls.phone.value;

    // 入館者
    let index = 0;
    const nyukansyas0 = [];
    this.nyukansyas.controls.forEach(obj => {
      const nyukansya = new Nyukansya(
        index.toString(),
        obj.value.nameKnSei + '　' + obj.value.nameKnMei,
        obj.value.nameKjSei + '　' + obj.value.nameKjMei
      );
      nyukansyas0.push(nyukansya);
      index++;
    });
    this.item.nyukansyas = nyukansyas0;

    // 搬入物
    index = 0;
    const hannyu0 = [];
    this.hannyus.controls.forEach(obj => {
      const hannyu = new Hannyu(
        index.toString(),
        obj.value.hannyu
      );
      hannyu0.push(hannyu);
      index++;
    });
    if (hannyu0.length === 1) {
      if (hannyu0[0].meisyo === '') {
        hannyu0.length = 0;
      }
    }
    this.item.hannyus = hannyu0;

    // 搬出物
    index = 0;
    const hansyutu0 = [];
    this.hansyutus.controls.forEach(obj => {
      const hansyutu = new Hansyutu(
        index.toString(),
        obj.value.hansyutu
      );
      hansyutu0.push(hansyutu);
      index++;
    });
    if (hansyutu0.length === 1) {
      if (hansyutu0[0].meisyo === '') {
        hansyutu0.length = 0;
      }
    }
    this.item.hansyutus = hansyutu0;

    // 持込作業PC
    index = 0;
    const pc0 = [];
    this.pcs.controls.forEach(obj => {
      const pc = new Pc(
        index.toString(),
        obj.value.pcName,
        obj.value.setuzokusaki,
        obj.value.macaddress
      );
      pc0.push(pc);
      index++;
    });
    if (pc0.length === 1) {
      if (pc0[0].pcName === '') {
        pc0.length = 0;
      }
    }
    this.item.pcs = pc0;

    // 持込工具類
    index = 0;
    const tool0 = [];
    this.tools.controls.forEach(obj => {
      const tool = new Tool(
        index.toString(),
        obj.value.tool
      );
      tool0.push(tool);
      index++;
    });
    if (tool0.length === 1) {
      if (tool0[0].meisyo === '') {
        tool0.length = 0;
      }
    }
    this.item.tools = tool0;

    // その他持込物
    index = 0;
    const other0 = [];
    this.others.controls.forEach(obj => {
      const other = new Other(
        index.toString(),
        obj.value.other
      );
      other0.push(other);
      index++;
    });
    if (other0.length === 1) {
      if (other0[0].meisyo === '') {
        other0.length = 0;
      }
    }
    this.item.others = other0;
  }

  async checkSyain(): Promise<boolean>{
    let mail = this.pageForm.controls.tantomail.value;
    if(mail.trim() === ""){
      return;
    }

    //自社管理ドメインかどうかのチェック
    var patternSol = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}sol.gcc.co.jp+$/;
    var patternBiz = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}gcc.biz.ezweb.ne.jp+$/;
  
    if (!patternSol.test(mail) && !patternBiz.test(mail)) {
      this.mailFlg = true;
      return new Promise((res) => {
        res(true);
      });
    }

    const prmList = [{
      key: ':pTANTOMAIL',
      value: mail
    }];
    
    // 担当者のメールアドレスの存在チェック
    const result = await this.dbConnect.CmdSql('sinseiToroku', '10', prmList);
    if (result === null || result === undefined) {
      this.mailFlg = true;
      return new Promise((res) => {
        res(true);
      });
    }
    //存在する場合は担当者の情報を設定
    this.mailFlg = false;
    this.userData.keep.mainKjnNo = result[0].nsm001_kjn_no;
    this.item.mainGroup = result[0].nsm001_group;
    this.item.mainNameKn = result[0].nsm001_name_kn;
    this.item.mainNameKj = result[0].nsm001_name_kj;
    this.item.mainTelNo = result[0].nsm001_tel_no;
    this.item.mainEmail = result[0].nsm001_email;
    return new Promise((res) => {
      res(false);
    });
  }

}
