import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RirekiSummary } from '@class/rireki-summary';
import { DbConnectService } from '@service/db-connect.service';
import { PageTransitionCheckService } from '@service/page-transition-check.service';

@Component({
  selector: 'app-rireki-list',
  templateUrl: './rireki-list.component.html',
  styleUrls: ['./rireki-list.component.css']
})
export class RirekiListComponent implements OnInit {
  pageForm: FormGroup = this.fb.group({
    status: '',
    sinseiNo: '',
    sinseiDateFrom: '',
    sinseiDateTo: '',
    sinseiNameKn: ''
  });

  rirekiList: RirekiSummary[];

  constructor(
    private fb: FormBuilder,
    private dbConnect: DbConnectService,
    private pageAuth: PageTransitionCheckService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if(!this.pageAuth.authCheck(this)){
      this.router.navigateByUrl('/home');
    }
    this.dbConnect.getNyukanSummaryList().then(res => {
      this.rirekiList = res;
    });
  }

  btnExtractClick(): void{
    const id = this.pageForm.value.sinseiNo;
    const nameKn = this.pageForm.value.sinseiNameKn;
    const startDate = new Date(this.pageForm.value.sinseiDateFrom);
    const endDate = new Date(this.pageForm.value.sinseiDateTo);
    const status = this.pageForm.value.status;

    this.dbConnect.getNyukanSummaryList(id, nameKn, startDate, endDate, status).then(res => {
      this.rirekiList = res;
    });
  }

  btnClearClick(): void{
    this.pageForm.controls.status.setValue('');
    this.pageForm.controls.sinseiNo.setValue('');
    this.pageForm.controls.sinseiDateFrom.setValue('');
    this.pageForm.controls.sinseiDateTo.setValue('');
    this.pageForm.controls.sinseiNameKn.setValue('');
  }
}
