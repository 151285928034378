import { Component, OnInit } from '@angular/core';
import { DbConnectService } from '@service/db-connect.service';
import { UserDataService } from '@service/user-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { S3fileService } from '@service/s3file.service';

@Component({
  selector: 'app-nyukansyou',
  templateUrl: './nyukansyou.component.html',
  styleUrls: ['./nyukansyou.component.css']
})
export class NyukansyouComponent implements OnInit {
  sinseiNo: string;
  pdfList: any[];
  qrCode:string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dbConnect: DbConnectService,
    private userData: UserDataService,
    private http:  HttpClient,
    private s3file: S3fileService,
    ) { }

  ngOnInit(): void {
    this.sinseiNo = this.route.snapshot.paramMap.get('id');

    this.pdfList = [
      {
        id: "1",
        name: "セキュリティエリア入館に際してのお願い(レベル5,4)",
        filename: "【SPビル・GCC-DC】セキュリティエリア入館諸注意（レベル5,4個人情報有り）.pdf",
        visible: false,
      },
      {
        id: "2",
        name: "セキュリティエリア入館に際してのお願い(設備エリア)",
        filename: "【SPビル・GCC-DC】セキュリティエリア入館諸注意（設備エリア）.pdf",
        visible: false,
      },
      {
        id: "3",
        name: "セキュリティエリア入館に際してのお願い(第二データセンター)",
        filename: "第二データセンター入室諸注意（レベル5,4個人情報有り）.pdf",
        visible: false,
      }
    ]

    this.setPdfDownLoad();

    this.dbConnect.CmdSql('nyukansyou', '2', [{
      key:':pSINSEI_NO',
      value:this.sinseiNo
    }]).then(res => {
      this.qrCode = `data:image/png;base64,${res[0].nst001_qrcode}`;
    })
  }

  getNyukanData(){

  }

  async onPdfDownload(id: string){
    this.pdfList.forEach(async pdf=>{
      const filename = pdf.filename;
      await this.s3file.downloadFile(filename ,"security");
    });
  }

  setPdfDownLoad(){
    const prmList = [{
      key: ':pSINSEI_NO1',
      value: Number(this.sinseiNo)
    },
    {
      key: ':pSINSEI_NO3',
      value: Number(this.sinseiNo)
    },
    {
      key: ':pSINSEI_NO4',
      value: Number(this.sinseiNo)
    }
  ];
    this.dbConnect.CmdSql('nyukansyou', '1', prmList).then(result => {
      if ( result == null) return;
      result.forEach(nyukanArea => {
        //【SPビル・GCC-DC】セキュリティエリア入館諸注意（レベル5,4個人情報有り）
        if (nyukanArea.nsc001_kbn == '1' && nyukanArea.nsc001_level == '5'
          || nyukanArea.nsc001_kbn == '1' && nyukanArea.nsc001_level == '4'
          || nyukanArea.nsc001_kbn == '3' && nyukanArea.nsc001_level == '5'
          || nyukanArea.nsc001_kbn == '3' && nyukanArea.nsc001_level == '4') {
          this.pdfList[0].visible = true;
        };
        //【SPビル・GCC-DC】セキュリティエリア入館諸注意（設備エリア）
        if (nyukanArea.nsc001_kbn == '1' && nyukanArea.nsc001_level == '0'
          || nyukanArea.nsc001_kbn == '3' && nyukanArea.nsc001_level == '0') {
            this.pdfList[1].visible = true;
        };
        //第二データセンター入室諸注意（レベル5,4個人情報有り）
        if (nyukanArea.nsc001_kbn == '4' && nyukanArea.nsc001_level == '5'
          || nyukanArea.nsc001_kbn == '4' && nyukanArea.nsc001_level == '4') {
            this.pdfList[2].visible = true;
        };
      });
    });
  }

}
