import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-master-kanri-nyuryoku',
  templateUrl: './master-kanri-nyuryoku.component.html',
  styleUrls: ['./master-kanri-nyuryoku.component.css']
})
export class MasterKanriNyuryokuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
