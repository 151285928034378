import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AwsConnectService } from '@service/aws-connect.service';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-login-ninsyo',
  templateUrl: './login-ninsyo.component.html',
  styleUrls: ['../login.component.css']
})
export class LoginNinsyoComponent implements OnInit {
  @Input() userId: string;
  @Output() session = new EventEmitter();
  pageForm: FormGroup;

  constructor(
    private AwsConnect: AwsConnectService,
    private fb: FormBuilder,
  ) {
    this.pageForm = this.fb.group({
      pinCode: '',
    });
  }

  ngOnInit(): void {
  }

  btnNinsyoClick(): void {
    this.AwsConnect.UserNinsyoCheck(this.pageForm.controls.pinCode.value as string).subscribe(
      result => {
        // 成功
        this.session.emit({changeFlg: true, userId: this.userId});
      },
      error => {
        // 失敗
        this.session.emit({changeFlg: false});
      });
  }

  btnResendClick(): void {
    this.AwsConnect.ResendCode();
  }

}
