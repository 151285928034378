import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DbConnectService } from './db-connect.service';
import { UserDataService } from './user-data.service';

export const newsCategory = {
  masterToroku: 1,
  sinseiKihyo: 2,
  sinseiSyonin: 3,
  sinseiKyakka: 4,
  masterSyusei: 5
} as const;
type newsCategory = typeof newsCategory[keyof typeof newsCategory];

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(
    private dbConnect: DbConnectService,
    private userData: UserDataService,
    private datePipe: DatePipe
  ) { }

  fetchNewsList(): Promise<string[]>{
    const prmList = [{
      key: ':pNEWS_KJN_NO',
      value: this.userData.keep.kjnNo
    }];

    return this.dbConnect.CmdSql('home', '4', prmList)
     .then(res => {
        const newsList: string[] = [];
        if(!res){
          return newsList;
        }

        res.forEach(e => {
          const date = this.datePipe.transform(e.nst030_news_ymd, 'yyyy年M月d日');
          const news = `${date} ${e.nst030_news_syosai}`;
          newsList.push(news);
        });

        return newsList.slice(0, 5);
     });
  }

  fetchShinseiNewsList(): Promise<string[]>{
    const prmList = [{
      key: ':pKJN_NO_1',
      value: this.userData.keep.kjnNo
    }];

    return this.dbConnect.CmdSql('home', '3', prmList)
      .then(res => {
        const newsList: string[] = [];
        if(!res){
          return newsList;
        }

        res.forEach(e => {
          const nyukanDate = this.datePipe.transform(e.nst001_nyukan_yotei_ymd, 'yyyy年M月d日');
          const news = `${nyukanDate} ${e.nst001_group}様`;
          newsList.push(news);
        });

        return newsList.slice(0, 5);
      });
  }

  registerNews(kjnNo: string, kbn: newsCategory, name: string, message?: string): any {
    let maxSeqNo = 0;
    const prmList = [{
      key: ':pKJN_NO',
      value: kjnNo
    }];
    this.dbConnect.CmdSql('common', '6', prmList).then(result => {
      if (result === null) {
        maxSeqNo = 1;
      } else {
        maxSeqNo = Number(result[0].max_seq_no);
      }

      let syosai = '';
      switch(kbn) {
        case newsCategory.masterToroku:
          syosai = `${name}様のマスタが登録されました`;
          break;
        case newsCategory.sinseiKihyo:
          syosai = `${name}様より入館申請が起票されました`;
          break;
        case newsCategory.sinseiSyonin:
          syosai = `${name}様の入館申請が承認されました`;
          break;
        case newsCategory.sinseiKyakka:
          syosai = `${name}様の入館申請が却下されました`;
          break;
        case newsCategory.masterSyusei:
          syosai = `${name}様のマスタが修正されました`;
          break;
      }
      if(message){
        syosai += `　${message}`;
      }      

      const prmList2 = [{
        key: ':pNEWS_KJN_NO',
        value: kjnNo
      }, {
        key: ':pSEQ_NO',
        value: maxSeqNo + 1
      }, {
        key: ':pSYOSAI',
        value: syosai
      }, {
        key: ':pINS_KJN_NO',
        value: this.userData.keep.kjnNo
      }];
      this.dbConnect.CmdStored('common', '5', prmList2).then();
    });
  }
}
