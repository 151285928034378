import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LoginTopComponent } from './login/login-top/login-top.component';
import { LoginNinsyoComponent } from './login/login-ninsyo/login-ninsyo.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { MenuComponent } from './menu/menu.component';
import { HomeComponent } from './home/home.component';
import { MasterTorokuComponent } from './master-toroku/master-toroku.component';
import { MasterTorokuNyuryokuComponent } from './master-toroku/master-toroku-nyuryoku/master-toroku-nyuryoku.component';
import { MasterTorokuKakuninComponent } from './master-toroku/master-toroku-kakunin/master-toroku-kakunin.component';
import { MasterKanriComponent } from './master-kanri/master-kanri.component';
import { MasterKanriKensakuComponent } from './master-kanri/master-kanri-kensaku/master-kanri-kensaku.component';
import { MasterKanriNyuryokuComponent } from './master-kanri/master-kanri-nyuryoku/master-kanri-nyuryoku.component';
import { MasterKanriKakuninComponent } from './master-kanri/master-kanri-kakunin/master-kanri-kakunin.component';
import { SinseiTorokuComponent } from './sinsei-toroku/sinsei-toroku.component';
import { SinseiTorokuNyuryokuComponent } from './sinsei-toroku/sinsei-toroku-nyuryoku/sinsei-toroku-nyuryoku.component';
import { SinseiTorokuKakuninComponent } from './sinsei-toroku/sinsei-toroku-kakunin/sinsei-toroku-kakunin.component';
import { SinseiSyokaiComponent } from './sinsei-syokai/sinsei-syokai.component';
import { SinseiSyokaiKensakuComponent } from './sinsei-syokai/sinsei-syokai-kensaku/sinsei-syokai-kensaku.component';
import { SinseiSyokaiKakuninComponent } from './sinsei-syokai/sinsei-syokai-kakunin/sinsei-syokai-kakunin.component';
import { NyutaikanUketukeComponent } from './nyutaikan-uketuke/nyutaikan-uketuke.component';
import { NyutaikanUketukeReadComponent } from './nyutaikan-uketuke/nyutaikan-uketuke-read/nyutaikan-uketuke-read.component';
import { NyutaikanUketukeNyuryokuComponent } from './nyutaikan-uketuke/nyutaikan-uketuke-nyuryoku/nyutaikan-uketuke-nyuryoku.component';
import { NyukanComponent } from './nyutaikan-uketuke/nyutaikan-uketuke-nyuryoku/nyukan/nyukan.component';
import { TaikanComponent } from './nyutaikan-uketuke/nyutaikan-uketuke-nyuryoku/taikan/taikan.component';
import { SainyukanComponent } from './nyutaikan-uketuke/nyutaikan-uketuke-nyuryoku/sainyukan/sainyukan.component';
import { ItijitaikanComponent } from './nyutaikan-uketuke/nyutaikan-uketuke-nyuryoku/itijitaikan/itijitaikan.component';
import { NyukansyouComponent } from './nyukansyou/nyukansyou.component';
import { ApiheaderHttpInterceptor } from './service/apiheader-http-interceptor';
import { SpinnerModalComponent } from './spinner-modal/spinner-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExpiredDeleteComponent } from './master-kanri/expired-delete/expired-delete.component';
import { MasterSyuseiComponent } from './master-syusei/master-syusei.component'; 
import { MasterSyuseiNyuryokuComponent } from './master-syusei/master-syusei-nyuryoku/master-syusei-nyuryoku.component';
import { MasterSyuseiKakuninComponent } from './master-syusei/master-syusei-kakunin/master-syusei-kakunin.component';
import { RoleChangeComponent } from './role-change/role-change.component';
import { RoleChangeKensakuComponent } from './role-change/role-change-kensaku/role-change-kensaku.component';
import { RoleChangeNyuryokuComponent } from './role-change/role-change-nyuryoku/role-change-nyuryoku.component';
import { RoleChangeKakuninComponent } from './role-change/role-change-kakunin/role-change-kakunin.component';
import { DomainKanriComponent } from './domain-kanri/domain-kanri.component';
import { DomainNyuryokuComponent } from './domain-kanri/domain-nyuryoku/domain-nyuryoku.component';
import { KaritorokuComponent } from './karitoroku/karitoroku.component';
import { EditComponent } from './nyutaikan-uketuke/nyutaikan-uketuke-nyuryoku/edit/edit.component';
import { RirekiDetailComponent } from './nyukan-rireki/rireki-detail/rireki-detail.component';
import { RirekiListComponent } from './nyukan-rireki/rireki-list/rireki-list.component';
import { SimpleCollapseComponent } from './component/simple-collapse/simple-collapse.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginTopComponent,
    LoginNinsyoComponent,
    ChangePasswordComponent,
    ForgetPasswordComponent,
    MenuComponent,
    HomeComponent,
    MasterTorokuComponent,
    MasterTorokuNyuryokuComponent,
    MasterTorokuKakuninComponent,
    MasterKanriComponent,
    MasterKanriKensakuComponent,
    MasterKanriNyuryokuComponent,
    MasterKanriKakuninComponent,
    SinseiTorokuComponent,
    SinseiTorokuNyuryokuComponent,
    SinseiTorokuKakuninComponent,
    SinseiSyokaiComponent,
    SinseiSyokaiKensakuComponent,
    SinseiSyokaiKakuninComponent,
    NyutaikanUketukeComponent,
    NyutaikanUketukeReadComponent,
    NyutaikanUketukeNyuryokuComponent,
    NyukanComponent,
    TaikanComponent,
    SainyukanComponent,
    ItijitaikanComponent,
    NyukansyouComponent,
    SpinnerModalComponent,
    ExpiredDeleteComponent,
    MasterSyuseiComponent,
    MasterSyuseiNyuryokuComponent,
    MasterSyuseiKakuninComponent,
    RoleChangeComponent,
    RoleChangeKensakuComponent,
    RoleChangeNyuryokuComponent,
    RoleChangeKakuninComponent,
    DomainKanriComponent,
    DomainNyuryokuComponent,
    KaritorokuComponent,
    EditComponent,
    RirekiDetailComponent,
    RirekiListComponent,
    SimpleCollapseComponent,
],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  providers: [ 
    { provide: HTTP_INTERCEPTORS, useClass: ApiheaderHttpInterceptor, multi: true },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
