export class MotikomiData {
  sinseiNo: string; // 申請番号
  kbn: string; // 持込物区分
  seqNo: string; // 整理番号
  meisyo: string; // 持込物名称
  setuzokusaki: string; // 接続先（持込作業PCのみ）
  macaddress: string; // MACアドレス（持込作業PCのみ）
  status: string; // ステータス

  constructor(
    sinseiNo: string,
    kbn: string,
    seqNo: string,
    meisyo: string,
    setuzokusaki: string,
    macaddress: string,
    status: string
  ) {
    this.sinseiNo = sinseiNo;
    this.kbn = kbn;
    this.seqNo = seqNo;
    this.meisyo = meisyo;
    this.setuzokusaki = setuzokusaki;
    this.macaddress = macaddress;
    this.status = status;
  }
}
