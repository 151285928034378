import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { SinseiSyokaiKensakuComponent } from './sinsei-syokai-kensaku/sinsei-syokai-kensaku.component';
import { SinseiSyokaiKakuninComponent } from './sinsei-syokai-kakunin/sinsei-syokai-kakunin.component';
import { DbConnectService } from '@service/db-connect.service';
import { UserDataService } from '@service/user-data.service';
import { SyokaiData } from '@class/syokai-data';
import { NyukansyaData } from '@class/nyukansya-data';
import { MotikomiData } from '@class/motikomi-data';
import { AwsMailService } from '@service/aws-mail.service';
import { S3fileService } from '@service/s3file.service';

import { ModalService } from '@service/modal.service'
import { SpinnerModalComponent } from '@spinner/spinner-modal.component';
import { PageTransitionCheckService } from '@service/page-transition-check.service';
import { newsCategory, NewsService } from '@service/news.service';

@Component({
  selector: 'app-sinsei-syokai',
  templateUrl: './sinsei-syokai.component.html',
  styleUrls: ['./sinsei-syokai.component.css']
})
export class SinseiSyokaiComponent implements OnInit {
  @ViewChild(SinseiSyokaiKensakuComponent) sinseiSyokaiKensaku: SinseiSyokaiKensakuComponent;
  @ViewChild(SinseiSyokaiKakuninComponent) sinseiSyokaiKakunin: SinseiSyokaiKakuninComponent;
  kensaku: boolean;
  kakunin: boolean;
  gridData: SyokaiData[];
  syokaiData: SyokaiData[];
  nyukansyaData: NyukansyaData[];
  motikomiData: MotikomiData[];

  constructor(
    private router: Router,
    private pageAuth: PageTransitionCheckService,
    private dbConnect: DbConnectService,
    private userData: UserDataService,
    private mailService: AwsMailService,
    private s3fileService: S3fileService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
    private newsService: NewsService
  ) {
    this.gridData = [{
      sinseiNo: '',
      sinseiKjnNo: '',
      sinseiGroup: '',
      sinseiNameKn: '',
      sinseiNameKj: '',
      status: '',
      statusDisp: '',
      nyukanYoteiYmd: '',
      nyukanYoteiTime: '',
      taikanYoteiYmd: '',
      taikanYoteiTime: '',
      group: '',
      mokuteki: '',
      mokutekiSyosai: '',
      spAreas: '',
      pcAreas: '',
      dcAreas: '',
      //sdAreas: '',
      camera: false,
      phone: false,
      uploadFile: '',
      mainKjnNo: '',
      //subKjnNo: '',   //★削除
      attendKjnNo: '',
      specialFlg: false,
      specialReason: '',
      specialExtra: ''
    }];

    this.syokaiData = [{
      sinseiNo: '',
      sinseiKjnNo: '',
      sinseiGroup: '',
      sinseiNameKn: '',
      sinseiNameKj: '',
      status: '',
      statusDisp: '',
      nyukanYoteiYmd: '',
      nyukanYoteiTime: '',
      taikanYoteiYmd: '',
      taikanYoteiTime: '',
      group: '',
      mokuteki: '',
      mokutekiSyosai: '',
      spAreas: '',
      pcAreas: '',
      dcAreas: '',
      //sdAreas: '',
      camera: false,
      phone: false,
      uploadFile: '',
      mainKjnNo: '',
      //subKjnNo: '',   //★削除
      attendKjnNo: '',
      specialFlg: false,
      specialReason: '',
      specialExtra: ''
    }];

    this.nyukansyaData = [{
      sinseiNo: '',
      seqNo: '',
      nameKn: '',
      nameKj: '',
      status: '',
      kakunin1: false,
      kakunin2: false,
      kakunin3: false,
      key: '',
      iccard: ''
    }];

    this.motikomiData = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];
  }

  async ngOnInit(): Promise<void> {
    if(!this.pageAuth.authCheck(this)){
      this.router.navigateByUrl('/home')
      return
    }

    await this.getGridData();
  }

  async getGridData(){
    this.modalService.openModal(SpinnerModalComponent, this.viewContainerRef, []);

    this.gridData.length = 0;
    const userKbn = this.userData.keep.userKbn;
    const role = this.userData.keep.role;
    const kjnNo = this.userData.keep.kjnNo;
    let where = '';
    let sqlId = '';
    const prmList = [];

    // 管理者・DS部部次長・DS部SOG社員・警備員
    if (userKbn === '00' || (userKbn === '10' && role === '2') || (userKbn === '10' && role === '3') || userKbn === '30') {
      sqlId = '9';

    // 担当者
    } else if (userKbn === '10' && role === '1') {
      sqlId = '10';
      prmList.push({
        key: ':pKJNNO',
        value: kjnNo
      });
      
    // 社外
    } else if (userKbn === '20') {
      sqlId = '11';
      prmList.push({
        key: ':pKJNNO',
        value: kjnNo
      });
    }

    await this.dbConnect.CmdSql('sinseiSyokai', sqlId, prmList).then(async result => {
      if (result === null || result == undefined) {
        // 検索へ
        this.kensaku = true;
        this.kakunin = false;
        return;
      }

      for (const obj of result) {
        const data = new SyokaiData(
          obj.nst001_sinsei_no,
          obj.nst001_sinsei_kjn_no,
          obj.nsm001_group,
          obj.nsm001_name_kn,
          obj.nsm001_name_kj,
          obj.nst001_status,
          obj.nst001_status,
          obj.nst001_nyukan_yotei_ymd,
          obj.nst001_nyukan_yotei_time,
          obj.nst001_taikan_yotei_ymd,
          obj.nst001_taikan_yotei_time,
          obj.nst001_group,
          obj.nst001_mokuteki,
          obj.nst001_mokuteki_syosai,
          obj.nst001_area_sp,
          obj.nst001_area_pc,
          obj.nst001_area_dc,
          //obj.nst001_area_sd,
          obj.nst001_camera === '1' ? true : false,
          obj.nst001_phone === '1' ? true : false,
          obj.nst001_upload_file,
          obj.nst001_main_kjn_no,
          //obj.nsm001_sub_kjn_no,  //★削除
          obj.nst001_attend_kjn_no,
          obj.nst001_special_flg === '1' ? true : false,
          obj.nst001_special_reason,
          obj.nst001_special_extra
        );
        this.gridData.push(data);
      }

      // 検索へ
      await this.sinseiSyokaiKensaku.setData();
      this.kensaku = true;
      this.kakunin = false;
      window.scrollTo(0, 0);
    });
    this.modalService.closeModal();
  }

  async changeKensaku(sinseiNo: string): Promise<void> {
    this.syokaiData.length = 0;
    this.nyukansyaData.length = 0;
    this.motikomiData.length = 0;

    this.syokaiData = this.gridData.filter(obj => obj.sinseiNo === sinseiNo);

    const prmList = [{
      key: ':pSINSEI_NO',
      value: sinseiNo
    }];

    this.dbConnect.CmdSql('sinseiSyokai', '2', prmList).then(result => {
      if(result != null){
        for (const obj of result) {
          const data = new NyukansyaData(
            obj.nst010_sinsei_no,
            obj.nst010_seq_no,
            obj.nst010_name_kn,
            obj.nst010_name_kj,
            '',
            false,
            false,
            false,
            '',
            ''
          );
          this.nyukansyaData.push(data);
        }
      }

      this.dbConnect.CmdSql('sinseiSyokai', '3', prmList).then(async result2 => {
        if(result2 != null) {
          for (const obj2 of result2) {
            const data2 = new MotikomiData(
              obj2.nst020_sinsei_no,
              obj2.nst020_kbn,
              obj2.nst020_seq_no,
              obj2.nst020_meisyo,
              obj2.nst020_setuzokusaki,
              obj2.nst020_macaddress,
              ''
            );
            this.motikomiData.push(data2);
          }
        }

        // 照会へ
        await this.sinseiSyokaiKakunin.setData();
        this.kensaku = false;
        this.kakunin = true;
        window.scrollTo(0, 0);
      });
    });
  }

  async changeKakunin(session: any): Promise<any> {
    this.modalService.openModal(SpinnerModalComponent, this.viewContainerRef, []);
    if (session.changeFlg) {
      // 承認
      if (session.syoninFlg) {
        await this.syonin(session);
      }
      // 却下
      else {
        await this.kyakka(session);

        alert('却下しました。');
      }

      // ホームへ
      this.router.navigate(['/home']);
      window.scrollTo(0, 0);
    } else {
      // 戻る
      await this.getGridData();
      window.scrollTo(0, 0);
    }
    this.modalService.closeModal();
  }

  async syonin(session){

    // 申請ステータス変更
    const prmList = [{
      key: ':pSTATUS',
      value: session.status
    },
    {
      key: ':pUPD_KJN_NO',
      value: this.userData.keep.kjnNo
    },
    {
      key: ':pSINSEI_NO',
      value: session.sinseiNo
    }];
    await this.dbConnect.CmdStored('sinseiSyokai', '4', prmList);

    // 主担当承認
    if (session.status === '02') {
      await this.syoninTuchiDS(session);  //★追加(DS部次長へ)
      // FOG社員確認
      if (session.uploadList.length !== 0) {
        this.syoninTuchiFOG(session);
      }
    }
    // DS部次長承認（承認完了）
    if (session.status === "04") {
      await this.syoninKanryo(session);
    };

    alert('承認しました。');
  }

  async syoninTuchiDS(session){
    // DS部次長に申請通知メール送信
    const prmDSMember = [{
      key: ':pROLE',
      value: '2'
    }];

    const DSMembers = await this.dbConnect.CmdSql('common', '4', prmDSMember);
    const groupName = this.syokaiData[0].sinseiGroup + '　' + this.syokaiData[0].sinseiNameKj;
    DSMembers.forEach(async member => {
      let prmDS = {
        "tantouAddr": member.nsm001_email,
        "tantouNm": member.nsm001_group + '　' + member.nsm001_name_kj,
        "sinseiShaNm": groupName
      };
      await this.mailService.sendSinseiTorokuTuti(prmDS);
    });
  }

  async syoninTuchiFOG(session) {
    // DS部FOG社員に申請通知メール送信
    const prmFOGMember = [{
      key: ':pROLE',
      value: '4'
    }];

    const FOGMembers = await this.dbConnect.CmdSql('common', '4', prmFOGMember);
    const groupName = this.syokaiData[0].sinseiGroup + '　' + this.syokaiData[0].sinseiNameKj;
    FOGMembers.forEach(async member => {
      const prmFOG = {
        tantouAddr: member.nsm001_email,
        tantouNm: member.nsm001_group + '　' + member.nsm001_name_kj,
        sinseiShaNm: groupName
      };
      await this.mailService.sendSinseiTorokuTuti(prmFOG);
    });
  }

  async syoninKanryo(session) {
    // 担当者に承認完了メール送信
    const prmMainInfo = [{
      key: ':pMAIN_KJN_NO',
      value: this.syokaiData[0].mainKjnNo
    }];
    const groupName = this.syokaiData[0].sinseiGroup + '　' + this.syokaiData[0].sinseiNameKj;

    await this.dbConnect.CmdSql('common', '7', prmMainInfo).then(async result => {
      const res = result[0];

      let prmMain = {
        "tantouAddr": res.main_email,
        "tantouNm": res.main_group + '　' + res.main_name_kj,
        "sinseiShaNm": groupName
      }
      await this.mailService.sendShouninZumiTuti(prmMain);
    });

/**
 * 最終承認は自分で実施しているので最終承認通知は不要
    // DS部次長に承認完了メール送信
    const prmDSManager = [{
      key: ':pROLE',
      value: '2'
    }];

    const DSManagers = await this.dbConnect.CmdSql('common', '4', prmDSManager);
    DSManagers.forEach(async manager => {
      let prmDS = {
        "tantouAddr": manager.nsm001_email,
        "tantouNm": manager.nsm001_group + '　' + manager.nsm001_name_kj,
        "sinseiShaNm": groupName
      };
      await this.mailService.sendShouninZumiTuti(prmDS)
    });
**/

    // DS部SOG社員に承認完了メール送信
    const prmDS = [{
      key: ':pROLE',
      value: '3'
    }];

    const DSMembers = await this.dbConnect.CmdSql('common', '4', prmDS);
    DSMembers.forEach(async member => {
      let prmDS = {
        "tantouAddr": member.nsm001_email,
        "tantouNm": member.nsm001_group + '　' + member.nsm001_name_kj,
        "sinseiShaNm": groupName
      };
      await this.mailService.sendShouninZumiTuti(prmDS)
    });

    // DS部FOG社員に承認完了メール送信
    const prmFOGMember = [{
      key: ':pROLE',
      value: '4'
    }];

    const FOGMembers = await this.dbConnect.CmdSql('common', '4', prmFOGMember);
    FOGMembers.forEach(async member => {
      const prmFOG = {
        tantouAddr: member.nsm001_email,
        tantouNm: member.nsm001_group + '　' + member.nsm001_name_kj,
        sinseiShaNm: groupName
      };
      await this.mailService.sendShouninZumiTuti(prmFOG);
    });

    // QRコードを生成
    await this.dbConnect.GenerateQrcode(this.syokaiData[0].sinseiNo, true);

    // nst030_newsの更新
    await this.newsService.registerNews(this.syokaiData[0].sinseiKjnNo,
      newsCategory.sinseiSyonin, this.syokaiData[0].sinseiGroup + '　' + this.syokaiData[0].sinseiNameKj);

    // 申請者に承認完了メールを送信
    let prmSinseiInfo = [{
      key: ':pKJN_NO',
      value: this.syokaiData[0].sinseiKjnNo
    }];
    const sinseiInfo = await this.dbConnect.CmdSql('common', '1', prmSinseiInfo);
    let prmSinsei = {
      "sinseiAddr": sinseiInfo[0].nsm001_email,
      "sinseiShaNm": groupName,
      "sinseiNo": this.syokaiData[0].sinseiNo
    };
    await this.mailService.sendShouninZumiTutiSinsei(prmSinsei);
  }

  async kyakka(session){
    if(this.userData.keep.role === '2'){
      //部次長の場合は担当者承認前のステータスに戻す
      const prmList = [{
        key: ':pSTATUS',
        value: '01'
      },
      {
        key: ':pUPD_KJN_NO',
        value: this.userData.keep.kjnNo
      },
      {
        key: ':pSINSEI_NO',
        value: session.sinseiNo
      }];
      await this.dbConnect.CmdStored('sinseiSyokai', '4', prmList);
    }else{
      //それ以外(担当者)の却下は取消
      const prmList = [{
        key: ':pUPD_KJN_NO',
        value: this.userData.keep.kjnNo
      },
      {
        key: ':pDEL_KJN_NO',
        value: this.userData.keep.kjnNo
      },
      {
        key: ':pSINSEI_NO',
        value: session.sinseiNo
      }];
      await this.dbConnect.CmdStored('sinseiSyokai', '5', prmList);
      await this.dbConnect.CmdStored('sinseiSyokai', '6', prmList);
      await this.dbConnect.CmdStored('sinseiSyokai', '7', prmList);

      // アップロードファイルの削除
      if(session.uploadList != []){
        for(let file of session.uploadList){
          await this.s3fileService.deleteFile(file, this.syokaiData[0].sinseiNo);
        };
      };
    }

    // nst030_newsの更新
    await this.newsService.registerNews(this.syokaiData[0].sinseiKjnNo,
      newsCategory.sinseiKyakka, this.syokaiData[0].sinseiGroup + '　' + this.syokaiData[0].sinseiNameKj,
      `却下理由：${session.reason}`);

    //申請却下の通知メール送信
    await this.kyakkaTuchi(session.reason);
  }

  async kyakkaTuchi(message?: string) {

    // 担当者に却下メール送信
    const prmMainInfo = [{
      key: ':pMAIN_KJN_NO',
      value: this.syokaiData[0].mainKjnNo
    }];
    const groupName = this.syokaiData[0].group + '　' + this.syokaiData[0].sinseiNameKj;

    this.dbConnect.CmdSql('common', '7', prmMainInfo).then(async result => {
      const res = result[0];

      let prmMain = {
        "tantouAddr": res.main_email,
        "tantouNm": res.main_group + '　' + res.main_name_kj,
        "sinseiShaNm": groupName,
        "message": message
      }
      await this.mailService.sendSinseiDeleteTuti(prmMain);
    });

/**
 * 部次長の却下については担当者へ返却するだけなので通知不要
 * 担当者の却下についても部次長・SOG・FOGへの通知は不要
    // DS部次長に却下メール送信
    const prmDSManager = [{
      key: ':pROLE',
      value: '2'
    }];

    const DSManagers = await this.dbConnect.CmdSql('common', '4', prmDSManager);
    DSManagers.forEach(async manager => {
      let prmDS = {
        "tantouAddr": manager.nsm001_email,
        "tantouNm": manager.nsm001_group + '　' + manager.nsm001_name_kj,
        "sinseiShaNm": groupName,
        "message": message
      };
      await this.mailService.sendSinseiDeleteTuti(prmDS)
    });

    // DS部SOG社員に却下メール送信
    const prmDSMember = [{
      key: ':pROLE',
      value: '3'
    }];

    const DSMembers = await this.dbConnect.CmdSql('common', '4', prmDSMember);;
    DSMembers.forEach(async member => {
      let prmDS = {
        "tantouAddr": member.nsm001_email,
        "tantouNm": member.nsm001_group + '　' + member.nsm001_name_kj,
        "sinseiShaNm": groupName,
        "message": message
      };
      await this.mailService.sendSinseiDeleteTuti(prmDS)
    });

    // DS部FOG社員に却下メール送信
    const prmFOGMember = [{
      key: ':pROLE',
      value: '4'
    }];

    const prmFOGMembers = await this.dbConnect.CmdSql('common', '4', prmFOGMember);
    prmFOGMembers.forEach(async member => {
      const prmFOG = {
        tantouAddr: member.nsm001_email,
        tantouNm: member.nsm001_group + '　' + member.nsm001_name_kj,
        sinseiShaNm: groupName
      };
      await this.mailService.sendSinseiDeleteTuti(prmFOG);
    });
**/

    // 申請者に却下メール送信
    let prmSinseiInfo = [{
      key: ':pKJN_NO',
      value: this.syokaiData[0].sinseiKjnNo
    }];
    const sinseiInfo = await this.dbConnect.CmdSql('common', '1', prmSinseiInfo);
    let prmSinsei = {
      "tantouAddr": sinseiInfo[0].nsm001_email,
      "tantouNm": groupName,
      "sinseiShaNm": groupName,
      "message": message
    };
    await this.mailService.sendSinseiDeleteTuti(prmSinsei)
  }

}
