export class UserData {
  kjnNo: string;
  userId: string;
  syainNo: string;
  role: string;
  group: string;
  nameKn: string;
  nameKj: string;
  telNo: string;
  email: string;
  mainKjnNo: string;
  subKjnNo: string;
  userKbn: string;

  constructor(
    kjnNo: string,
    userId: string,
    syainNo: string,
    role: string,
    group: string,
    nameKn: string,
    nameKj: string,
    telNo: string,
    email: string,
    mainKjnNo: string,
    subKjnNo: string,
    userKbn: string
  ) {
    this.kjnNo = kjnNo;
    this.userId = userId;
    this.syainNo = syainNo;
    this.role = role;
    this.group = group;
    this.nameKn = nameKn;
    this.nameKj = nameKj;
    this.telNo = telNo;
    this.email = email;
    this.mainKjnNo = mainKjnNo;
    this.subKjnNo = subKjnNo;
    this.userKbn = userKbn;
  }
}
