import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Auth } from 'aws-amplify';
import { UserDataService } from '@service/user-data.service';
import { DbConnectService } from '@service/db-connect.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userData: UserDataService,
    private dbConnect: DbConnectService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise((resolve) => {
        Auth.currentAuthenticatedUser({
            bypassCache: false
          })
          .then((user) => {
            if (user) {
              const userId = sessionStorage.getItem('userId');
              const prmList = [{
                key: ':pUSER_ID',
                value: userId
              }];
              this.dbConnect.CmdSql('login', '1', prmList).then(result => {
                this.userData.SetUserKbn(userId);
                this.userData.SetUserData(result[0]);
                resolve(true);
              });
            }
          })
          .catch(() => {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            resolve(false);
          });
      });
  }
}
