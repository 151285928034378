import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-nyutaikan-uketuke-read',
  templateUrl: './nyutaikan-uketuke-read.component.html',
  styleUrls: ['./nyutaikan-uketuke-read.component.css']
})
export class NyutaikanUketukeReadComponent implements OnInit {
  @Output() sinseiNo = new EventEmitter();
  pageForm: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
    this.pageForm = fb.group({
      sinseiNo: ''
    });
  }

  ngOnInit(): void {
  }

  btnReadClick(): void {
    if (this.pageForm.controls.sinseiNo.value !== '') {
      this.sinseiNo.emit(this.pageForm.controls.sinseiNo.value);
    } else {
      alert('QRコードを読み取ってください。');
    }
  }

}
