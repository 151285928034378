<div class="bg">
    <div class="container">
        <div fxLayout="column" class="main">
            <div fxFlex="15">
                    <div class="title center">
                        <div>
                            入館証
                        </div>
                    </div>
                </div>
            <div fxFlex="40" class="images center">
                <div class="img-qr">
                    <img id="qr-image" src="{{qrCode}}">
                </div>
            </div>
            <div fxFlex="20" class="full">
                ・来館予定者全員へ本入館証を配布いただきますようお願いいたします。<br>
                入館証をご提示いただけない場合は入館いただけませんのであらかじめご了承下さい。<br><br>
                ・入館前に「セキュリティエリア入館に際してのお願い」記載内容についてご了承願います。<br><br>
            </div>
            <div class="mb-3">
                <div>＜個人情報ありのエリアが含まれる場合＞</div>
                <div class="ml-3"><a href="https://pdftempstorage.s3.ap-northeast-1.amazonaws.com/security/%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3%E3%82%A8%E3%83%AA%E3%82%A2%E5%85%A5%E5%A0%B4%E3%81%AB%E9%9A%9B%E3%81%97%E3%81%A6%E3%81%AE%E3%81%8A%E9%A1%98%E3%81%84%EF%BC%88%E3%83%AC%E3%83%99%E3%83%AB5%2C4%E5%80%8B%E4%BA%BA%E6%83%85%E5%A0%B1%E6%9C%89%E3%82%8A%EF%BC%89.pdf">セキュリティエリア入場に際してのお願い（レベル5,4個人情報有り）.pdf</a></div>
            </div>
            <div class="mb-3">
                <div>＜設備エリアのみの場合＞</div>
                <div class="ml-3"><a href="https://pdftempstorage.s3.ap-northeast-1.amazonaws.com/security/%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3%E3%82%A8%E3%83%AA%E3%82%A2%E5%85%A5%E5%A0%B4%E3%81%AB%E9%9A%9B%E3%81%97%E3%81%A6%E3%81%AE%E3%81%8A%E9%A1%98%E3%81%84%EF%BC%88%E8%A8%AD%E5%82%99%E3%82%A8%E3%83%AA%E3%82%A2%EF%BC%89.pdf">セキュリティエリア入場に際してのお願い（設備エリア）.pdf</a></div>
            </div>
        </div>
        <div class="footer" style="text-align: center;">
            <ng-container *ngFor="let pdf of pdfList">
                <ng-container *ngIf="pdf.visible">
                    <button class="btn btn-primary ml-3" (click)="onPdfDownload(pdf.id)">「{{pdf.name}}」をダウンロード</button>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>