import { Component, OnInit, Input, ViewContainerRef, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SyokaiData } from '@class/syokai-data';
import { NyukansyaData } from '@class/nyukansya-data';
import { MotikomiData } from '@class/motikomi-data';
import { DbConnectService } from '@service/db-connect.service';
import { UserDataService } from '@service/user-data.service';
import { ModalService } from '@service/modal.service'
import { SpinnerModalComponent } from '@spinner/spinner-modal.component';
import { MasterData } from '@class/master-data';
import { CustomValidator } from '@utility/custom-validator';

@Component({
  selector: 'app-nyutaikan-uketuke-nyuryoku',
  templateUrl: './nyutaikan-uketuke-nyuryoku.component.html',
  styleUrls: ['./nyutaikan-uketuke-nyuryoku.component.css']
})
export class NyutaikanUketukeNyuryokuComponent implements OnInit {
  @Input() syokaiData: SyokaiData;
  @Input() nyukansyaData: NyukansyaData[];
  @Input() motikomiData: MotikomiData[];

  pageForm: FormGroup;
  uketuke: boolean;
  nyukan: boolean;
  taikan: boolean;
  reNyukan: boolean;
  tempTaikan: boolean;
  nyukansyaedit:boolean;

  nyukansya: NyukansyaData[];
  hannyuList: MotikomiData[];
  hansyutuList: MotikomiData[];
  pcList: MotikomiData[];
  toolList: MotikomiData[];
  otherList: MotikomiData[];
  status: string[];
  qrcode: string[];

  nyukanYoteiDat: string;
  taikanYoteiDat: string;
  mainTanto: string;
  subTanto: string;
  attendant: string;
  spAreas: string;
  pcAreas: string;
  dcAreas: string;
  sdAreas: string;
  camera: string;
  phone: string;
  special: string;

  noHannyu: boolean;
  noHansyutu: boolean;
  noPc: boolean;
  noTool: boolean;
  noOther: boolean;
  noSpecial: boolean;
  item: MasterData;
  attendTanto: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    private dbConnect: DbConnectService,
    private userData: UserDataService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.pageForm = this.fb.group({
      attend: '',
      nyukansyas: this.fb.array([]),
      syainNo: ['', Validators.compose([Validators.required, CustomValidator.numeric])],
      syainNoCheck: null,
      dojo: true
    });

    this.nyukansya = [{
      sinseiNo: '',
      seqNo: '',
      nameKn: '',
      nameKj: '',
      status: '',
      kakunin1: false,
      kakunin2: false,
      kakunin3: false,
      key: '',
      iccard: ''
    }];

    this.hannyuList = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];

    this.hansyutuList = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];

    this.pcList = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];

    this.toolList = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];

    this.otherList = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];

    this.item = {
      syubetu: '',
      syainNo: '',
      role: '',
      roleDisp: '',
      group: '',
      nameKn: '',
      nameKj: '',
      telNo: '',
      email: '',
      kjnNoMain: '',
      syainNoMain: '',
      groupMain: '',
      nameKnMain: '',
      nameKjMain: '',
      telNoMain: '',
      emailMain: '',
      kjnNoSub: '',
      syainNoSub: '',
      groupSub: '',
      nameKnSub: '',
      nameKjSub: '',
      telNoSub: '',
      emailSub: ''
    };
  }

  ngOnInit(): void {
    this.changeUketuke(true);
    this.addNyukansya();
    this.noHannyu = false;
    this.noHansyutu = false;
    this.noPc = false;
    this.noTool = false;
    this.noOther = false;
    this.status = [];
    this.qrcode = [];
  }

  // 入館ボタン　クリック
  btnNyukanClick(index: number): void {
    this.setNyukansya(index);
    this.changeNyukan(true);
  }

  // 退館ボタン　クリック
  btnTaikanClick(index: number): void {
    this.setNyukansya(index);
    this.changeTaikan(true);
  }

  // 再入館ボタン　クリック
  btnReNyukanClick(index: number): void {
    this.setNyukansya(index);
    this.changeSainyukan(true);
  }

  // 一時退館ボタン　クリック
  btnTempTaikanClick(index: number): void {
    this.setNyukansya(index);
    this.changeItijitaikan(true);
  }

  // 完了ボタン　クリック
  async btnFinishClick(): Promise<any> {
    if (!confirm('完了します。よろしいですか？')) {
      return;
    }

    this.modalService.openModal(SpinnerModalComponent, this.viewContainerRef, []);

    const prmList = [{
      key: ':pSINSEI_NO',
      value: this.syokaiData.sinseiNo
    },
    {
      key: ':pSTATUS',
      value: '06'
    },
    {
      key: ':pUPD_KJN_NO',
      value: this.userData.keep.kjnNo
    }];
    await this.dbConnect.CmdStored('nyutaikanUketuke', '3', prmList);

    for (let i = 0; i < this.nyukansyaData.length; i++) {
      const prmList2 = [{
        key: ':pSINSEI_NO',
        value: this.nyukansyaData[i].sinseiNo
      },
      {
        key: ':pSEQ_NO',
        value: this.nyukansyaData[i].seqNo
      },
      {
        key: ':pFUBI',
        value: this.nyukansyas.controls[i].get('fubi').value ? '1' : '0'
      },
      {
        key: ':pNONE',
        value: this.nyukansyas.controls[i].get('none').value ? '1' : '0'
      },
      {
        key: ':pUPD_KJN_NO',
        value: this.userData.keep.kjnNo
      }];
      await this.dbConnect.CmdStored('nyutaikanUketuke', '4', prmList2);
    }

    this.modalService.closeModal();
    alert('対応が完了しました。');
    this.router.navigate(['/home']);
    window.scrollTo(0, 0);
  }

  // ホームボタン　クリック
  btnHomeClick(): void {
    this.router.navigate(['/home']);
    window.scrollTo(0, 0);
  }

  changeUketuke(changeFlg: boolean): void {
    if (changeFlg) {
      // 入退館受付画面へ
      this.uketuke = true;
      this.nyukan = false;
      this.taikan = false;
      this.reNyukan = false;
      this.tempTaikan = false;
    }
  }

  changeNyukan(changeFlg: boolean): void {
    if (changeFlg) {
      // 入館手続き画面へ
      this.uketuke = false;
      this.nyukan = true;
      this.taikan = false;
      this.reNyukan = false;
      this.tempTaikan = false;
      window.scrollTo(0, 0);
    } else {
      // 入退館受付画面へ
      this.getNyukansyaData();
      this.getMotikomiData();
      this.uketuke = true;
      this.nyukan = false;
      this.taikan = false;
      this.reNyukan = false;
      this.tempTaikan = false;
      window.scrollTo(0, 0);
    }
  }

  changeTaikan(changeFlg: boolean): void {
    if (changeFlg) {
      // 退館手続き画面へ
      this.uketuke = false;
      this.nyukan = false;
      this.taikan = true;
      this.reNyukan = false;
      this.tempTaikan = false;
      window.scrollTo(0, 0);
    } else {
      // 入退館受付画面へ
      this.getNyukansyaData();
      this.getMotikomiData();
      this.uketuke = true;
      this.nyukan = false;
      this.taikan = false;
      this.reNyukan = false;
      this.tempTaikan = false;
      window.scrollTo(0, 0);
    }
  }

  changeSainyukan(changeFlg: boolean): void {
    if (changeFlg) {
      // 再入館手続き画面へ
      this.uketuke = false;
      this.nyukan = false;
      this.taikan = false;
      this.reNyukan = true;
      this.tempTaikan = false;
      window.scrollTo(0, 0);
    } else {
      // 入退館受付画面へ
      this.uketuke = true;
      this.nyukan = false;
      this.taikan = false;
      this.reNyukan = false;
      this.tempTaikan = false;
      window.scrollTo(0, 0);
    }
  }

  changeItijitaikan(changeFlg: boolean): void {
    if (changeFlg) {
      // 一時退館手続き画面へ
      this.uketuke = false;
      this.nyukan = false;
      this.taikan = false;
      this.reNyukan = false;
      this.tempTaikan = true;
      window.scrollTo(0, 0);
    } else {
      // 入退館受付画面へ
      this.uketuke = true;
      this.nyukan = false;
      this.taikan = false;
      this.reNyukan = false;
      this.tempTaikan = false;
      window.scrollTo(0, 0);
    }
  }

  setData(): void {
    this.setMotikomiList();
    this.setSyokaiData();
    this.setStatus();
    this.getNyukansyaData();
    this.getMotikomiData();
  }

  setNyukansya(index: number): void {
    this.nyukansya.length = 0;
    const target = this.nyukansyaData[index];
    this.nyukansya = [target];
  }

  setMotikomiList(): void {
    this.hannyuList.length = 0;
    this.hansyutuList.length = 0;
    this.pcList.length = 0;
    this.toolList.length = 0;
    this.otherList.length = 0;
    for (const obj of this.motikomiData) {
      if (obj.kbn.toString() === '1') {
        // 搬入物
        this.hannyuList.push(obj);
      } else if (obj.kbn.toString() === '2') {
        // 搬出物
        this.hansyutuList.push(obj);
      } else if (obj.kbn.toString() === '3') {
        // 持込作業PC
        this.pcList.push(obj);
      } else if (obj.kbn.toString() === '4') {
        // 持込工具類
        this.toolList.push(obj);
      } else if (obj.kbn.toString() === '5') {
        // その他持込物
        this.otherList.push(obj);
      }
    }
  }

  setSyokaiData(): void {
    this.nyukanYoteiDat = '';
    this.taikanYoteiDat = '';
    this.spAreas = '';
    this.pcAreas = '';
    this.dcAreas = '';
    this.sdAreas = '';
    this.camera = '';
    this.phone = '';

    // 入館予定日時
    if (this.syokaiData.nyukanYoteiYmd !== '' && this.syokaiData.nyukanYoteiTime !== '') {
      const nyukanYoteiDat0 = this.syokaiData.nyukanYoteiYmd + ' ' + this.syokaiData.nyukanYoteiTime;
      this.nyukanYoteiDat = this.datePipe.transform(nyukanYoteiDat0, 'yyyy年MM月dd日')
        + '　' + this.datePipe.transform(nyukanYoteiDat0, 'HH時mm分');
    }

    // 退館予定日時
    if (this.syokaiData.taikanYoteiYmd !== '' && this.syokaiData.taikanYoteiTime !== '') {
      const taikanYoteiDat0 = this.syokaiData.taikanYoteiYmd + ' ' + this.syokaiData.taikanYoteiTime;
      this.taikanYoteiDat = this.datePipe.transform(taikanYoteiDat0, 'yyyy年MM月dd日')
        + '　' + this.datePipe.transform(taikanYoteiDat0, 'HH時mm分');
    }

    const prmList2 = [{
      key: ':pMAIN_KJN_NO',
      value: this.syokaiData.mainKjnNo
    }];
    this.dbConnect.CmdSql('common', '7', prmList2).then(result2 => {
      this.mainTanto = result2[0].main_group + '　' + result2[0].main_name_kj + '　　内線：' + result2[0].main_tel_no;
      this.setAttend();
    });

    //アテンド社員取得
    this.getAttendSyain();

    // 特別許可申請
    this.special = this.syokaiData.specialFlg === true ? '特別許可申請あり' : '';
    this.noSpecial = this.syokaiData.specialFlg;

    // 持込カメラ・デジタルカメラ
    this.camera = this.syokaiData.camera === true ? '持ち込みあり' : '持ち込みなし';

    // 持込携帯電話・スマートフォン
    this.phone = this.syokaiData.phone === true ? '持ち込みあり' : '持ち込みなし';

    // 搬入物
    if (this.hannyuList.length === 0) {
      this.noHannyu = true;
    } else {
      this.noHannyu = false;
    }

    // 搬出物
    if (this.hannyuList.length === 0) {
      this.noHansyutu = true;
    } else {
      this.noHansyutu = false;
    }

    // 持込作業PC
    if (this.pcList.length === 0) {
      this.noPc = true;
    } else {
      this.noPc = false;
    }

    // 持込工具類
    if (this.toolList.length === 0) {
      this.noTool = true;
    } else {
      this.noTool = false;
    }

    // その他持込物
    if (this.otherList.length === 0) {
      this.noOther = true;
    } else {
      this.noOther = false;
    }

    // 入館者QRコード
    this.setNyukansyaQrcode();
  }

  setStatus(): void {
    for (let i = 0; i < this.nyukansyaData.length; i++) {
      const statusKbn = this.nyukansyaData[i].status;
      if (statusKbn === '') {
        this.status[i] = '未入館';
      } else if (statusKbn === '01') {
        this.status[i] = '入館中';
      } else if (statusKbn === '02') {
        this.status[i] = '一時退館中';
      } else if (statusKbn === '03') {
        this.status[i] = '退館済';
      }
    }
  }

  setAttend(): void {
    let tanto = '';
    if (this.syokaiData.attendKjnNo === this.userData.keep.mainKjnNo) {
      tanto = '01';
    } else if (this.syokaiData.attendKjnNo === this.userData.keep.subKjnNo) {
      tanto = '02';
    } else {
      tanto = '01';
    }
    this.pageForm.controls.attend.setValue(tanto);
  }

  async setNyukansyaQrcode(): Promise<any> {
    this.qrcode = [];
    for (const obj of this.nyukansyaData) {
      // 2022.09.02 変更後の入館者氏名でQRコードを生成するよう修正
      //const result = await this.dbConnect.GenerateQrcode(obj.nameKn, false);
      const result = await this.dbConnect.GenerateQrcode(this.getNameKn(obj.nameKn), false);
      this.qrcode.push('data:image/png;base64,' + result.toString());
    }
  }

  // 2022.09.02 Add Start
  private prefix_str: string ="　入館：";
  private suffix_str: string ="予定：";

  getNameKn(str : string): string{
    let list = str.replace(this.suffix_str, "").split(this.prefix_str);
    return list[list.length - 1].toString();
  }
  // 2022.09.02 Add End

  // nst011_nyukansyaの最新履歴データの取得
  getNyukansyaData(): void {
    const prmList = [{
      key: ':pSINSEI_NO',
      value: this.syokaiData.sinseiNo
    }];
    this.dbConnect.CmdSql('nyutaikanUketuke', '11', prmList).then(result => {
      if (result === null) {
        return;
      }

      this.InitNyukansya();
      for (const obj of this.nyukansyaData) {
        for (const obj2 of result) {
          if (obj.sinseiNo === obj2.nst011_sinsei_no && obj.seqNo === obj2.nst011_seq_no) {
            obj.status = obj2.nst011_status;
            obj.key = obj2.nst011_key;
            obj.iccard = obj2.nst011_iccard;
          }
        }
        this.addNyukansya();
      }

      this.setStatus();
    });
  }

  // nst021_motikomiの最新履歴データの取得
  getMotikomiData(): void {
    const prmList = [{
      key: ':pSINSEI_NO',
      value: this.syokaiData.sinseiNo
    }];
    this.dbConnect.CmdSql('nyutaikanUketuke', '12', prmList).then(result => {
      if (result === null) {
        return;
      }

      for (const obj of this.motikomiData) {
        for (const obj2 of result) {
          if (obj.sinseiNo === obj2.nst021_sinsei_no && obj.kbn === obj2.nst021_kbn && obj.seqNo === obj2.nst021_seq_no) {
            obj.status = obj2.nst021_status;
          }
        }
      }
    });
  }

  addNyukansya(): void {
    this.nyukansyas.push(this.fb.group({
      fubi: [false],
      none: [false]
    }));
  }

  InitNyukansya(): void {
    this.pageForm.controls.nyukansyas = this.fb.array([]);
  }

  get nyukansyas(): FormArray {
    return this.pageForm.controls.nyukansyas as FormArray;
  }

  getSpecialReason(): string{
    let response = "";
    if(this.syokaiData.specialReason === '1'){
      response = "作業完了報告書出力のため";
    }else if(this.syokaiData.specialReason === '2'){
      response = "テザリング利用のため";
    }else if(this.syokaiData.specialReason === '9'){
      response = this.syokaiData.specialExtra;
    }
    return response;
  }

  async getAttendSyain() : Promise<void>{
    //アテンド社員個人番号から社員番号を取得
    let kjn_no = "";
    if(this.syokaiData.attendKjnNo === null){
      kjn_no = this.syokaiData.mainKjnNo;
    }else{
      kjn_no = this.syokaiData.attendKjnNo;
    }

    const prmList = [{
        key: ':pKJN_NO',
        value: kjn_no
      }];

    await this.dbConnect.CmdSql('common', '1', prmList).then(result => {
      if (result === null) {
        //社員が存在しない場合
        this.attendTanto = this.mainTanto;
      }
      const data = result[0];
      this.item.kjnNoMain = data.nsm001_kjn_no;
      this.item.groupMain = data.nsm001_group;
      this.item.nameKnMain = data.nsm001_name_kn;
      this.item.nameKjMain = data.nsm001_name_kj;
      this.item.telNoMain = data.nsm001_tel_no;
      this.item.emailMain = data.nsm001_email;
      this.attendTanto = this.item.groupMain + '　' + this.item.nameKjMain + '　　内線：' + this.item.telNoMain;
    });
    
  }

  //入館者修正ボタンクリック
  btnEditClick(index: number): void{
    this.setNyukansya(index);
    this.changeEdit(true);
  }

  changeEdit(changeFlg: boolean): void {
    if (changeFlg) {
      // 入館者修正画面へ
      this.uketuke = false;
      this.nyukan = false;
      this.taikan = false;
      this.reNyukan = false;
      this.tempTaikan = false;
      this.nyukansyaedit = true;
      window.scrollTo(0, 0);
    } else {
      // 入退館受付画面へ
      // 2022.09.05 Add 入館者変更時のQRコード再生成処理追加
      this.setNyukansyaQrcode();
      this.uketuke = true;
      this.nyukan = false;
      this.taikan = false;
      this.reNyukan = false;
      this.tempTaikan = false;
      this.nyukansyaedit = false;
      window.scrollTo(0, 0);
    }
  }
}
