import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AwsConnectService } from '@service/aws-connect.service';
import { DbConnectService } from '@service/db-connect.service';
import { UserDataService } from '@service/user-data.service';
import { CustomValidator } from '@utility/custom-validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  pageForm: FormGroup;
  pincode: boolean;
  chgpass: boolean;

  constructor(
    private router: Router,
    private service: AwsConnectService,
    private dbConnect: DbConnectService,
    private userData: UserDataService,
    private fb: FormBuilder
  ) {
    this.pageForm = this.fb.group({
      pincode: '',
      //入力制限追加
      newPass: ['', [Validators.required, CustomValidator.password]],
      newPassCheck: '',
      oldPass: [{value: '', disabled: this.chgpass}, ]
    });
  }

  ngOnInit(): void {
    this.pincode = false;
    this.chgpass = false;
    
    if (this.service.pwResetFlg) {
      this.pincode = true;
    }
    this.chgpass = this.service.pwChgFlg;
  }

  btnChangeClick(): void {
    //追加
    if (this.pageForm.invalid) {
      alert('入力条件を満たしていません。');
    }
    else if (this.pageForm.controls.newPass.value !== this.pageForm.controls.newPassCheck.value) {
      alert('パスワードが一致していません。');
    } 
    else{
      if (!this.pincode) {
        if(!this.chgpass){
          //初回ログイン時の強制パスワード変更
          this.service.PasswordChange(this.pageForm.controls.newPass.value);
        }else{
          //メニューからのパスワード変更
          this.service.ChangePassword(this.pageForm.controls.oldPass.value, this.pageForm.controls.newPass.value);
        }
      } else {
        // パスワード忘れた
        this.service.PasswordForgotJikkou(this.pageForm.controls.pincode.value, this.pageForm.controls.newPass.value);
      }
    }
  }

  btnBackClick(): void {
    this.router.navigate(['/home']);
  }
}