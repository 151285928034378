import { sharedStylesheetJitUrl } from "@angular/compiler";

// 照会データ
export class SyokaiData {
  sinseiNo: string; // 申請番号
  sinseiKjnNo: string; // 申請者個人番号
  sinseiGroup: string; // 申請者所属
  sinseiNameKn: string; // 申請者氏名カナ
  sinseiNameKj: string; // 申請者氏名漢字
  status: string; // ステータス
  statusDisp: string; // ステータス（表示用）
  nyukanYoteiYmd: string; // 入館予定日
  nyukanYoteiTime: string; // 入館予定時刻
  taikanYoteiYmd: string; // 退館予定日
  taikanYoteiTime: string; // 退館予定時刻
  group: string; // 所属
  mokuteki: string; // 入館目的
  mokutekiSyosai: string; //入館目的の詳細
  spAreas: string; // 入館場所（SPビル）
  pcAreas: string; // 入館場所（プロセシングセンター）
  dcAreas: string; // 入館場所（GCC-DC）
  //sdAreas: string; // 入館場所（第二データセンター）
  camera: boolean; // 持込カメラ
  phone: boolean; // 持込携帯電話
  uploadFile: string; // 添付資料
  mainKjnNo: string; // 主担当者個人番号
  //subKjnNo: string; // 副担当者個人番号 ★削除
  attendKjnNo: string; // アテンド社員個人番号
  specialFlg: boolean; //特別申請フラグ
  specialReason: string;  //特別申請理由
  specialExtra: string; //特別申請理由その他

  constructor(
    sinseiNo: string,
    sinseiKjnNo: string,
    sinseiGroup: string,
    sinseiNameKn: string,
    sinseiNameKj: string,
    status: string,
    statusDisp: string,
    nyukanYoteiYmd: string,
    nyukanYoteiTime: string,
    taikanYoteiYmd: string,
    taikanYoteiTime: string,
    group: string,
    mokuteki: string,
    mokutekiSyosai: string,
    spAreas: string,
    pcAreas: string,
    dcAreas: string,
    //sdAreas: string,
    camera: boolean,
    phone: boolean,
    uploadFile: string,
    mainKjnNo: string,
    //subKjnNo: string, ★削除
    attendKjnNo: string,
    specialFlg: boolean,
    specialReason: string,
    specialExtra: string
  ) {
    this.sinseiNo = sinseiNo;
    this.sinseiKjnNo = sinseiKjnNo;
    this.sinseiGroup = sinseiGroup;
    this.sinseiNameKn = sinseiNameKn;
    this.sinseiNameKj = sinseiNameKj;
    this.status = status;
    this.statusDisp = statusDisp;
    this.nyukanYoteiYmd = nyukanYoteiYmd;
    this.nyukanYoteiTime = nyukanYoteiTime;
    this.taikanYoteiYmd = taikanYoteiYmd;
    this.taikanYoteiTime = taikanYoteiTime;
    this.group = group;
    this.mokuteki = mokuteki;
    this.mokutekiSyosai = mokutekiSyosai;
    this.spAreas = spAreas;
    this.pcAreas = pcAreas;
    this.dcAreas = dcAreas;
    //this.sdAreas = sdAreas;
    this.camera = camera;
    this.phone = phone;
    this.uploadFile = uploadFile;
    this.mainKjnNo = mainKjnNo;
    //this.subKjnNo = subKjnNo; ★削除
    this.attendKjnNo = attendKjnNo;
    this.specialFlg = specialFlg;
    this.specialReason = specialReason;
    this.specialExtra = specialExtra;
  }
}
