import { Injectable } from '@angular/core';
import { UserData } from '@class/user-data';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  keep: UserData;

  constructor() {
    this.keep = {
      kjnNo: '',
      userId: '',
      syainNo: '',
      role: '',
      group: '',
      nameKn: '',
      nameKj: '',
      telNo: '',
      email: '',
      mainKjnNo: '',
      subKjnNo: '',
      userKbn: ''
    };
  }

  // ユーザ区分の設定
  SetUserKbn(userId: string): void {
    this.InitUserKbn();
    this.keep.userKbn = userId.substr(0, 2);
    if (this.keep.userKbn === 'ad') {
      this.keep.userKbn = '00';
    }
    //警備員 ★
    if(this.keep.role === '5'){
      this.keep.userKbn = '30';
    }
  }

  // ユーザ情報の設定
  SetUserData(data: any): void {
    this.InitUserData();
    this.keep.kjnNo = data.nsm001_kjn_no;
    this.keep.userId = data.nsm001_user_id;
    this.keep.syainNo = data.nsm001_syain_no;
    this.keep.role = data.nsm001_role;
    this.keep.group = data.nsm001_group;
    this.keep.nameKn = data.nsm001_name_kn;
    this.keep.nameKj = data.nsm001_name_kj;
    this.keep.telNo = data.nsm001_tel_no;
    this.keep.email = data.nsm001_email;
    this.keep.mainKjnNo = data.nsm001_main_kjn_no;
    this.keep.subKjnNo = data.nsm001_sub_kjn_no;
    sessionStorage.setItem('userId', data.nsm001_user_id);
  }

  // ユーザ区分の初期化
  InitUserKbn(): void {
    this.keep.userKbn = '';
  }

  // ユーザ情報の初期化
  InitUserData(): void {
    this.keep.kjnNo = '';
    this.keep.userId = '';
    this.keep.syainNo = '';
    this.keep.role = '';
    this.keep.group = '';
    this.keep.nameKn = '';
    this.keep.nameKj = '';
    this.keep.telNo = '';
    this.keep.email = '';
    this.keep.mainKjnNo = '';
    this.keep.subKjnNo = '';
    sessionStorage.removeItem('userId');
  }

}
