import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { NyutaikanUketukeNyuryokuComponent } from './nyutaikan-uketuke-nyuryoku/nyutaikan-uketuke-nyuryoku.component';
import { DbConnectService } from '@service/db-connect.service';
import { UserDataService } from '@service/user-data.service';
import { SyokaiData } from '@class/syokai-data';
import { NyukansyaData } from '@class/nyukansya-data';
import { MotikomiData } from '@class/motikomi-data';
import { ModalService } from '@service/modal.service'
import { SpinnerModalComponent } from '@spinner/spinner-modal.component';
import { PageTransitionCheckService } from '@service/page-transition-check.service';
import { ActivatedRoute } from '@angular/router'; //★

@Component({
  selector: 'app-nyutaikan-uketuke',
  templateUrl: './nyutaikan-uketuke.component.html',
  styleUrls: ['./nyutaikan-uketuke.component.css']
})
export class NyutaikanUketukeComponent implements OnInit {
  @ViewChild(NyutaikanUketukeNyuryokuComponent) nyutaikanUketukeNyuryoku: NyutaikanUketukeNyuryokuComponent;
  read: boolean;
  nyuryoku: boolean;
  syokaiData: SyokaiData;
  nyukansyaData: NyukansyaData[];
  motikomiData: MotikomiData[];

  constructor(
    private route: ActivatedRoute,  //★
    private router: Router,
    private pageAuth: PageTransitionCheckService,
    private dbConnect: DbConnectService,
    private userData: UserDataService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.syokaiData = {
      sinseiNo: '',
      sinseiKjnNo: '',
      sinseiGroup: '',
      sinseiNameKn: '',
      sinseiNameKj: '',
      status: '',
      statusDisp: '',
      nyukanYoteiYmd: '',
      nyukanYoteiTime: '',
      taikanYoteiYmd: '',
      taikanYoteiTime: '',
      group: '',
      mokuteki: '',
      mokutekiSyosai: '',
      spAreas: '',
      pcAreas: '',
      dcAreas: '',
      //sdAreas: '',
      camera: false,
      phone: false,
      uploadFile: '',
      mainKjnNo: '',
      //subKjnNo: '',   //★削除
      attendKjnNo: '',
      specialFlg: false,
      specialReason: '',
      specialExtra: ''
    };

    this.nyukansyaData = [{
      sinseiNo: '',
      seqNo: '',
      nameKn: '',
      nameKj: '',
      status: '',
      kakunin1: false,
      kakunin2: false,
      kakunin3: false,
      key: '',
      iccard: ''
    }];

    this.motikomiData = [{
      sinseiNo: '',
      kbn: '',
      seqNo: '',
      meisyo: '',
      setuzokusaki: '',
      macaddress: '',
      status: ''
    }];
  }

  ngOnInit(): void {

    //入館申請照会画面からのnavigateだった場合
    let sinseiNo = this.route.snapshot.queryParamMap.get('id');
    if(sinseiNo !== undefined && sinseiNo !== null){
      this.changeRead(sinseiNo);
    }

    if(!this.pageAuth.authCheck(this)){
      this.router.navigateByUrl('/home')
      return
    }

    this.read = true;
    this.nyuryoku = false;
  }

  async changeRead(sinseiNo: string): Promise<any> {
    this.modalService.openModal(SpinnerModalComponent, this.viewContainerRef, []);
    this.nyukansyaData.length = 0;
    this.motikomiData.length = 0;

    const result = await this.dbConnect.getShinseiFromCode(sinseiNo);
    if (result === null) {
      // 該当の申請が見つからなかったとき
      this.modalService.closeModal();
      alert('該当の申請が見つかりません。');
      this.read = true;
      this.nyuryoku = false;
      window.scrollTo(0, 0);
      return;
    }
    // 該当の申請が見つかったとき
    this.syokaiData = new SyokaiData(
      result[0].nst001_sinsei_no,
      result[0].nst001_sinsei_kjn_no,
      result[0].nsm001_group,
      result[0].nsm001_name_kn,
      result[0].nsm001_name_kj,
      result[0].nst001_status,
      result[0].nst001_status,
      result[0].nst001_nyukan_yotei_ymd,
      result[0].nst001_nyukan_yotei_time,
      result[0].nst001_taikan_yotei_ymd,
      result[0].nst001_taikan_yotei_time,
      result[0].nst001_group,
      result[0].nst001_mokuteki,
      result[0].nst001_mokuteki_syosai,
      result[0].nst001_area_sp,
      result[0].nst001_area_pc,
      result[0].nst001_area_dc,
      //result[0].nst001_area_sd,
      result[0].nst001_camera === '1' ? true : false,
      result[0].nst001_phone === '1' ? true : false,
      result[0].nst001_upload_file,
      result[0].nst001_main_kjn_no, //★修正
      //result[0].nsm001_sub_kjn_no,  //★削除
      result[0].nst001_attend_kjn_no,
      result[0].nst001_special_flg === '1' ? true : false,
      result[0].nst001_special_reason,
      result[0].nst001_special_extra
    );

    // 入館時刻の登録
    if (result[0].nst001_nyukan_dat === null) {
      const prmList2 = [{
        key: ':pSINSEI_NO',
        value: this.syokaiData.sinseiNo
      },
      {
        key: ':pSTATUS',
        value: '05'
      },
      {
        key: ':pUPD_KJN_NO',
        value: this.userData.keep.kjnNo
      }];
      await this.dbConnect.CmdStored('nyutaikanUketuke', '2', prmList2);
    }

    // 入館者情報の取得
    const prmList = [{
      key: ':pSINSEI_NO',
      value: this.syokaiData.sinseiNo
    }];
    const result2 = await this.dbConnect.CmdSql('sinseiSyokai', '2', prmList);
    this.nyutaikanUketukeNyuryoku.InitNyukansya();
    if(result2 != null) {
      for (const obj of result2) {
        const data = new NyukansyaData(
          obj.nst010_sinsei_no,
          obj.nst010_seq_no,
          obj.nst010_name_kn,
          obj.nst010_name_kj,
          '',
          false,
          false,
          false,
          '',
          ''
        );
        this.nyutaikanUketukeNyuryoku.addNyukansya();
        this.nyukansyaData.push(data);
      }
    }

    // 持込物情報の取得
    const result3 = await this.dbConnect.CmdSql('sinseiSyokai', '3', prmList);
    if(result3 != null) {
      for (const obj of result3) {
        const data = new MotikomiData(
          obj.nst020_sinsei_no,
          obj.nst020_kbn,
          obj.nst020_seq_no,
          obj.nst020_meisyo,
          obj.nst020_setuzokusaki,
          obj.nst020_macaddress,
          ''
        );
        this.motikomiData.push(data);
      }
    }

    // 詳細へ
    this.nyutaikanUketukeNyuryoku.setData();
    this.read = false;
    this.nyuryoku = true;
    this.modalService.closeModal();
    window.scrollTo(0, 0);
  }

  changeNyuryoku(changeFlg: boolean): void {
    if (changeFlg) {
      // ホームへ
      this.router.navigate(['/home']);
      window.scrollTo(0, 0);
    }
  }

}

